//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './UserInfoEnrollment.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useUpdateDocument } from '../hooks/useUpdateDocument';
//Dev Components
import UserEnrollments from './UserEnrollments';
import { set } from 'date-fns';
//Firebase
import { Timestamp } from 'firebase/firestore';

const UserInfoEnrollment = ({ updateInfos, returnPage, userEnrollSelected, infoUserEnroll }) => {

    console.log("userEnrollSelected ", userEnrollSelected)

    const { updateDocument, responseUpdate } = useUpdateDocument("Companys", "");

    const [userEnroll, setUserEnroll] = useState()
    const [enrollSelected, setEnrollSelected] = useState(userEnrollSelected)

    const [userId, setUserId] = useState(null)
    const [companyId, setCompanyId] = useState(null)
    const [userName, setUserName] = useState(null)
    const [urlUserImage, setUrlUserImage] = useState(null)
    const [userJobTitle, setUserJobTitle] = useState(null)
    const [userDepartment, setUserDepartment] = useState(null)

    const [courseName, setCourseName] = useState(null)
    const [validateDate, setValidateDate] = useState(null)
    const [originalValidateDate, setOriginalValidateDate] = useState(null)
    const [courseActive, setCourseActive] = useState(null)
    const [originalCourseActive, setOriginalCourseActive] = useState(null)
    const [courseAccessUpon, setCourseAccessUpon] = useState(null)
    const [originalCourseAccessUpon, setOriginalCourseAccessUpon] = useState(null)  
    const [createdAt, setCreatedAt] = useState(null)
    const [firstAccess, setFirstAccess] = useState(null)
    const [lastAccess, setLastAccess] = useState(null)
    const [totalTime, setTotalTime] = useState(null)
    const [status, setStatus] = useState(null)
    const [score, setScore] = useState(null)
    const [enrollmentId, setEnrollmentId] = useState(null)

    const [formattedFirstAccess, setFormattedFirstAccess] = useState(null)
    const [formattedLastAccess, setFormattedLastAccess] = useState(null)
    const [fomattedTotalTilme, setFomattedTotalTilme] = useState(null)

    const [showSaveNewSettings, setShowSaveNewSettings] = useState(false)

    const [naoMostrar, setNaoMostrar] = useState(false)

    const handleChangeEnrollment = (actualenrollment) => {
        setEnrollmentId(actualenrollment.enrollment_id)
        setCourseName(actualenrollment.course_name)
        setCreatedAt(actualenrollment.creation_date)
        setFirstAccess(actualenrollment.first_access ? actualenrollment.first_access : 0)
        setLastAccess(actualenrollment.last_access)
        setTotalTime(actualenrollment.total_time)
        setStatus(actualenrollment.status)
        setScore(actualenrollment.score)
        setValidateDate(actualenrollment.validate_date)
        setOriginalValidateDate(actualenrollment.validate_date)
        setCompanyId(actualenrollment.company_id)
        setCourseActive(actualenrollment.active)
        setOriginalCourseActive(actualenrollment.active)
    }

    useEffect(() => {
        setUserEnroll(infoUserEnroll)
    }, [infoUserEnroll])

    useEffect(() => {
        if (userEnroll) {
            setUserId(userEnroll.id)
            setUserName(userEnroll.name)
            setUrlUserImage(userEnroll.url_user_image)
            setUserJobTitle(userEnroll.job_title)
            setUserDepartment(userEnroll.department)
            enrollSelected && setCompanyId(enrollSelected.enrollment.company_id)
        }
    }, [userEnroll])

    useEffect(() => {
        if (enrollSelected) {
            setCourseName(enrollSelected.enrollment.course_name)
            setCreatedAt(enrollSelected.enrollment.created_at)
            setFirstAccess(enrollSelected.enrollment.first_access ? enrollSelected.enrollment.first_access : 0)
            setLastAccess(enrollSelected.enrollment.last_access)
            setTotalTime(enrollSelected.enrollment.total_time)
            setStatus(enrollSelected.enrollment.status)
            setScore(enrollSelected.enrollment.score)
            setEnrollmentId(enrollSelected.enrollment.enrollment_id)
            setValidateDate(enrollSelected.enrollment.validate_date)
            setOriginalValidateDate(enrollSelected.enrollment.validate_date)
            setCourseActive(enrollSelected.enrollment.active)
            setOriginalCourseActive(enrollSelected.enrollment.active)
            setCourseAccessUpon(enrollSelected.enrollment.access_upon_completion ? enrollSelected.enrollment.access_upon_completion : false)
            setOriginalCourseAccessUpon(enrollSelected.enrollment.access_upon_completion ? enrollSelected.enrollment.access_upon_completion : false)
        }
    }, [enrollSelected])

    useEffect(() => {
        if (status || firstAccess || lastAccess || totalTime) {
            setFormattedFirstAccess(status != "not started" ? formatDate(firstAccess) : 'N/A');
            setFormattedLastAccess(status != "not started" ? formatDate(lastAccess) : 'N/A');
            setFomattedTotalTilme(status != "not started" ? `${totalTime} seconds` : 'N/A');
        }
    }, [status, firstAccess, lastAccess, totalTime])

    const handleCloseWindow = () => {
        setUserEnroll(null)
        returnPage()
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString();
    };

    const saveNewSettings = async () => {
        const newDoc = {
            active: courseActive,
            access_upon_completion: courseAccessUpon,
            domain: companyId,
            company_id: companyId,
            userId: userId,
            id: enrollmentId,
            collection2: 'Enrollments',
            validate_date: validateDate
        }
        await updateDocument(newDoc)
        updateInfos()
        setShowSaveNewSettings(false)
    }

    useEffect(() => {
        if (validateDate != originalValidateDate || courseActive != originalCourseActive || courseAccessUpon != originalCourseAccessUpon) {
            setShowSaveNewSettings(true)
        } else {
            setShowSaveNewSettings(false)
        }
    }, [validateDate, courseActive, courseAccessUpon])

    return (
        <div className={styles.container}>
            <div>
                <div className="row">
                    <div className={`${styles.iconButton} d-flex align-items-end justify-content-end`}>
                        <i className={`bi bi-box-arrow-left me-4 ${styles.icons}`} onClick={() => handleCloseWindow()}></i>
                    </div>
                </div>
                <div className={`ms-1 d-flex row align-items-center ${styles.divName}`}>
                    <div className='col-auto'>
                        {urlUserImage
                            ? <img src={urlUserImage} alt="User Profile" className={`img-thumbnail rounded-circle ${styles.profilePicture}`} style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }} />
                            : <div className={`d-flex justify-content-center align-items-center rounded-circle bg-secondary ${styles.profilePicture}`} style={{ width: '100px', height: '100px', cursor: 'pointer', position: 'relative' }}>
                                <i className="bi bi-person-fill" style={{ fontSize: '50px', color: 'white' }}></i>
                            </div>}
                    </div>
                    <div className={`col-2 ${styles.username} ${styles.formatTexts}`}>
                        {userName}
                        <p></p>
                        <div className={`${styles.jobtitle}`}>
                        {userDepartment} / {userJobTitle}
                        </div>
                    </div>
                    {enrollmentId &&
                        <div>
                            <div className={`mt-3 ms-3 fw-bold  ${styles.coursename} `}>
                                {courseName}
                            </div>
                            <div className={`form-group col-12 ms-3 ${styles.inputGroup}`}>
                                <div className="row mt-2 d-flex align-items-center">
                                    <div className={`${styles.colSettingsDate}`}>
                                        <label className={` ${styles.texts_format} ${styles.formatTexts}`} htmlFor="validateDate">Valid until:</label>
                                    </div>
                                    {!validateDate && <span className={`col-auto ${styles.zindex}`}>No date set</span>}
                                    <input
                                        type="date"
                                        className={`form-control col-4 ${styles.input} ${!validateDate ? styles.inputHide : null} `}
                                        id="validateDate"
                                        value={validateDate && validateDate.seconds ? new Date(validateDate.seconds * 1000).toLocaleDateString('en-CA') : ''}
                                        min={new Date().toISOString().split('T')[0]}
                                        onChange={(e) => {
                                            const selectedDate = new Date(e.target.value);
                                            selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset());
                                            setValidateDate(Timestamp.fromDate(selectedDate));
                                        }}

                                    />
                                </div>
                                <div className="row mt-1 d-flex align-items-center">
                                    <div className={`${styles.colSettings}`}>
                                        <label className={` ${styles.texts_format} ${styles.formatTexts}`} htmlFor="validateDate">Enrollment:</label>
                                    </div>
                                    <div className="form-check form-switch col-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="courseActiveSwitch"
                                            checked={courseActive}
                                            onChange={(e) => setCourseActive(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="courseActiveSwitch">
                                            {courseActive ? 'Active' : 'Not Active'}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mt-1 d-flex align-items-center">
                                    <div className={`${styles.colSettings}`}>
                                        <label className={` ${styles.texts_format} ${styles.formatTexts}`} htmlFor="validateDate">Course accessible upon completion:</label>
                                    </div>
                                    <div className="form-check form-switch col-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="courseActiveSwitch"
                                            checked={courseAccessUpon}
                                            onChange={(e) => setCourseAccessUpon(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="courseActiveSwitch">
                                            {courseAccessUpon ? 'Accessible' : 'Not accessible'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-2 mt-3 ms-3'>
                                {showSaveNewSettings &&
                                    <div className="col-auto">
                                        <button
                                            className={`btn btn-primary ${styles.button}`}
                                            onClick={() => saveNewSettings()}
                                        >
                                            Save
                                        </button>
                                    </div>}
                            </div>
                            <div className={`mt-3 ms-3 ${styles.coursename}`}>
                                Course Details:
                            </div>
                            <div className={`table-responsive mt-3 ms-3 me-5 ${styles.tableContainer}`}>
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td className={`${styles.formatTexts}`}>First access:</td>
                                            <td className={`${styles.formatTexts}`}>{formattedFirstAccess}</td>
                                        </tr>
                                        <tr>
                                            <td className={`${styles.formatTexts}`}>Last access:</td>
                                            <td className={`${styles.formatTexts}`}>{formattedLastAccess}</td>
                                        </tr>
                                        <tr>
                                            <td className={`${styles.formatTexts}`}>Status:</td>
                                            <td className={`${styles.formatTexts}`}>{status}</td>
                                        </tr>
                                        {(status === "failed" || status === "passed")
                                            && (
                                                <tr>
                                                    <td className={`${styles.formatTexts}`}>Score:</td>
                                                    <td className={`${styles.formatTexts}`}>{score}%</td>
                                                </tr>
                                            )}
                                        <tr>
                                            <td className={`${styles.formatTexts}`}>Total time:</td>
                                            <td className={`${styles.formatTexts}`}>{fomattedTotalTilme}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <div className={`mt-3 ms-3 ${styles.coursename}`}>
                        Current Enrollments:
                    </div>
                    {
                        <div className='mt-3 ms-3 mb-3'>
                            <UserEnrollments
                                user={infoUserEnroll}
                                actualEnrollmentId={enrollmentId}
                                handleChangeEnrollment={handleChangeEnrollment}
                            />
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default UserInfoEnrollment