//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './ExportScoAI.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useCreateEnrollment } from '../hooks/useCreateEnrollment';
import React from 'react'
import { id } from 'date-fns/locale';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";

const ExportScoAI = ({ project }) => {

    console.log("project", project);

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();
    const { createEnrollment } = useCreateEnrollment();

    const [docFile, setDocFile] = useState("word");
    const [courseId, setCourseId] = useState("");
    const [courseImage, setCourseImage] = useState("");
    const [urlCourseImg, setUrlCourseImg] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [projectType, setProjectType] = useState("");
    const [passingScore, setPassingScore] = useState(0);
    const [enrollmenttUsers, setEnrollmenttUsers] = useState([]);
    const [enrollmentId, setEnrollmentId] = useState("");

    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');
    const [progresspercent, setProgresspercent] = useState(0);

    const dataCreation = Timestamp.now();

    var userStudentId = "1tJn1AlP4EdnU0ht83WtfSiD7oJ2";


    const enrollmentItems = {
        actionDoc: "insert",
        bookmark: {
            project: projectType,
            slide: 0,
            time: 0
        },
        concluded: false,
        conclusion_date: dataCreation,
        course_description: courseDescription,
        course_id: courseId,
        course_image: urlCourseImg,
        course_name: courseName,
        domain: userStudentId,
        domain_id: project.userId,
        enrollment_date: dataCreation,
        enrollment_id: enrollmentId,
        first_access: dataCreation,
        id: enrollmentId,
        last_access: dataCreation,
        passing_score: passingScore,
        score: 0,
        status: "not started",
        total_time: 0,
        validate_date: dataCreation,
    }

    const classItems = {
        id: enrollmentId,
        course_id: courseId,
        course_image: urlCourseImg,
        course_name: courseName,
        course_description: courseDescription,
        data_creation: dataCreation,
        enrollment_users: ["1tJn1AlP4EdnU0ht83WtfSiD7oJ2"],
    }

    const uploadImage = async (file) => {
        setUploadingFile(true);
        //await handleDeleteImage();

        const forcedFileName = 'thumbnail';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {

                const maxSize = 1000 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 200Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                setUploadingFile(true);
                const storageRef = ref(storage, `companys/${companyId}/${courseId}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlCourseImg(url);
                                
                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            setUploadingFile(false);
            console.error('Erro geral:', error);
        }
    }

    const handleDeleteImage = async () => { }


    const handleSaveProject = async () => {
        await createEnrollment(enrollmentItems);
        setUploadingFile(false);
    }

    useEffect(() => {
        if (urlCourseImg) {
            handleSaveProject();
        }
    }, [urlCourseImg])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (project) {
            setCourseId(project.id);
            setCourseName(project.projectName);
            setCompanyId("4Z8vmBIyeGwU7J3jTd2w");

            const projectTypes = {
                includeCase: "case",
                includeContent: "content",
                includeSlides: "ppt",
                includeVideo: "video"
            };

            for (const [key, value] of Object.entries(projectTypes)) {
                if (project[key]) {
                    setProjectType(value);
                    break;
                }
            }
        }
    }, [project])

    useEffect(() => {
        if (courseName) {
             var enroll = "enroll_" + courseName + Timestamp.now();
             setEnrollmentId(enroll);
        }
    }, [courseName])

    return (
        <div>
            {uploadingFile
                ? <div className={`row d-flex justify-content-center align-items-center pt-3 pb-3 mt-5`}>
                    <div className="text-center">
                        <div><h4>Publicando curso</h4></div>
                    </div>
                    <div className="ps-1 mt-2 text-center">
                        <div className={`spinner-grow text-primary ${styles.spinner_customize}`} role="status"></div>
                    </div>
                </div>
                : <div className="mt-4 p-0">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="course-info-tab" data-bs-toggle="tab" data-bs-target="#course-info" type="button" role="tab" aria-controls="course-info" aria-selected="true">
                                {t('projectpreview:exportscoai.infos curso')}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="false">
                                {t('projectpreview:exportscoai.usuarios')}
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content ms-3 me-3" id="myTabContent">
                        <div className="tab-pane fade show active" id="course-info" role="tabpanel" aria-labelledby="course-info-tab">
                            <div className="form-group mt-3">
                                <label className={`${styles.texts_format}`} htmlFor="courseName">{t('projectpreview:exportscoai.nome do curso')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="courseName"
                                    value={courseName}
                                    onChange={(e) => setCourseName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className={`${styles.texts_format}`} htmlFor="courseDescription">{t('projectpreview:exportscoai.descricao')}</label>
                                <textarea
                                    className="form-control"
                                    id="courseDescription"
                                    rows="3"
                                    value={courseDescription}
                                    onChange={(e) => setCourseDescription(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="form-group mt-3">
                                <label className={`${styles.texts_format}`} htmlFor="passingScore">{t('projectpreview:exportscoai.nota de corte')}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="passingScore"
                                    value={passingScore}
                                    onChange={(e) => setPassingScore(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className={`${styles.texts_format}`} htmlFor="validateDate">{t('projectpreview:exportscoai.validade')}</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="validateDate"
                                    value={enrollmentItems.validate_date.toDate().toISOString().split('T')[0]}
                                    onChange={(e) => setEnrollmenttUsers({ ...enrollmentItems, validate_date: new Date(e.target.value) })}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className={`${styles.texts_format}`} htmlFor="courseImage">{t('projectpreview:exportscoai.imagem do curso')}</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="courseImage"
                                    onChange={(e) => setCourseImage(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                            <div className={`mt-4 ${styles.texts_format}`}>
                                <h6>{`Selecione os usuários:`}</h6>
                            </div>
                            <div className={`form-check mt-4 ${docFile === 'docfile1' ? styles.actual_choice : ''}`}>
                                <input className={`form-check-input ${styles.option_check}`} type="radio" name="docRadioDefault" id="word" onChange={(event) => setDocFile(event.target.id)} checked={docFile === 'word'} />
                                <span className={`form-check-label ${styles.texts_format}`}>
                                    {`Renato Palácio`}
                                </span>
                            </div>
                            <div className="row mt-4">
                                <button className={`${styles.btn_style}`} onClick={() => uploadImage(courseImage)}>{t('projectpreview:exportscoai.publicar')}</button>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default ExportScoAI