
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import aquaStyles from '../templates/aqua.module.css'
import blueStyles from '../templates/blue.module.css'
import cleanDarkStyles from '../templates/cleanDark.module.css'
import cleanLightStyles from '../templates/cleanLight.module.css'
import cleanNeutralStyles from '../templates/cleanNeutral.module.css'
import coralStyles from '../templates/coral.module.css'
import freshStyles from '../templates/fresh.module.css'
import greenStyles from '../templates/green.module.css'
import pastelStyles from '../templates/pastel.module.css'
import purpleRainStyles from '../templates/purpleRain.module.css'
import quizAIStyles from '../templates/quizai.module.css'
import styles from './LaunchCourse.module.css'
import defaultStyles from '../templates/default.module.css'
import Carousel from 'react-bootstrap/Carousel';

//Firebase
import { useFetchDocument } from '../hooks/useFetchDocument';
//React Hooks
import { useEffect, useState, useRef } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
//Dev components
import AssessmentQuestions from './AssessmentQuestions';
import AssessmentContent from './AssessmentContent';
import AssessmentCase from './AssessmentCase';
import VideoInteractionObject from './VideoInteractionObject';
import { Timestamp } from 'firebase/firestore';
import { set } from 'date-fns';

const ProjectPreview = ({ id, domainId, returnHome, enrollment, userId }) => {

    var search = "";
    const timerRef = useRef(null);

    if (id === "Setor Elétrico Resumido063871966576.400000000") {
        id = "Setor Elétrico Resumido063871966576.400000000"
    }

    var { document: project, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", domainId, "Folder", id);

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const [projectLoading, setProjectLoading] = useState(true);
    const [showProgress, setShowProgress] = useState(true);
    const [menuFreeNavigation, setMenuFreeNavigation] = useState(false);
    const [idProject, setIdProject] = useState(id);

    const [projectName, setProjectName] = useState("");
    const [urlLogo, setUrlLogo] = useState("");
    const [language, setLanguage] = useState("Portuguese");

    const [showPPt, setShowPPt] = useState(false);
    const [pptAssessmentTogether, setPptAssessmentTogether] = useState(false);
    const [pptText, setPptText] = useState("");
    const [listUrlSlides, setListUrlSlides] = useState([]);
    const [slide, setSlide] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const [slidesProgress, setSlidesProgress] = useState(0);
    const [menuItems, setMenuItems] = useState([]);
    const [visitedSlide, setVisitedSlide] = useState(1);
    const [includeSlides, setIncludeSlides] = useState(false);

    const [showVideo, setShowVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [objectSubtitle, setObjectSubtitle] = useState(null);
    const [videoSubtitle, setVideoSubtitle] = useState({});
    const [subTitleUrl, setSubTitleUrl] = useState("");
    const [videoAssessmentTogether, setVideoAssessmentTogether] = useState(true);
    const [videoText, setVideoText] = useState("");
    const [answerAssessmentVisible, setAnswerAssessmentVisible] = useState(false);
    const [reviewVideoVisible, setReviewVideoVisible] = useState(false);
    const [finisheCourseVisible, setFinisheCourseVisible] = useState(false);
    const [videoOverlayVisible, setVideoOverlayVisible] = useState(false);
    const [includeVideo, setIncludeVideo] = useState(false);
    const [editSubtitle, setEditSubtitle] = useState(false);
    const [subtitleLabel, setSubtitleLabel] = useState("Português");
    const [srclangSubtitle, setSrclangSubtitle] = useState("pt");
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [videoTotalTime, setVideoTotalTime] = useState(0);
    const [interactionPoints, setInteractionPoints] = useState([]);
    const [interactionsVisible, setInteractionsVisible] = useState(false);

    const [projectContent, setProjectContent] = useState("");
    const [projectAssessment, setProjectAssessment] = useState([]);
    const [showAssessment, setShowAssessment] = useState(false);
    const [showButtonAnsAss, setShowButtonAnsAss] = useState(false);
    const [hasBtAnAsClicked, setHasBtAnAsClicked] = useState(false);
    const [hasAssessment, setHasAssessment] = useState(false);
    const [qtdQuestions, setQtdQuestions] = useState(0);
    const [originalQtdQuestions, setOriginalQtdQuestions] = useState(0);
    const [returnIA, setReturnIA] = useState(false);
    const [returnManual, setReturnManual] = useState(false);
    const [closeWindow, setCloseWindow] = useState(false);

    const [chooseTemplate, setChooseTemplate] = useState(defaultStyles);
    const [templateId, setTemplateId] = useState("");

    const [includeContent, setIncludeContent] = useState(false);
    const [includeCase, setIncludeCase] = useState(false);
    const [contentType, setContentType] = useState("");

    const [projectVideoInteraction, setProjectVideoInteraction] = useState([]);
    const [interactionTimes, setInteractionTimes] = useState([]);
    const [currentInteractionTime, setCurrentInteractionTime] = useState(0);
    const [showVideoInteractionObject, setShowVideoInteractionObject] = useState(false);

    const [conclusionDate, setConclusionDate] = useState(null);
    const [firstAccess, setFirstAccess] = useState(null);
    const [lastAccess, setLastAccess] = useState(null);
    const [totalTime, setTotalTime] = useState(null);
    const [bookmark, setBookmark] = useState([]);
    const [status, setStatus] = useState(null);
    const [bookMarkPoint, setBookMarkPoint] = useState(null);
    const [score, setScore] = useState(null);
    const [passingScore, setPassingScore] = useState(null);
    const [courseConcluded, setCourseConcluded] = useState(false);
    const [concluing, setConcluing] = useState(false);

    const carouselRef = useRef(null);
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const interactionTimesInSeconds = useRef([]);
    const triggeredTimes = useRef(new Set());
    const assessmentDivRef = useRef(null);

    var bookMark = null

    const nextButtonCarousel = () => {
        setSlide(slide + 1)
        if (slide + 1 >= visitedSlide) {
            setVisitedSlide(visitedSlide + 1)
        }
    }

    const prevButtonCarousel = () => {
        setSlide(slide - 1)
    }

    const changeMenuPage = (index) => {
        setSlide(index);
    }

    const changeProjectName = (name) => {
        setProjectName(name);
    }

    const handleReviewContentGenerated = (reviewed, jsonContentReviwed) => {
        setProjectContent(jsonContentReviwed)
        setIncludeContent(contentType === "text")
        setIncludeCase(contentType === "case")
    }

    const handleAnswerAssessment = () => {
        setShowAssessment(!showAssessment)
        setShowVideo(false)
    }

    const handleReviewVideo = () => {
        if (videoRef.current) {
            setAnswerAssessmentVisible(false)
            setReviewVideoVisible(false)
            setVideoOverlayVisible(false)
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const answerAssessment = () => {
        setShowPPt(false);
        setShowAssessment(true)
        setHasBtAnAsClicked(true)
    }

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
        switch (lang) {
            case "Portuguese":
                setSubtitleLabel("Português");
                setSrclangSubtitle("pt");
                break;
            case "English":
                setSubtitleLabel("English");
                setSrclangSubtitle("en");
                break;
            case "Spanish":
                setSubtitleLabel("Español");
                setSrclangSubtitle("es");
                break;
            case "French":
                setSubtitleLabel("Français");
                setSrclangSubtitle("fr");
                break
            case "Italian":
                setSubtitleLabel("Italiano");
                setSrclangSubtitle("it");
                break;
            case "Germany":
                setSubtitleLabel("Deutsch");
                setSrclangSubtitle("de");
                break;
            case "Russian":
                setSubtitleLabel("Русский");
                setSrclangSubtitle("ru");
                break;
            case "Mandarin":
                setSubtitleLabel("中文");
                setSrclangSubtitle("zh");
                break;
            case "Japanese":
                setSubtitleLabel("日本語");
                setSrclangSubtitle("ja");
                break;
            case "Korean":
                setSubtitleLabel("한국어");
                setSrclangSubtitle("ko");
                break;
            default:
                setSubtitleLabel("English");
                setSrclangSubtitle("en");
        }
    }

    const handleChangeTemplate = (templateid) => {
        setTemplateId(templateid);
        const stylesMap = {
            default: defaultStyles,
            aqua: aquaStyles,
            blue: blueStyles,
            cleanDark: cleanDarkStyles,
            cleanLight: cleanLightStyles,
            cleanNeutral: cleanNeutralStyles,
            coral: coralStyles,
            fresh: freshStyles,
            green: greenStyles,
            pastel: pastelStyles,
            purpleRain: purpleRainStyles,
            quizai: quizAIStyles,
        };
        document.body.className = '';
        const newTemplate = stylesMap[templateid];
        setChooseTemplate(newTemplate);
        document.body.classList.add(stylesMap[templateId].body_project);

    }

    const convertTextToWebVTT = (subtitles) => {

        const replaceNinthChar = (str) => {
            if (str && str.length < 9) return str;
            return str.substring(0, 8) + '.0' + str.slice(9);
        }

        let webVTTContent = 'WEBVTT\n\n';
        subtitles.forEach((subtitle, index) => {

            const textWithoutNumber = subtitle.words;
            const startTime = subtitle.startTime || subtitle.endTime.replace(/(\d{2}:\d{2}:\d{2}):(\d{2})/, (match, p1, p2) => {
                const endTimeInSeconds = parseInt(p1.split(':')[2]) - 7;
                const newSeconds = endTimeInSeconds < 0 ? 0 : endTimeInSeconds;
                return `${p1}:${newSeconds.toString().padStart(2, '0')}`;
            });

            const endTime = subtitle.endTime || subtitle.startTime.replace(/(\d{2}:\d{2}:\d{2}):(\d{2})/, (match, p1, p2) => {
                const startTimeInSeconds = parseInt(p1.split(':')[2]) + 7;
                const newSeconds = startTimeInSeconds < 0 ? 0 : startTimeInSeconds;
                return `${p1}:${newSeconds.toString().padStart(2, '0')}`;
            });

            webVTTContent += `${index + 1}\n`;
            webVTTContent += `${replaceNinthChar(startTime)} --> ${replaceNinthChar(endTime)}\n`;
            webVTTContent += `${textWithoutNumber}\n\n`;
        });
        return webVTTContent;
    }

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const changeVideoState = (option) => {
        setShowVideoInteractionObject(option)
        videoRef.current.play()
    }

    const playVideoObjectInteraction = () => {
        if (showVideoInteractionObject) {
            videoRef.current.pause()
        } else {
            videoRef.current.play()
        }
    }

    const handleSetResults = (score) => {
        setScore(score);
        setConcluing(true)

        if (score >= passingScore) {
            setStatus("passed");
        } else {
            setStatus("failed");
        }
        setConclusionDate(Timestamp.now());
    }

    const handleFinishCourse = () => {
        returnHome(firstAccess, lastAccess, totalTime, bookmark, "completed", Timestamp.now(), null, false, true);
    }

    const handleCloseLaunchCourse = () => {
        returnHome(firstAccess, lastAccess, totalTime, bookmark, status, conclusionDate, score, courseConcluded, concluing);
    }

    const handleSetBookmark = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            setBookmark(prevBookmark => ({
                ...prevBookmark,
                time: currentTime
            }));
        }
        setCloseWindow(true);
    }
    const changeInteractionsState = () => {
        if (videoRef.current && !videoRef.current.paused) {
            setInteractionsVisible(false);
        }
    }

    useEffect(() => {
        if (closeWindow && bookmark) {
            handleCloseLaunchCourse()
        }
    }, [closeWindow, bookmark])

    useEffect(() => {
        if (objectSubtitle && objectSubtitle.length > 0) {
            const webVTTSubtitles = convertTextToWebVTT(objectSubtitle);
            if (webVTTSubtitles) {
                const subtitlesBlob = new Blob([webVTTSubtitles], { type: 'text/vtt' });
                const url = URL.createObjectURL(subtitlesBlob);
                setSubTitleUrl(url);
            }
        }
    }, [objectSubtitle]);

    useEffect(() => {
        if (videoSubtitle && videoSubtitle.length > 0) {
            const bttsubtitles = convertTextToWebVTT(videoSubtitle)
            const subtitlesBlob = new Blob([bttsubtitles], { type: 'text/vtt' });
            const url = URL.createObjectURL(subtitlesBlob);
            setSubTitleUrl(url)
        }
    }, [videoSubtitle])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        templateId && handleChangeTemplate(templateId)
    }, [templateId])

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('play', () => {
                setEditSubtitle(false);
            });
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play', () => {
                    setEditSubtitle(false);
                });
                setVideoTotalTime(videoRef.current.duration);
            }
        };
    }, [videoRef.current]);

    useEffect(() => {
        if (project && id != "") {
            setProjectName(project.project_name)
            setShowVideo(project.include_video)
            setShowPPt(project.include_slides)
            setUrlLogo(project.logo_url)
            setListUrlSlides(project.listUrlSlides)
            setProjectLoading(false)
            setShowProgress(project.show_progress)
            setMenuItems(project.menu_items)
            setMenuFreeNavigation(project.menu_free_navigation)
            setTemplateId(project.template_id)
            setProjectAssessment(project.assessment)
            setOriginalQtdQuestions(project.assessment.length)
            setPptAssessmentTogether(project.ppt_assessent_together !== undefined ? project.ppt_assessent_together : false);
            setVideoAssessmentTogether(project.video_assessment_together !== undefined ? project.video_assessment_together : false);
            setPptText(project.ppt_text)
            setVideoUrl(project.video_url)
            setVideoText(project.video_text)
            setShowSubtitle(project.show_subtitle !== undefined ? project.show_subtitle : true)
            setIncludeVideo(project.include_video)
            setIncludeSlides(project.include_slides)
            setIncludeContent(project.include_content)
            setIncludeCase(project.include_case)
            setProjectContent(project.content_generated)
            setObjectSubtitle(project.subtitle || "")
            setLanguage(project.project_language)
            setProjectVideoInteraction(project.video_interaction || [])
        } else if (id === "") {
            setProjectLoading(false)
            project = null
            setProjectName("")
            setShowProgress(false)
            setIncludeVideo(false)
            setIncludeSlides(false)
            setListUrlSlides([])
        }
    }, [project])


    useEffect(() => {
        if (projectVideoInteraction) {
            if (projectVideoInteraction.length > 0) {
                setInteractionTimes(projectVideoInteraction.map((interaction) => interaction.interaction_time))
            }
        }
    }, [projectVideoInteraction])

    useEffect(() => {
        if (interactionTimes) {
            interactionTimesInSeconds.current = interactionTimes.map(time => {
                const [hours, minutes, seconds, frames] = time.split(':').map(Number);
                return hours * 3600 + minutes * 60 + seconds + frames / 30;
            });
        }
    }, [interactionTimes]);

    useEffect(() => {

        if (interactionTimes && interactionTimes.length > 0) {
            const handleTimeUpdate = () => {
                if (videoRef.current) {
                    const currentTime = videoRef.current.currentTime;

                    interactionTimesInSeconds.current.forEach(time => {
                        if (Math.abs(currentTime - time) < 0.2 && !triggeredTimes.current.has(time)) {
                            setShowVideoInteractionObject(true);
                            triggeredTimes.current.add(time);
                            setCurrentInteractionTime(time);
                        }
                    });
                }
            };

            const handleVideoSeeked = () => {
                triggeredTimes.current.clear();
            };

            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.addEventListener('timeupdate', handleTimeUpdate);
                videoElement.addEventListener('seeked', handleVideoSeeked);
            }

            return () => {
                if (videoElement) {
                    videoElement.removeEventListener('timeupdate', handleTimeUpdate);
                    videoElement.removeEventListener('seeked', handleVideoSeeked);
                }
            };
        }
    }, [videoRef.current, interactionTimes]);

    useEffect(() => {
        if (showVideoInteractionObject) {
            playVideoObjectInteraction()
        }
    }, [showVideoInteractionObject])

    useEffect(() => {
        if (pptAssessmentTogether != null || videoAssessmentTogether != null) {
            if (pptAssessmentTogether === true || videoAssessmentTogether === true) {
                setShowAssessment(true)
            } else if (pptAssessmentTogether === false && videoAssessmentTogether === false) {
                setShowAssessment(false)
            }
        }
    }, [pptAssessmentTogether, videoAssessmentTogether])

    useEffect(() => {
        if (listUrlSlides) {
            setTotalSlides(listUrlSlides.length)
        }
    }, [listUrlSlides])

    useEffect(() => {
        if (totalSlides && totalSlides !== 0 && slide !== undefined && slide !== null) {
            const progress = ((slide / totalSlides) * 100).toFixed(2);
            setSlidesProgress(parseFloat(progress));
        }
    }, [totalSlides, slide]);

    useEffect(() => {
        if (slide >= visitedSlide - 1) {
            var sl = 0
            if (slide > 0) {
                sl = slide + 1
            } else {
                sl = 0
            }
            const progress = ((sl / totalSlides) * 100).toFixed(2);
            setSlidesProgress(parseFloat(progress))
        }

        setBookmark(prevBookmark => ({
            ...prevBookmark,
            slide: slide
        }));
    }, [slide])

    useEffect(() => {
        if (visitedSlide && visitedSlide === totalSlides && !pptAssessmentTogether && hasAssessment) {
            setShowButtonAnsAss(true);
        }
    }, [visitedSlide])

    useEffect(() => {
        if (showPPt && hasBtAnAsClicked) {
            setSlide(0)
        }
    }, [showPPt])

    useEffect(() => {
        if (projectAssessment && projectAssessment.length > 0) {
            if (projectAssessment[0].ask_question === "" && projectAssessment[0].alternatives[0].text === "") {
                setHasAssessment(false)
            } else {
                setHasAssessment(true)
                setQtdQuestions(projectAssessment.length)
            }
        } else {
            setHasAssessment(false)
            setQtdQuestions(0)
        }

        if (returnIA && assessmentDivRef.current) {
            assessmentDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setReturnIA(false);
        }
    }, [projectAssessment, assessmentDivRef, returnIA]);

    useEffect(() => {
        if (returnManual && assessmentDivRef.current) {
            assessmentDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setReturnManual(false);
        }
    }, [projectAssessment, assessmentDivRef, returnManual])

    useEffect(() => {
        pptText && setProjectContent(pptText)
    }, [pptText])

    useEffect(() => {
        videoText && setProjectContent(videoText)
    }, [videoText])

    useEffect(() => {
        const video = videoRef.current;
        const handleVideoEnded = () => {
            if (videoRef.current) {
                const currentTime = videoRef.current.currentTime;
                setBookmark(prevBookmark => ({
                    ...prevBookmark,
                    time: currentTime
                }));
            }
            if (!videoAssessmentTogether && projectAssessment && projectAssessment.length > 0) {
                setAnswerAssessmentVisible(true);
            }

            if (projectAssessment.length === undefined || projectAssessment.length === 0) {
                setFinisheCourseVisible(true);
            }
            setReviewVideoVisible(true);
            setVideoOverlayVisible(true);
        };
        if (video) {
            video.addEventListener('ended', handleVideoEnded);
            return () => {
                video.removeEventListener('ended', handleVideoEnded);
            };
        }
    }, [videoRef.current, videoAssessmentTogether]);


    useEffect(() => {
        if (qtdQuestions > 0 && !includeVideo && !includeSlides) {
            setShowAssessment(true)
        }
    }, [qtdQuestions, includeVideo, includeSlides])

    useEffect(() => {
        includeCase && setContentType("case")
    }, [includeCase])

    useEffect(() => {
        includeContent && setContentType("text")
    }, [includeContent])

    useEffect(() => {
        if (language) {
            handleChangeLanguage(language)
        }
    }, [language])

    useEffect(() => {
        if (enrollment) {
            const enrollmentFirstAccess = enrollment.first_access
            const enrollmentLastAccess = enrollment.last_access
            const enrollmentStatus = enrollment.status
            const enrollmentTotalTime = enrollment.total_time
            const enrollmentBookmark = enrollment.bookmark
            const enrollmentConclusionDate = enrollment.conclusion_date
            const enrollmentDate = enrollment.enrollment_date
            const projectType = enrollment.bookmark.project
            const enrollmentPassingScore = enrollment.passing_score
            const enrollmentConcluded = enrollment.concluded || false

            const timeNow = Timestamp.now()

            if (enrollmentDate === enrollmentFirstAccess) {
                setFirstAccess(timeNow)
            } else {
                setFirstAccess(enrollmentFirstAccess)
            }

            if (projectType === "video") {
                setBookMarkPoint(enrollment.bookmark.time)
            } else if (projectType === "ppt") {
                setBookMarkPoint(enrollment.bookmark.slide)
            } else {
                bookMark = null
            }

            if (enrollmentStatus === "not started") {
                setStatus("started")
                setFirstAccess(timeNow)
            } else {
                setStatus(enrollmentStatus)
            }

            setCourseConcluded(enrollmentConcluded);
            setPassingScore(enrollmentPassingScore);
            setLastAccess(timeNow);
            setTotalTime(enrollmentTotalTime);
            setStatus("started");
            setConclusionDate(enrollmentConclusionDate);
            setBookmark(enrollmentBookmark);
        }
    }, [enrollment])

    useEffect(() => {
        const projectType = enrollment.bookmark.project
        if (bookMarkPoint !== null) {
            if (projectType === "video" && videoRef.current) {
                videoRef.current.currentTime = bookMarkPoint;
            } else if (projectType === "ppt") {
                setSlide(bookMarkPoint);

            }
        }
    }, [bookMarkPoint, videoRef.current])

    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTotalTime(prevTime => prevTime + 1);
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (interactionTimes && interactionTimes.length > 0) {
            setInteractionPoints(interactionTimes.map(time => {
                const [hours, minutes, seconds, frames] = time.split(':').map(Number);
                return hours * 3600 + minutes * 60 + seconds + frames / 30;
            }));
        }
    }, [interactionTimes]);

    useEffect(() => {
        if (interactionsVisible) {

            const timeoutId = setTimeout(() => {
                setInteractionsVisible(false);
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [interactionsVisible]);

    useEffect(() => {
        const handlePlay = () => {
            setInteractionsVisible(true);
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('play', handlePlay);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play', handlePlay);
            }
        };
    }, [videoRef.current]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={`  `}>
            {projectLoading
                ? <div className="d-flex justify-content-center mt-5">
                    <div className="align-self-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                </div>
                : <div className='d-flex justify-content-center' >

                    <div className={`${includeVideo ? 'col-12' : 'col-11'}`}>
                        <div className={` ${chooseTemplate.custom_bar} ${chooseTemplate.top_bar} ${(includeVideo || includeSlides) ? chooseTemplate.adjust_top_bottom : ``} d-flex  justify-content-around `} id='customBar'>
                            {urlLogo
                                && <div className={`${chooseTemplate.logo} d-flex align-items-center`} >
                                    <img className={`img-fluid p-1  `} src={urlLogo} id="div_logo" />
                                </div>
                            }
                            <div className={`${chooseTemplate.course_title} ${(urlLogo) ? chooseTemplate.adjust_align : styles.adjust_title}`}>
                                <input type="text" id="title" name="title" value={projectName} className={`col-9 ${styles.input_title} ${urlLogo ? 'text-center' : 'text-start'}`} />
                            </div>

                            <div className={`${styles.progressBarWidth}`}>
                                {showProgress &&
                                    <div className={`align-items-center row ${styles.div_progressBar} justify-content-end me-3`}>
                                        <div className={`${chooseTemplate.progressBarContainer} p-0 col-10 col-md-9`} id="progressBarContainer">
                                            <div className={`${chooseTemplate.progressBar} ${slidesProgress === 100 ? chooseTemplate.progressBarEnd : ''}`} id="progressBar" style={{ width: `${slidesProgress}%`, marginLeft: '0' }}></div>
                                            <div className={`${chooseTemplate.progressTextContainer} `} id="progressTextContainer">{slidesProgress}%</div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="">
                                <button type="button" className={`btn  ${styles.btn_closeLaunch}`} onClick={includeVideo
                                    ? handleSetBookmark
                                    : handleCloseLaunchCourse}>
                                    <i className="bi bi-x-circle"></i>
                                </button>
                            </div>
                        </div>

                        <div className={`${idProject ? `` : styles.heightDivSlides}`} id="divSlides">
                            {showVideo && (
                                <div>
                                    <div className={`container p-0 m-0 ${styles.videoContainer}`}>
                                        <div className={videoOverlayVisible ? styles.videoOverlay : ''}></div>

                                        <div className={`${styles.video_container}`}>
                                            <video
                                                className={styles.videoTeste}
                                                controls={true}
                                                controlsList="nodownload"
                                                width="80%"
                                                height="80%"
                                                id="myVideo"
                                                ref={videoRef}
                                                onMouseLeave={interactionPoints ? () => changeInteractionsState() : null}
                                                onMouseEnter={interactionPoints ? () => setInteractionsVisible(true) : null}
                                                onMouseMove={interactionPoints ? () => setInteractionsVisible(true) : null}
                                            >
                                                <source src={videoUrl} type="video/mp4" />
                                                {!editSubtitle && showSubtitle && (
                                                    <track
                                                        className={`${styles.subtitlesPosition}`}
                                                        src={subTitleUrl}
                                                        kind="subtitles"
                                                        srclang={srclangSubtitle}
                                                        label={subtitleLabel}
                                                        default
                                                        style={{ bottom: '20%' }}
                                                    />
                                                )}
                                            </video>

                                            {(interactionsVisible && videoRef.current && !isNaN(videoRef.current.duration) && interactionPoints && interactionPoints.length > 0) && interactionPoints.map((time, index) => (
                                                <div
                                                    key={index}
                                                    className={``}
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '.5%',
                                                        fontSize: '24px',
                                                        color: 'red',
                                                        right: `${90 - ((time / videoRef.current.duration) * 78)}%`
                                                    }}
                                                >
                                                    <i className={`bi bi-info-circle-fill ${styles.timelineIcon}`} onMouseOver={() => setInteractionsVisible(true)}></i>
                                                </div>
                                            ))
                                            }
                                        </div>

                                        {showVideoInteractionObject &&
                                            <div>
                                                <VideoInteractionObject
                                                    changeVideoState={changeVideoState}
                                                    currentInteractionTime={currentInteractionTime}
                                                    projectVideoInteraction={projectVideoInteraction}
                                                    editProject={false}
                                                    language={language}
                                                />
                                            </div>}

                                        <div className={`row position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center`}>
                                            {(reviewVideoVisible || answerAssessmentVisible || finisheCourseVisible) && (
                                                <div className="d-flex justify-content-around w-100">
                                                    {reviewVideoVisible && (
                                                        <div className="col-3">
                                                            <button
                                                                className="w-100 mt-3 btn btn-primary"
                                                                id={`${chooseTemplate.reviewVideo}`}
                                                                style={{ fontSize: '20px', borderRadius: '5em', minWidth: '10em', maxWidth: '10em' }}
                                                                onClick={() => handleReviewVideo()}
                                                            >
                                                                {t('projectpreview:home.Rever')} <br /> {t('projectpreview:home.Video')}
                                                            </button>
                                                        </div>
                                                    )}
                                                    {answerAssessmentVisible && (
                                                        <div className="col-3 ms-2">
                                                            <button
                                                                className="w-100 mt-3 btn btn-primary"
                                                                id={`${chooseTemplate.answerAssessment}`}
                                                                style={{ fontSize: '20px', borderRadius: '5em', minWidth: '10em', maxWidth: '10em' }}
                                                                onClick={() => handleAnswerAssessment()}
                                                            >
                                                                {t('projectpreview:home.Responder')} <br /> {t('projectpreview:home.avaliação')}
                                                            </button>
                                                        </div>
                                                    )}
                                                    {finisheCourseVisible && (
                                                        <div className="col-3 ms-2">
                                                            <button
                                                                className="w-100 mt-3 btn btn-primary"
                                                                id={`${chooseTemplate.answerAssessment}`}
                                                                style={{ fontSize: '20px', borderRadius: '5em', minWidth: '10em', maxWidth: '10em' }}
                                                                onClick={() => handleFinishCourse()}
                                                            >
                                                                {t('projectpreview:home.Finalizar')} <br /> {t('projectpreview:home.Curso')}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showPPt && !showVideo &&
                                <div className={``} >
                                    <Carousel ref={carouselRef} id="carouselSlides" interval={null} indicators={false} controls={false} activeIndex={slide} onSelect={(selectedIndex) => setSlide(selectedIndex)}>
                                        {listUrlSlides && listUrlSlides.map((slideUrl, index) => {
                                            return (
                                                <Carousel.Item key={index}>
                                                    <img src={slideUrl} className={`d-block  ${styles.adjust_slide}`} alt={`slide-${index}`} />
                                                </Carousel.Item>
                                            );
                                        })}
                                    </Carousel>
                                    <div className={`${chooseTemplate.custom_bar} ${chooseTemplate.bottom_bar} ${styles.adjust_bottom} ${(includeVideo || includeSlides) ? chooseTemplate.adjust_top_bottom : ``}`}>
                                        <div className="col-auto btn-group dropup">
                                            <button type="button" className="btn btn-secondary dropdown-toggle menu_button" id={`${styles.menuButton}`} data-bs-toggle="dropdown" >
                                                <i className={`bi bi-list ${chooseTemplate.menu_icon}`}></i>
                                            </button>
                                            <ul className={`dropdown-menu ms-1 `} id={`${styles.slideMenu}`}>
                                                {menuItems && menuItems.map((item, index) => {
                                                    return (
                                                        <div key={index} className={`${styles.menu_items} ${(!menuFreeNavigation && index >= visitedSlide) ? styles.disabled_menu_item : ''}`} onClick={() => changeMenuPage(index)}>
                                                            <li className={`ms-2 mt-1 ${styles.dropdown_item}`} data-bs-target="#carouselSlides" data-bs-slide-to={index}>
                                                                {<span>{item}</span>}
                                                            </li>
                                                        </div>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div className={`col-auto ${chooseTemplate.menu_adjust} `}>
                                            {(showButtonAnsAss && !hasBtAnAsClicked) &&
                                                <button onClick={() => answerAssessment()} className={`${styles.answer_assessment} ${chooseTemplate.background_button}`}>
                                                    {t('projectpreview:home.Responder')} <br /> {t('projectpreview:home.avaliação')}
                                                </button>}
                                            <button onClick={() => prevButtonCarousel()} className={`${chooseTemplate.button_navigation}`} id="prevButtonCarousel" disabled={slide === 0}><i className={`bi bi-arrow-left-circle ${styles.icons_menu}`}></i></button>
                                            <button onClick={() => nextButtonCarousel()} className={`${chooseTemplate.button_navigation}`} id="nextButtonCarousel" disabled={slide === totalSlides - 1}><i className={`bi bi-arrow-right-circle ${styles.icons_menu}`}></i></button>
                                        </div>
                                    </div>

                                </div>
                            }

                            <p></p>
                            <div id='scroolAssessment'></div>

                            {(projectContent && contentType === "text") &&
                                <div className="mb-1">
                                    <AssessmentContent
                                        contentGenerated={projectContent}
                                        editProject={false}
                                        template={chooseTemplate}
                                        language={language}
                                        handleChangeContent={() => { }}
                                        changeProjectName={changeProjectName}
                                        handleReviewContentGenerated={handleReviewContentGenerated}
                                        infoText={`Revise seu texto abaixo:`}
                                    />
                                </div>
                            }

                            {(projectContent && contentType === "case") &&
                                <div className="mb-1">
                                    <AssessmentCase
                                        contentGenerated={projectContent}
                                        editProject={false}
                                        template={chooseTemplate}
                                        language={language}
                                        handleChangeContent={() => { }}
                                        changeProjectName={changeProjectName}
                                        handleReviewContentGenerated={handleReviewContentGenerated}
                                        infoText={`Revise seu case abaixo:`}
                                    />
                                </div>
                            }

                            {(projectAssessment && projectAssessment.length > 0 && showAssessment) &&
                                <div className={`d-flex align-items-center justify-content-center flex-column `} >
                                    <AssessmentQuestions
                                        projectAssessment={projectAssessment}
                                        editProject={false}
                                        template={chooseTemplate}
                                        templateId={templateId}
                                        originalQtdQuestions={originalQtdQuestions}
                                        assessmentDivRef={assessmentDivRef}
                                        handleSetResults={handleSetResults}
                                        handleCloseLaunchCourse={handleCloseLaunchCourse}
                                        courseConcluded={courseConcluded}
                                    />
                                </div>}
                            <div id='scroolEndAssessment'></div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectPreview