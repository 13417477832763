import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import { updateDoc, doc } from "firebase/firestore";

const initialState = {
  loading: null,
  error: null,
};

const updateReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null };
    case "UPDATED_DOC":
      return { loading: false, error: null };
    case "ERROR":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useUpdateDocument = (docCollection) => {
  const [response, dispatch] = useReducer(updateReducer, initialState);

  // deal with memory leak
  const [cancelled, setCancelled] = useState(false);

  const checkCancelBeforeDispatch = (action) => {
    if (!cancelled) {
      dispatch(action);
    }
  };

  const updateDocument = async (document) => {

    var documentId = document.domain;
    const id = document.id;
    const userid = document.userId;

    checkCancelBeforeDispatch({ type: "LOADING" });

    try {
      var collection = "Folder"
      var collection2 = ""

      if (docCollection === "Companys") {
        documentId = document.company_id
        collection = "Users"
        if (document.collection2 === "Enrollments") {
          collection2 = document.collection2
        }
      }

      console.log(docCollection, documentId, collection, userid, collection2, id)

      const docRef = id
        ? collection2 === "Enrollments"
          ? await doc(db, docCollection, documentId, collection, userid, collection2, id)
          : await doc(db, docCollection, documentId, collection, id)
        : await doc(db, docCollection, documentId);

      const updatedDocument = await updateDoc(docRef, document);

      checkCancelBeforeDispatch({
        type: "UPDATED_DOC",
        payload: updatedDocument,
      });
    } catch (error) {
      console.log(error);
      checkCancelBeforeDispatch({ type: "ERROR", payload: error.message });
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { updateDocument, response };
};
