import { format } from 'date-fns';
import { enUS, es, pt } from 'date-fns/locale';
import { useEffect, useState } from 'react';

//User hooks
import { useLanguageValue } from '../context/LanguageContext';

//firebase
import { doc, onSnapshot, Timestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useFetchDocument } from './useFetchDocument';
import { useFetchProjects } from './useFetchProjects';
import { useUpdateDocument } from './useUpdateDocument';

export const useCheckLicenseLimits = (userId) => {

    const [domainIsActive, setDomainIsActive] = useState(true);
    const [disableIA, setDisableIA] = useState(false);
    const [AIHelp, setAIHelp] = useState(true);
    const [trialExpired, setTrialExpired] = useState(false);
    const [msgDialog, setMsgDialog] = useState("");
    const [showImage, setShowImage] = useState(false);
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [failedPayment, setFailedPayment] = useState(false);
    const [dataStorageUsed, setDataStorageUsed] = useState(0);
    const [domainPlan, setDomainPlan] = useState("");
    const [pptVideoInfos, setPptVideoInfos] = useState({});
    const [folder, setFolder] = useState("Folder");
    const [search, setSearch] = useState();
    const [dataFetch, setDataFetch] = useState(false);

    const { preferenceLanguage } = useLanguageValue();
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains");
    const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", userId)
    const { documents: projects, loadingProjects, error } = useFetchProjects(folder, search, userId);
    const { documents: company, loadingCompany, errorCompany } = useFetchProjects("Companys", search, userId, "Users");

    const getPlanInfos = async (domainplan) => {
        try {
            const docRef = doc(db, 'Plans', domainplan);
            const planDoc = await getDoc(docRef);
            if (planDoc.exists()) {
                const domainData = planDoc.data();
                return {
                    dataStorageLimit: domainData.data_storage_limit,
                    studentUsers: domainData.student_users
                };
            } else {
                console.error('No such document!');
                return null;
            }
        } catch (error) {
            console.error('Error getting document:', error);
            return null;
        }
    }

    const checkSubscriptionStatus = () => {

        const domainPlan = domain.plan;
        var status = domain.status;
        const domainActive = domain.domain_active;
        setDomainIsActive(domainActive);
        if (domainPlan === "free") {
            setDisableIA(true);
            setAIHelp(false);
        }

        if (domainActive) {
            if (domainPlan.includes("trial")) {
                status = checkTrialExpired();
            }
        }

        return status
    }

    const checkTrialExpired = () => {
        const expiredTrialDate = domain.expired_trial_date;
        const todayDate = Timestamp.now();
        if (expiredTrialDate < todayDate) {
            setDomainIsActive(false);
            //executeTrialExpired()
            return "expired"
        }

        const executeTrialExpired = async () => {
            setTrialExpired(true);
            const isActive = domain.domain_active;
            if (isActive) {
                const newDocument = {
                    domain: userId,
                    status: "expired",
                    domain_active: false
                }
                await updateDocument(newDocument)
            }
            setMsgDialog('O período do seu plano Trial expirou. Para continuar usando o QuizAI você pode assinar plano Pro ou migrar para o plano Basic (gratuito). ');
            setShowImage(true);
            setOptionsDialog(['SAIBA MAIS'])
            setOpenDialog(true);
        }
    }

    const checkSubscriptionCancelled = async () => {
        const nextBillDate = domain.next_bill_date;
        const nextBillDateObj = new Date(nextBillDate);
        const currentTimestamp = Timestamp.now().toDate();
        if (nextBillDateObj < currentTimestamp) {
            executeDomainCancelled();
        } else {
            setDomainIsActive(true);
        }
    }

    const executeDomainCancelled = () => {
        const cancelledData = domain.last_cancel_date.toDate();
        const formatedDate = format(
            cancelledData,
            preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
            {
                locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
            }
        );
        setMsgDialog(`Essa assinatura foi cancelada por você em ${formatedDate}. Deseja fazer uma nova assinatura?`);
        setOptionsDialog(['Sim', 'Não'])
        setOpenDialog(true);
    }

    const executePaymentFailed = () => {
        setFailedPayment(true);
        setMsgDialog('Ocorreu um problema com sua cobrança. Você pode acessar Detalhes do plano, no menu Minha conta, e alterar a forma de pagamento.');
        setOptionsDialog(["Ok"])
        setOpenDialog(true);
    }

    const checkDomainProjectsLimit = () => {
        let limitAvailable = true;
        let limitPptAvailable = true;
        let limitVideoAvailable = true;
        const totalMonthProjects = totalProjectsMonth();
        const domainPlan = domain.plan;
        const limitMonthProjects = domain.monthly_project_limit;
        const limitPptProjects = domain.ppt_project_limit;
        const limitVideoProjects = domain.video_project_limit;

        if (totalMonthProjects.totalMonthProjects >= limitMonthProjects) {
            limitAvailable = false;
        }

        if (totalMonthProjects.totalPptProjects >= limitPptProjects) {
            limitPptAvailable = false;
        }

        if (totalMonthProjects.totalVideoProjects >= limitVideoProjects) {
            limitVideoAvailable = false;
        }

        if ((totalMonthProjects.totalPptProjects + totalMonthProjects.totalVideoProjects > 3) && domainPlan === "free") {
            limitPptAvailable = false;
            limitVideoAvailable = false;
        }

        return [limitAvailable, limitPptAvailable, limitVideoAvailable];
    };

    const totalProjectsMonth = () => {
        const signatureDateTime = domain.signature_date.toDate();
        const actualDateTime = Timestamp.now().toDate();

        signatureDateTime.setHours(0, 0, 0, 0);
        actualDateTime.setHours(0, 0, 0, 0);

        const signatureDateDay = signatureDateTime.getDate();
        const actualDay = actualDateTime.getDate();
        const actualMonth = actualDateTime.getMonth();
        const actualYear = actualDateTime.getFullYear();
        const baseMonth = signatureDateDay > actualDay ? actualMonth - 1 : actualMonth;
        const baseDate = new Date(actualYear, baseMonth, signatureDateDay);

        const filteredProjects = projects.filter((project) => {
            if (project.id === "folder") {
                return false;
            }

            const creationDate = project.creation_date.toDate();
            creationDate.setHours(0, 0, 0, 0);

            return creationDate >= baseDate && creationDate <= actualDateTime;
        });

        const checkpptvideos = checkTotalPPtVideo(actualDateTime, signatureDateDay, actualDay, actualMonth, actualYear);
        const totalPptProjects = checkpptvideos.pptQtd;
        const totalVideoProjects = checkpptvideos.videoQtd;
        const totalMonthProjects = filteredProjects.length;

        return { totalMonthProjects, totalPptProjects, totalVideoProjects };
    };

    const checkTotalPPtVideo = (actualDateTime, signatureDateDay, actualDay, actualMonth, actualYear) => {
        const baseMonth = signatureDateDay > actualDay ? actualMonth - 1 : actualMonth;
        const baseDate = new Date(actualYear, baseMonth, signatureDateDay);
        const pptProjects = domain.ppt_projects ? domain.ppt_projects : [];
        const videoProjects = domain.video_projects ? domain.video_projects : [];
        const limitRenovation = domain.limit_monthly_renovation ?? true;

        const pptProjectsArray = Object.values(pptProjects);
        const videoProjectsArray = Object.values(videoProjects);

        const filteredPptProjects = pptProjectsArray.filter(project => {
            const projectDate = project.creation_date.toDate();
            return projectDate >= baseDate;
        });
        const pptProjectsQty = limitRenovation ? filteredPptProjects.length : pptProjectsArray.length

        const filteredVideoProjects = videoProjectsArray.filter(project => {
            const projectDate = project.creation_date.toDate();
            return projectDate >= baseDate;
        });
        const videoProjectsQty = limitRenovation ? filteredVideoProjects.length : videoProjectsArray.length

        const infosVideoPPt = {
            pptQtd: pptProjectsQty,
            videoQtd: videoProjectsQty
        }

        setPptVideoInfos(infosVideoPPt)
        return infosVideoPPt

    }

    const checkStudentUsersLimit = async () => {
        const domainPlan = domain.plan;

        const { studentUsers } = await getPlanInfos(domainPlan);

        const studentUsersUsed = company.length || 0;

        const limitStudentUsers = studentUsersUsed < studentUsers;
        const avaliableStudentUsers = studentUsers - studentUsersUsed;

        return [limitStudentUsers, avaliableStudentUsers, studentUsersUsed];
    }

    const checkStorageLimit = async () => {
        const domainPlan = domain.plan;

        const { dataStorageLimit } = await getPlanInfos(domainPlan);

        const pptStorage = domain.ppt_storage_size || 0;
        const videoStorage = domain.video_storage_size || 0;
        const totalSizeInKb = pptStorage + videoStorage;

        const dataStorageUsedPlan = totalSizeInKb;

        const limitStorage = dataStorageUsedPlan < dataStorageLimit;
        const avaliableStorage = dataStorageLimit - dataStorageUsedPlan;

        setDataStorageUsed(totalSizeInKb);
        return [limitStorage, avaliableStorage, dataStorageUsedPlan];
    }

    useEffect(() => {
        if (projects && domain && dataStorageUsed) {
            setDataFetch(true)
            setDomainPlan(domain.plan);
        }
    }, [projects, domain, dataStorageUsed])

    useEffect(() => {
        if (!userId) return;

        const docRef = doc(db, 'Users', userId);

        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setDataFetch(data)

            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error fetching document:', error);
        });

        return () => unsubscribe();
    }, [domain]);

    useEffect(() => {
        if (domain) {
            setDomainPlan(domain.plan);
        }
    }, [domain])

    return { dataFetch, pptVideoInfos, checkSubscriptionStatus, checkDomainProjectsLimit, checkStorageLimit, checkStudentUsersLimit, domainPlan, company }

}