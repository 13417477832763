import { useState, useEffect, useReducer } from 'react';
import { db } from '../firebase/config'
import { collection, doc, setDoc, Timestamp, documentId } from 'firebase/firestore';

const initState = {
    loading: null,
    error: null
}

const insertReducer = (state, action) => {
    switch(action.type) {
        case "LOADING":
            return {loading: true, error: null};
        case "INSERTED_DOC":
            return {loading: false, error: null};
        case "ERROR":
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const useInsertDocument = (docCollection) => {
    const [response, dispatch] = useReducer(insertReducer, initState);

    //deal with memory leak
    const [canceled, setCancelled] = useState(false);
    const checkCancelBeforeDispatch = (action) => {
        if(!canceled){
            dispatch(action);
        }
    }

    const insertDocument = async(document) => {

        var documentId = document.domain;
        const id = document.id; 
        const hubName = document.hub_name;

        checkCancelBeforeDispatch({
            type: "LOADING"
        });

        try {
            const newDocument = {
                ...document, 
                creation_date: Timestamp.now()
            };

            var collection = "Folder"

            if (docCollection === "Companys") {
                collection = "Users"
                documentId = document.company_id
            }
            
            const insertedDocument = await setDoc(
                (id && !hubName)
                    ? doc(db, docCollection, documentId, collection, id)
                    : doc(db, docCollection, documentId),
                newDocument,
            );

            checkCancelBeforeDispatch({
                type: "INSERTED_DOC",
                payload: insertedDocument
            });

        } catch (error) {
            console.log(error)
            checkCancelBeforeDispatch({
                type: "ERROR",
                payload: error.message,
            });
        }
    };

    useEffect(() => {
        return () => setCancelled(true)
    }, [])

    return { insertDocument, response }

}