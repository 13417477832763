//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './AssessmentQuestions.module.css'
import defaultStyles from '../templates/default.module.css'
import aquaStyles from '../templates/aqua.module.css'
import blueStyles from '../templates/blue.module.css'
import cleanDarkStyles from '../templates/cleanDark.module.css'
import cleanLightStyles from '../templates/cleanLight.module.css'
import cleanNeutralStyles from '../templates/cleanNeutral.module.css'
import coralStyles from '../templates/coral.module.css'
import freshStyles from '../templates/fresh.module.css'
import greenStyles from '../templates/green.module.css'
import pastelStyles from '../templates/pastel.module.css'
import purpleRainStyles from '../templates/purpleRain.module.css'
import quizAIStyles from '../templates/quizai.module.css'
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll, getMetadata } from "firebase/storage";
import { storage } from "../firebase/config";
import { useAuthValue } from '../context/AuthContext';
import { useFetchDocument } from '../hooks/useFetchDocument';
//React Hooks
import { useEffect, useState, useRef } from 'react';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
//Dev components
import DialogBox from './DialogBox';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';

const AssessmentQuestions = ({ projectAssessment, editProject, template, templateId, changeAssessment, originalQtdQuestions, assessmentDivRef, handleSetResults, handleCloseLaunchCourse, courseConcluded }) => {

    const userEdit = editProject;
    const scoreRef = useRef(null);

    const [assessment, setAssessment] = useState(projectAssessment);
    const [showFeedback, setShowFeedback] = useState([]);
    const [openDialogDeleteQuestion, setOpenDialogDeleteQuestion] = useState(false);
    const [openDialogDeleteAlternative, setOpenDialogDeleteAlternative] = useState(false);
    const [openDialogDeleteText, setOpenDialogDeleteText] = useState(false);
    const [index, setIndex] = useState(0);
    const [indexChoice, setIndexChoice] = useState(0);
    const [originalCorrectAnswers, setOriginalCorrectAnswers] = useState([]);
    const [chooseTemplate, setChooseTemplate] = useState(template);
    const [colorAskQuestion, setColorAskQuestion] = useState("");
    const [templateTon, setTemplateTon] = useState("white");

    const [question, setQuestion] = useState(null);
    const [alternative, setAlternative] = useState(null);
    const [userChoices, setUserChoices] = useState(["i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i", "i"]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState(0);
    const [score, setScore] = useState(0);
    const [showCloseResults, setShowCloseResults] = useState(false);

    const [justSend, setJustSend] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [msgDialog, setMsgDialog] = useState("");
    const [optionsDialog, setOptionsDialog] = useState(["OK"]);

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const actionScript = (option) => {
        setOpenDialogDeleteAlternative(false);
        setOpenDialogDeleteQuestion(false);
        if (option && alternative !== null && alternative !== undefined) {
            handleDeleteChoice(true, question, alternative);
        } else if (option && !alternative) {
            handleDeleteQuestion(true, question);
        } else if (!option) {
            setQuestion(null);
            setAlternative(null);
        }
    }

    const handleChangeQuestion = (index, value) => {
        const updatedQuestions = [...assessment];
        updatedQuestions[index].ask_question = value;
        setAssessment(updatedQuestions);
    }

    const handleChangeChoices = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...assessment];
        updatedQuestions[questionIndex].alternatives[optionIndex].text = value;
        setAssessment(updatedQuestions);
    }

    const handleChangeCorrectAnswer = (index, value) => {
        const updatedQuestions = [...assessment];
        updatedQuestions[index].correct_answer = value;
        setAssessment(updatedQuestions);
    };

    const handleChangeFeedback = (index, value) => {
        const updatedQuestions = [...assessment];
        updatedQuestions[index].feedback = value;
        setAssessment(updatedQuestions);
    };

    const handleDeleteChoice = (confirmed, i, j) => {
        if (confirmed) {
            const choice = assessment[i].alternatives[j]
            let correct = assessment[i].correct_answer
            const newAlternatives = assessment[i].alternatives.filter(
                alt => alt !== choice
            )
            const options = ["a", "b", "c", "d", "e"]
            newAlternatives.map((alternative, i) => {
                if (alternative.option === correct) {
                    correct = options[i]
                }
                alternative.option = options[i]
            })
            const newAssessment = [...assessment]
            newAssessment[i].alternatives = newAlternatives
            newAssessment[i].correct_answer = correct
            setAssessment(newAssessment)
        }
    }

    const handleDeleteQuestion = (confirmed, i) => {
        if (confirmed) {
            const newAssessment = [...assessment.slice(0, i), ...assessment.slice(i + 1)];
            setAssessment(newAssessment)
        }
        setOpenDialogDeleteQuestion(false)
    }

    const handleAddAlternative = (i) => {
        const alternativesQty = assessment[i].alternatives.length;

        let optionValue;
        switch (alternativesQty) {
            case 2:
                optionValue = "c";
                break
            case 3:
                optionValue = "d";
                break
            case 4:
                optionValue = "e";
                break
            default:
                optionValue = ""
                break
        }

        const newAlternative = { option: optionValue, text: "" }
        const alternatives = assessment[i].alternatives;
        const newAlternatives = [...alternatives, newAlternative]
        const newAssessment = [...assessment]
        newAssessment[i].alternatives = newAlternatives

        setAssessment(newAssessment)

    }

    const handleShowFeedback = (i, feedState) => {
        const mapFeed = [...showFeedback];
        mapFeed[i] = feedState;
        setShowFeedback(mapFeed);
    }

    const handleAddQuestion = () => {
        const newQuestion = {
            alternatives: [
                { option: "a", text: "" },
                { option: "b", text: "" },
                { option: "c", text: "" },
                { option: "d", text: "" },
            ],
            ask_question: "",
            correct_answer: "",
            feedback: ""
        }

        const newAssessment = [...assessment, newQuestion];

        setAssessment(newAssessment);
    }

    const alternativeChoiced = (i, choice) => {
        const choices = [...userChoices];
        if (choices.length <= i) {
            choices.push(choice);
        } else {
            choices.splice(i, 1, choice);
        }
        setUserChoices(choices);
    }

    const openDialogBox = (q, i, j) => {
        setQuestion(i)
        setAlternative(j)
        if (j === undefined) {
            setOpenDialogDeleteAlternative(false)
            setOpenDialogDeleteQuestion(true)
        } else {
            setOpenDialogDeleteQuestion(false)
            setOpenDialogDeleteAlternative(true)
        }
    }

    const assessmentScore = () => {
        setJustSend(true)
        scoreRef.current.scrollIntoView({ behavior: "smooth" });
        let corrects = 0;
        let incorrects = 0;
        assessment.forEach((question, index) => {
            const correctAnswer = question.correct_answer;
            const userAnswer = userChoices[index];
            if (correctAnswer === userAnswer) {
                corrects++;
            } else {
                incorrects++;
            }
        });
        const qtdQuestions = corrects + incorrects;
        setTotalQuestions(qtdQuestions);
        setCorrectAnswer(corrects);
        const finalScore = (corrects / qtdQuestions) * 100;
        showCloseResults && handleSetResults(finalScore);
        setScore(finalScore)
    }

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleCloseAssessment = () => {
        handleCloseLaunchCourse();
    }

    useEffect(() => {
        if (handleSetResults) {
            setShowCloseResults(true)
        }
    }, [handleSetResults])

    useEffect(() => {
        if (projectAssessment) {
            setAssessment(projectAssessment)
        }
    }, [projectAssessment])

    useEffect(() => {
        if (template) {
            setChooseTemplate(template);
        }
    }, [template])

    useEffect(() => {
        if (templateId) {
            const colorStylesMap = {
                default: "#ffffff",
                aqua: "#ffffff",
                blue: "#2CA4F4",
                cleanDark: "#ffffff",
                cleanLight: "#ffffff",
                cleanNeutral: "#ffffff",
                coral: "#004D73",
                fresh: "#9B3976",
                green: "#009BB5",
                pastel: "#344B47",
                purpleRain: "#ffffff",
                quizAI: "#ffffff",
            };
            setColorAskQuestion(colorStylesMap[templateId]);
            const colorButtonMap = {
                default: "white",
                aqua: "white",
                blue: "black",
                cleanDark: "white",
                cleanLight: "black",
                cleanNeutral: "white",
                coral: "black",
                fresh: "black",
                green: "black",
                pastel: "black",
                purpleRain: "white",
                quizai: "white",
            };
            setTemplateTon(colorButtonMap[templateId]);
        }
    }, [templateId])

    useEffect(() => {
        if (assessment && assessment != projectAssessment) {
            changeAssessment(assessment, true)
        }
    }, [assessment])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    return (
        <div className={`${chooseTemplate.form_assessment} `}>
            <div ref={scoreRef} >
                {totalQuestions > 0 &&
                    <div className={`score ${chooseTemplate.results}`} {...(showCloseResults && { onClick: () => handleCloseAssessment(score) })}>
                        {t('projectpreview:assessmentquestion.assessment result', {
                            correctAnswer: correctAnswer,
                            totalQuestions: totalQuestions,
                            score: score.toFixed(2),
                            interpolation: { prefix: '${', suffix: '}' }
                        })}
                        {showCloseResults && <p className={`${chooseTemplate.textP}`}>{t('projectpreview:assessmentquestion.clique para fechar')}</p>}
                    </div>}

            </div>
            {projectAssessment.length === 1 && <div className={`assessmentDivRef row`} ref={assessmentDivRef}></div>}
            {assessment.map((q, i) => (
                <div key={i}>
                    <form >
                        <div className={`mt-4 ${chooseTemplate.question_feedback} `}>
                            <div className={`${chooseTemplate.question}`}>
                                <div className="row ">
                                    {(assessment.length > 0 && editProject) &&
                                        <div className={`${templateTon === "white" ? styles.icon_color_white : styles.icon_color_black} ${styles.icons_question} d-flex align-items-center justify-content-end`} onClick={() => openDialogBox(q.ask_question, i)}>
                                            <i className={`bi bi-x-circle me-1`}></i>
                                        </div>}
                                </div>
                                <div className={`row d-flex align-items-top`}  >
                                    <div className='col-auto'>{(i + 1).toString() + " - "}</div>
                                    <div className="col-11">
                                        <textarea
                                            disabled={!editProject}
                                            className={`col-12 ${chooseTemplate.text_question} ${chooseTemplate.field_textArea_question} `}
                                            type="text"
                                            name="question"
                                            required
                                            placeholder={t('projectpreview:assessmentquestion.insira o enunciado')}
                                            value={q.ask_question}
                                            onChange={(e) => handleChangeQuestion(i, e.target.value)}
                                            style={{ color: colorAskQuestion }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2'>
                                {openDialogDeleteQuestion && <DialogBox
                                    action={'delete'}
                                    actionScript={actionScript}
                                    questionDialog={t('projectpreview:assessmentquestion.certeza que deseja excluir a questão', {
                                        question: index + 1,
                                        interpolation: { prefix: '${', suffix: '}' }
                                    })}
                                    optionsDialog={[t('projectpreview:assessmentquestion.cancelar'), t('projectpreview:assessmentquestion.excluir')]}
                                    index={index}
                                    isopen={true}
                                />}
                            </div>
                            <div className="mt-1">
                                {q.alternatives.map((alternative, j) => (
                                    <div className="row mt-1" key={j} >
                                        <div className={`col ${editProject ? 'col-11' : 'col-12'}`}>
                                            <div className={`form-check ${editProject && `ms-5 d-flex align-items-center`}`}>
                                                {editProject &&
                                                    <div className=''>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`alternative-${i}`}
                                                            value={alternative.option}
                                                            checked={alternative.option === q.correct_answer}
                                                            onChange={(e) => handleChangeCorrectAnswer(i, e.target.value)}
                                                        />
                                                    </div>}
                                                <textarea
                                                    readOnly={editProject ? false : true}
                                                    className={`${styles.textArea} ${editProject && `ms-5`} ${alternative.option === userChoices[i] ? chooseTemplate.label_selected : chooseTemplate.label_assessment}`}
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    value={alternative.text}
                                                    onChange={(e) => handleChangeChoices(i, j, e.target.value)}
                                                    onClick={!editProject ? () => alternativeChoiced(i, alternative.option) : null}
                                                />
                                            </div>
                                        </div>
                                        {(editProject && alternative.option != q.correct_answer) &&
                                            <div className="col-auto d-flex align-items-center">
                                                <div className={`iconDelete ${templateTon === "white" ? styles.icon_color_white : styles.icon_color_black} ${styles.icons_alternative}  ms-3`} onClick={() => openDialogBox(alternative.text, i, j)}>
                                                    <i className={`bi bi-x-circle me-1 `}></i>
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {openDialogDeleteAlternative &&
                                                <DialogBox
                                                    action={'delete'}
                                                    actionScript={actionScript}
                                                    questionDialog={t('projectpreview:assessmentquestion.certeza que deseja excluir a alternativa')}
                                                    optionsDialog={[t('projectpreview:assessmentquestion.cancelar'), t('projectpreview:assessmentquestion.excluir')]}
                                                    index={index}
                                                    indexChoice={indexChoice}
                                                    isopen={true}
                                                />}
                                        </div>
                                    </div>
                                ))}
                                {q.alternatives.length < 5 && editProject &&
                                    <div className={`row d-flex align-items-center ${styles.new_alternative} ${templateTon === "white" ? styles.icon_color_white : styles.icon_color_black}`} onClick={() => handleAddAlternative(i)}>
                                        <div className="col-auto">
                                            <i className={`bi bi-plus-circle me-1`}></i>
                                        </div>
                                        <div className="col-auto">
                                            {t('projectpreview:assessmentquestion.incluir nova alternativa')}
                                        </div>
                                    </div>
                                }
                            </div>
                            <p></p>
                            {(editProject || totalQuestions > 0) &&
                                <div className={` ${chooseTemplate.correct_answer}`} style={{ height: 'auto', minHeight: '5rem' }}>
                                    {editProject &&
                                        <div className="col-5 form-check form-switch">
                                            <div className="form-check-label ">
                                                {t('projectpreview:assessmentquestion.incluir feedback')}
                                                <input
                                                    disabled={!editProject}
                                                    className={`form-check-input ${chooseTemplate.option_check} `}
                                                    id="flex-switch"
                                                    type="checkbox"
                                                    checked={showFeedback[i] !== false ? true : false}
                                                    onChange={(e) => handleShowFeedback(i, e.target.checked)}
                                                />
                                            </div>
                                        </div>}
                                    {(showFeedback[i] !== false) &&
                                        <div className=''>
                                            <textarea
                                                className={`col-12 ${chooseTemplate.field_textArea}`}
                                                disabled={!editProject}
                                                type="text"
                                                name="feedback"
                                                required
                                                placeholder={t('projectpreview:assessmentquestion.insira o feedback')}
                                                value={q.feedback}
                                                onChange={(e) => handleChangeFeedback(i, e.target.value)}
                                            />
                                        </div>}
                                </div>}
                        </div>
                        <p></p>

                        {assessment.length === i + 1 && editProject && assessment.length < 20 && <div
                            className=' mt-1 d-flex justify-content-start'
                            onClick={handleAddQuestion}>
                            <button
                                className={`${styles.btn_style} ${styles.btn_excludeQuestion}`}>
                                <i className="bi bi-plus-circle me-2"></i>
                                {t('projectpreview:assessmentquestion.incluir nova questão')}
                            </button>
                        </div>}

                        <p></p>
                        {!justSend && <div>
                            {assessment.length === i + 1 &&
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className={`${chooseTemplate.submitButton}`} onClick={() => assessmentScore()}>{t('projectpreview:assessmentquestion.enviar')}</div>
                                </div>
                            }
                        </div>}
                    </form>

                    <p></p>

                </div>))}
            {projectAssessment.length > 1 && <div className={`assessmentDivRef row`} ref={assessmentDivRef}></div>}
        </div>
    )
}

export default AssessmentQuestions