//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './MngUsers.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useCreateUserDoc } from '../hooks/useCreateUserDoc';
import { useCreatUserCompany } from '../hooks/useCreateUserCompany';
import { useFetchProjects } from '../hooks/useFetchProjects';
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useCheckLicenseLimits } from '../hooks/useCheckLicenseLimits';
//Dev Components
import EditUser from './EditUser';
import DialogBox from '../components/DialogBox';
import UserInfoEnrollment from './UserInfoEnrollment';
//context
import { useAuthValue } from '../context/AuthContext';
import { useLanguageValue } from '../context/LanguageContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set, setSeconds } from 'date-fns';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const MngUsers = ({ userInfo }) => {

    const { user } = useAuthValue();
    const auth = getAuth();
    const db = getFirestore();

    const [lastLogin, setLastLogin] = useState(null);

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [userProfile, setUserProfile] = useState("");
    const [department, setDepartment] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [activeUser, setActiveUser] = useState(true);
    const [userLanguage, setUserLanguage] = useState(navigator.language);
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
    const [companyName, setCompanyName] = useState(userInfo.company);
    const [companyID, setCompanyID] = useState(userInfo.company_id);
    const [showPopup, setShowPopup] = useState(false);
    const [creatingUser, setCreatingUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUserEnrollments, setSelectedUserEnrollments] = useState();
    const [showUsers, setShowUsers] = useState(true);
    const [showUserInfos, setShowUserInfos] = useState(false);
    const [showEnrollments, setShowEnrollments] = useState(false);
    const [showUserEnrollments, setShowUserEnrollments] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [deletingUser, setDeletingUser] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState("");

    const [statusFilter, setStatusFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState('');

    const [limitStudents, setLimitStudents] = useState(true);
    const [avaliableStudents, setAvaliableStudents] = useState(0);
    const [studentsUsed, setStudentsUsed] = useState(0);      

    const { createUserDoc } = useCreateUserDoc();
    const { createUserCompany } = useCreatUserCompany();

    const { documents: users, loadinUsers, error } = useFetchProjects("Companys", "", companyID, "Users");
    const { dataFetch, pptVideoInfos, checkSubscriptionStatus, checkDomainProjectsLimit, checkStorageLimit, checkStudentUsersLimit, domainPlan, company } = useCheckLicenseLimits(userInfo.domain);

    const checkStudentLimits = async () => {

        const [limitStudentUsers, avaliableStudentUsers, studentUsersUsed] = await checkStudentUsersLimit();

        setLimitStudents(limitStudentUsers);
        setAvaliableStudents(avaliableStudentUsers);
        setStudentsUsed(studentUsersUsed);

    }

    const handleOpenPopup = async () => {
        if(limitStudents){
            setShowPopup(true);
        } else {
            setMsgDialog("You have reached the limit of students allowed in your plan. Please, upgrade your plan to add more students.");
            setAction("error");
            setOptionsDialog(["Ok"]);
            setOpenDialog(true);
        }
    }

    const handleCreateUser = async () => {
        setCreatingUser(true);
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/createUser?email=${email}&password=${password}`, {
                method: 'GET',
            });

            const data = await response.json();
            if (data.error) {
                console.log('User created successfully:', data.error.message);
                showError(data.error.message);
                return
            }
            setUserId(data.uid);
        } catch (error) {
            console.error('Error creating user:', error);
            const errorMessage = await error.json();
            showError(errorMessage.error);
        }
    }

    const returnNormalState = () => {
        setShowUsers(true);
        setShowUserInfos(false);
        setShowEnrollments(false);
        setSelectedUser(null);
        setSelectedUserEnrollments(null);
        setShowUserEnrollments(false);
        setSelectedUser(null);
    }

    const handleEditUser = (user) => {
        setSelectedUser(user);
    }

    const handleDeleteUser = (userId) => {
        setUserIdToDelete(userId);
        setAction("delete");
        setMsgDialog("Are you sure you want to delete this user?");
        setOptionsDialog(["Cancel", "Delete"]);
        setOpenDialog(true);
    }

    const handleUserEnrollments = (user) => {
        setSelectedUserEnrollments(user);
        setShowUsers(false);
        setShowUserInfos(false);
        setShowEnrollments(false);
    }

    const deleteUser = async (userId) => {
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/deleteUser?uid=${userId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');

            }

            await deleteDocUser("Companys", companyID, "Users", userId);
            setDeletingUser(false);
            await deleteDocUser("Users", userId, null, null);
            await deleteDocUser("Domains", userId, null, null);
            await deleteStorageFolder("users", userId);

        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const deleteDocUser = async (collection1, docId1, collection2, userId) => {

        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/deleteDocument?collection1=${collection1}&docId1=${docId1}&collection2=${collection2}&docId2=${userId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('Document deleted successfully');
            // Optionally, you can refresh the document list or update the state here
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    }

    const deleteStorageFolder = async (collection, userid) => {
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/deleteStorageFolder?collection=${collection}&folderId=${userid}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('Storage folder deleted successfully');
            // Optionally, you can refresh the document list or update the state here
        } catch (error) {
            console.error('Error deleting storage folder:', error);
        }
    }

    const actionDelete = async (confirmed) => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        if (confirmed) {
            deleteUser(userIdToDelete);
            setDeletingUser(true);
        }
    }

    const actionCloseDialogBox = () => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        setOptionsDialog([]);
    }

    const showError = (error) => {
        setCreatingUser(false);
        setOpenDialog(true);
        setMsgDialog(error);
        setAction("error");
        setOptionsDialog(["Ok"]);
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString();
    };

    const updateInfos = () => { }

    const checkPassword = () => {
        if (password !== confirmPassword) {
            console.log("passwords do not match");
            setMsgDialog("Passwords do not match. Please, try again.");
            setOpenDialog(true);
            setAction("error");
            setOptionsDialog(["Ok"]);
            return
        }
        handleCreateUser();
    }

    useEffect(() => {
        if (selectedUser) {
            setShowUsers(false);
            setShowUserInfos(true);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (selectedUserEnrollments) {
            setShowUsers(false);
            setShowUserEnrollments(true);
        }
    }, [selectedUserEnrollments]);

    useEffect(() => {
        if (deletingUser) {
            setAction("processing");
            setMsgDialog("Deleting user");
            setOptionsDialog([]);
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
            setAction("");
            setMsgDialog("");
            setOptionsDialog([]);
        }
    }, [deletingUser])

    useEffect(() => {
        if (userId) {
            const userInfos = {
                email: email,
                displayName: name,
                profile: userProfile,
                department: department,
                jobTitle,
                company: companyName,
                company_id: companyID,
                language: preferenceBrowserLanguage,
                userID: userId,
                plan: "Free",
                country: "",
                user_active: true,
                user_author: false
            }
            const resDoc = createUserDoc(userInfos);

            const userCompanyInfos = {
                displayName: name,
                profile: userProfile,
                department: department,
                jobTitle,
                userID: userId,
                id: userId,
                company_id: companyID,
                user_active: true
            }

            const resUserComp = createUserCompany(userCompanyInfos);

            setCreatingUser(false);
            setEmail("");
            setPassword("");
            setName("");
            setUserProfile("");
            setDepartment("");
            setJobTitle("");
        }
    }, [userId]);

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        if (userInfo) {
            setActiveUser(userInfo.active);
            setCompanyName(userInfo.company);
            setCompanyID(userInfo.company_id);
        }
    }, [userInfo])


    useEffect(() => {
        if (domainPlan && company) {
            checkStudentLimits()
        }
    }, [domainPlan, company])

    return (
        <div className={styles.container}>
            {showUsers &&
                <div className='p-4'>
                    {users && users.length > 0
                        ? <div>
                            <div className='d-flex justify-content-between align-items-center mb-2'>

                                <div className="col-3 fw-bold">
                                    <input
                                        type="text"
                                        className={`form-control ${styles.filterName}`}
                                        placeholder="User name search..."
                                        value={nameFilter}
                                        onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
                                    />
                                </div>

                                <div className="col-1 fw-bold">
                                    <select
                                        id="statusFilter"
                                        className={`form-select ${styles.filters} pe-5`}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Status</option>
                                        <option value="all">All</option>
                                        <option value="active">Active</option>
                                        <option value="not active">Not Active</option>
                                    </select>
                                </div>
                                <div className="col-2 fw-bold">
                                    <label htmlFor="createdOn" >
                                        Last access
                                    </label>
                                    <div className={`row d-flex aling-items-center  `}>
                                        <input
                                            id="createdOn"
                                            type="date"
                                            className={`form-control col-1  ${styles.filterDate}`}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 fw-bold d-flex justify-content-end pe-5">
                                    Total users: {users.length}
                                </div>

                            </div>
                            {<hr />}

                            <div className={`${styles.divUsers}`}>
                                {users && users.filter(user =>
                                    (statusFilter === '' || statusFilter === 'all' || (statusFilter === 'active' && user.user_active) || (statusFilter === 'not active' && !user.user_active)) &&
                                    (nameFilter === "" || user.name.toLowerCase().startsWith(nameFilter)) &&
                                    (!startDate || (user.last_access && new Date(user.last_access.seconds * 1000) >= new Date(startDate)))
                                )
                                    .filter(user => !user.deleted)
                                    .map((user, index) => (
                                        <div key={user.id}>
                                            <div className="d-flex justify-content-between align-items-center mb-2">

                                                <span className={`col-3 ${styles.textsFormatted}`}>
                                                    <div className={`row d-flex align-items-center ${styles.formatTexts}`}>
                                                        <div className="col-auto d-flex align-items-center ">
                                                            {user.url_user_image ? (
                                                                <img src={user.url_user_image} alt="User" className={`${styles.thumbnailCircular}`} />
                                                            ) : (
                                                                <i className={`bi bi-person-circle ${styles.iconPerson}`} ></i>
                                                            )}
                                                        </div>
                                                        {user.name}
                                                    </div>
                                                </span>
                                                <span className="col-1">
                                                    {user.user_active
                                                        ? <div className={`${styles.formatTexts}`}><i className={`bi bi-person-check ${styles.icons}`}></i>  Active</div>
                                                        : <div className={`${styles.formatTexts}`}><i className={`bi bi-person-slash ${styles.icons}`}></i>  Not active</div>}
                                                </span>
                                                <span className={`col-2 ${styles.formatTexts}`}>
                                                    <i className="bi bi-calendar me-2"></i>
                                                    {user.last_access ? formatDate(user.last_access) : "N/A"}
                                                </span>
                                                <div className={`col-4`}>
                                                    <button className={`btn btn-primary me-2 col-4 ${styles.userButton}`} onClick={() => handleEditUser(user)}>
                                                        <i className="bi bi-pencil-square"></i> Edit
                                                    </button>
                                                    <button className={`btn btn-danger me-2 col-4 ${styles.userButton}`} onClick={() => handleDeleteUser(user.id)}>
                                                        <i className="bi bi-trash"></i> Delete
                                                    </button>

                                                    <button className={`btn btn-success col-4 ${styles.userButton}`} onClick={() => handleUserEnrollments(user)}>
                                                        <i className="bi bi-ticket"></i> Enrollments
                                                    </button>
                                                </div>
                                            </div>
                                            {<hr />}
                                        </div>
                                    ))}

                            </div>
                        </div>
                        : <div className={`d-flex align-items-center mt-2 mb-4 ${styles.formatTexts}`}>
                            No users found. Please, click on the button below to add a new user.
                        </div>
                    }
                    <button className={`btn btn-primary ${styles.addButton}`} onClick={() => handleOpenPopup()}>
                        <i className="bi bi-plus-circle ms-2 me-2 mt-4"></i> Add User
                    </button>
                </div>
            }

            {showUserInfos && selectedUser &&
                <EditUser
                    returnNormalState={returnNormalState}
                    selectedUser={selectedUser} />
            }

            {showUserEnrollments && selectedUserEnrollments &&
                <div>
                    <UserInfoEnrollment
                        updateInfos={updateInfos}
                        returnPage={returnNormalState}
                        infoUserEnroll={selectedUserEnrollments}
                    />
                </div>}

            {showPopup && (
                <div className={styles.popup}>
                    {
                        creatingUser
                            ? (
                                <div className={`d-flex flex-column justify-content-center align-items-center mt-5`}>
                                    <div className="spinner-border text-primary" role="status"></div>
                                    <br />
                                    <p>Creating User</p>
                                </div>
                            ) : (
                                <div className={styles.popupContent}>
                                    <div>
                                        <h3 className={`mb-3`}> <i className={`bi bi-person-add ${styles.icon}`}></i>Add user</h3>
                                    </div>
                                    <div>
                                        <div className={styles.inputGroup}>
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className={styles.input}
                                            />
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <div className="input-group">
                                                <input
                                                    type={passwordVisible ? "text" : "password"}
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className={styles.inputPassword}
                                                />
                                                <button
                                                    className={`btn btn-outline-secondary ${styles.eyeButton}`}
                                                    type="button"
                                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                                >
                                                    <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <div className="input-group">
                                                <input
                                                    type={confirmPasswordVisible ? "text" : "password"}
                                                    placeholder="Confirm Password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    className={styles.inputPassword}
                                                />
                                                <button
                                                    className={`btn btn-outline-secondary ${styles.eyeButton}`}
                                                    type="button"
                                                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                                >
                                                    <i className={`bi ${confirmPasswordVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className={styles.input}
                                            />
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <select
                                                value={userProfile}
                                                onChange={(e) => setUserProfile(e.target.value)}
                                                className={styles.input}
                                            >
                                                <option value="" disabled>Select User Profile</option>
                                                <option value="student">Student</option>
                                                <option value="admin">Admin</option>
                                            </select>
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <input
                                                type="text"
                                                placeholder="Department"
                                                value={department}
                                                onChange={(e) => setDepartment(e.target.value)}
                                                className={styles.input}
                                            />
                                        </div>
                                        <div className={styles.inputGroup}>
                                            <input
                                                type="text"
                                                placeholder="Job Title"
                                                value={jobTitle}
                                                onChange={(e) => setJobTitle(e.target.value)}
                                                className={styles.input}
                                            />
                                        </div>
                                        <div className={`row ${styles.div_buttons}`}>
                                            <button onClick={() => setShowPopup(false)} className={styles.buttons}>Close</button>
                                            <button
                                                onClick={checkPassword}
                                                className={` ms-3 ${!email || !password || !confirmPassword || !name || !userProfile ? styles.disabledButton : styles.buttons}`}
                                                disabled={!email || !password || !confirmPassword || !name || !userProfile}
                                            >
                                                Create User
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            )}
            {openDialog &&
                <div>
                    <DialogBox
                        action={action}
                        actionScript={action === "delete" ? actionDelete : action === "error" ? actionCloseDialogBox : ""}
                        questionDialog={msgDialog}
                        optionsDialog={optionsDialog}
                        isopen={true}
                    />
                </div>
            }
        </div>
    )
}

export default MngUsers