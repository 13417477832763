//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './LearnHub.module.css'
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//Components
import MngUsers from '../../components/MngUsers';
import MngEnrollments from '../../components/MngEnrollments';
import MngDashboards from '../../components/MngDashboards';
import MngConfig from '../../components/MngConfig';
// Hooks
import { useEffect } from 'react';

const LearnHub = ({ userSetLanguage, userInfo, hideMenuItem }) => {

    useEffect(() => {
        hideMenuItem("learnhub")
      }, [])

    return (
        <div className={`${styles.body_project}`}>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link active ${styles.abaItems}`} id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="true">
                        Users
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${styles.abaItems}`} id="enrollment-tab" data-bs-toggle="tab" data-bs-target="#enrollment" type="button" role="tab" aria-controls="enrollment" aria-selected="false">
                        Courses
                    </button>
                </li>
                {/** <li className="nav-item" role="presentation">
                    <button className={`nav-link ${styles.abaItems}`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">
                        Dashboards
                    </button>
                </li> **/}
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${styles.abaItems}`} id="config-tab" data-bs-toggle="tab" data-bs-target="#config" type="button" role="tab" aria-controls="config" aria-selected="false">
                        Configuration
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade show active ${styles.backAba}`} id="users" role="tabpanel" aria-labelledby="users-tab">
                    <MngUsers userInfo={userInfo} />
                </div>
                {userInfo &&
                    <div className={`tab-pane fade ${styles.backAba}`} id="enrollment" role="tabpanel" aria-labelledby="enrollment-tab">
                        <MngEnrollments userInfo={userInfo} />
                    </div>}
                {/**<div className={`tab-pane fade ${styles.backAba}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <MngDashboards />
                </div>**/}
                <div className={`tab-pane fade ${styles.backAba}`} id="config" role="tabpanel" aria-labelledby="config-tab">
                    <MngConfig userInfo={userInfo} />
                </div>
            </div>
        </div>
    )
}

export default LearnHub