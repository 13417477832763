// Estilos
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './NavbarCompany.module.css'
//React Hooks
import { useEffect, useState } from 'react'
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useAuthentication } from '../hooks/useAuthentications';
import { useAuthValue } from '../context/AuthContext';
import { useLanguageValue } from '../context/LanguageContext';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';

import scormAiLogo from '../images/logo-ScormAI.png';

const NavbarCompany = ({ changeSiteLanguage, changeNavBar, company, userInfo, hideItem, authorShip }) => {

    const { t } = useTranslation(['navbar']);
    const { preferenceLanguage } = useLanguageValue();
    const userLanguage = navigator.language;

    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [urlUserImage, setUrlUserImage] = useState("");

    const [userCompanyId, setUserCompanyId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyUrlLogo, setCompanyUrlLogo] = useState("");
    const [companyColorBanner, setCompanyColorBanner] = useState("");
    const [bannerColor, setBannerColor] = useState("")
    const [textsColor, setTextsColor] = useState("")
    const [companyHubName, setCompanyHubName] = useState("");

    const { logout } = useAuthentication();
    const { user } = useAuthValue();
    const navigate = useNavigate();
    //const { document: userInfo, loadingDocDomain, errorDocDomain } = useFetchDocument("Users", userId)

    const changeUserLanguage = (lang) => {
        changeSiteLanguage(lang)
    }

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        user && setUserId(user.uid)
    }, [user])

    useEffect(() => {
        if (userInfo) {
            setUserName(userInfo.name);
            setUserCompanyId(userInfo.company_id);

        }

    }, [userInfo])

    const handleLogout = () => {
        changeNavBar(true);
        logout()
        navigate("/hub")
    }
    

    useEffect(() => {
        if (company && userCompanyId) {
            setCompanyName(company.name);
            setCompanyUrlLogo(company.logo);
            setCompanyColorBanner(company.color);
            setBannerColor(company.color);
            setTextsColor(company.text_color);
            setCompanyHubName(company.hub_name);
            setUrlUserImage(userInfo.url_user_image);
        }
    }, [company, userCompanyId])

    return (
        <div className={`navbar row navbar-expand-lg ${styles.navbar} justify-content-lg-between justify-content-md-between justify-content-sm-center `} id="bottom-navbar-container" style={{ backgroundColor: bannerColor }}>
            <div className={`d-flex col-auto align-items-center`}>
                <NavLink to="/" className={styles.brand}>
                    <img src={companyUrlLogo || scormAiLogo} alt="companyLogo" className={styles.logo} />
                </NavLink>
            </div>
            <div className="col-auto">
                <h3
                    style={{ color: textsColor }}>
                    {companyHubName}
                </h3>
            </div>
            {user && <div className="col-auto d-flex justify-content-end ">
                <div className={`col-auto ${styles.menu}`}>

                    <NavLink
                        to="#"
                        className="nav-link dropdown-toggle d-flex align-items-center"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ color: textsColor }}
                    >
                        {urlUserImage ? (
                            <img src={urlUserImage} alt="User" className={`${styles.thumbnailCircular}`} />
                        ) : (
                            <i className={`bi bi-person-circle ${styles.iconPerson}`} ></i>
                        )}
                        {userName}

                    </NavLink>
                    <li className={`nav-item dropdown ${styles.links_list}`}>
                        <ul className="dropdown-menu" id="dropdown-menu">
                            {hideItem != "mycourses" && <li>
                                <NavLink
                                    to="/"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-files align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menunavcompany.Meus cursos`)}
                                </NavLink>
                            </li>}

                            {hideItem != "myprojects" && <li>
                                {authorShip &&
                                    <NavLink
                                        to="/myprojects"
                                        className="nav-link d-flex align-items-center ms-2"
                                        activeClassName={styles.active}
                                    >
                                        <i className="bi bi-files align-text-bottom me-2 fs-5"></i>
                                        {t(`navbar:menu.Meus projetos`)}
                                    </NavLink>}
                            </li>}

                            {hideItem != "newproject" && <li>
                                {authorShip &&
                                    <NavLink
                                        to="/newproject"
                                        className="nav-link d-flex align-items-center ms-2"
                                        activeClassName={styles.active}
                                    >
                                        <i className="bi bi-file-earmark-plus align-text-bottom me-2 fs-5"></i>
                                        {t(`navbar:menu.Novo projeto`)}
                                    </NavLink>}
                            </li>}

                            {<li>
                                <NavLink
                                    to="/Seuperfil"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-person-fill align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menunavcompany.Minha conta`)}
                                </NavLink>
                            </li>}

                            <li>
                                <div className={`d-flex align-items-center ms-1 mt-1 ${styles.logoutButton}`}>
                                    <button className={`${styles.buttons}`} onClick={() => handleLogout()}>
                                        <i className="bi bi-box-arrow-left align-text-bottom me-2 fs-5"></i> {t(`navbar:menunavcompany.Sair`)}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>}
        </div>
    )
}

export default NavbarCompany