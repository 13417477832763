import { id } from 'date-fns/locale';
import { db } from '../firebase/config';
import { useInsertDocument } from './useInsertDocument';
import { Timestamp } from 'firebase/firestore';

export const useCreateCompany = () => {
    let userID = "";

    const { insertDocument, response } = useInsertDocument("Companys");

    const createCompany = async (userInfos) => {

        userID = userInfos.userID;

        const newCompany = {
            color: "#e9eaed",
            company_id: userInfos.userID,
            id: userInfos.userID,
            name: userInfos.company,
            text_color: "#000000",
            hub_name: userInfos.company,
            logo: "https://firebasestorage.googleapis.com/v0/b/quizzai-4b3cd.appspot.com/o/logos%2Fscormai%2Flogo-ScormAI.png?alt=media&token=3c8e2f89-f48e-442e-88aa-ff8af589f1d2",
        }

        console.log("newCompany ", newCompany);

        await insertDocument(newCompany);
    
    }

    return {createCompany};

}