//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './EditEnrollment.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useCreateEnrollment } from '../hooks/useCreateEnrollment';
//Dev Components
import DialogBox from '../components/DialogBox';
import UserInfoEnrollment from './UserInfoEnrollment';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';
//Firebase
import { Timestamp, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";

const EditEnrollment = ({ returnNormalState, enrollment, users }) => {

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();
    const { createEnrollment } = useCreateEnrollment();

    const [companyId, setCompanyId] = useState(enrollment.company_id);
    const [enrolledUsers, setEnrolledUsers] = useState(enrollment.users_enrolled || []);
    const [courseName, setCourseName] = useState(enrollment.course_name);
    const [courseDescription, setCourseDescription] = useState(enrollment.course_description);
    const [courseImage, setCourseImage] = useState(null);
    const [urlCourseImg, setUrlCourseImg] = useState(enrollment.course_image);
    const [passingScore, setPassingScore] = useState(enrollment.passing_score);
    const [validateDate, setValidateDate] = useState(enrollment.validate_date);
    const [enrollmentInfos, setEnrollmentInfos] = useState([]);
    const [enrollmentId, setEnrollmentId] = useState(enrollment.id);
    const [courseId, setCourseId] = useState(enrollment.course_id);
    const [projectType, setProjectType] = useState(enrollment.project_type);
    const [activeEnroll, setActiveEnroll] = useState(enrollment.active);
    const [deletedEnroll, setDeletedEnroll] = useState(enrollment.deleted);
    const [changeActive, setChangeActive] = useState(enrollment.active);
    const [showPupup, setShowPopup] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [addingUserEnrollment, setAddingUserEnrollment] = useState(false);
    const [enrollmentUserIdDelete, setEnrollmentUserIdDelete] = useState('');
    const [showButtonAdd, setShowButtonAdd] = useState(true);
    const [userEnrollSelected, setUserEnrollSelected] = useState(null);
    const [infoUserEnroll, setInfoUserEnroll] = useState(null);
    const [showInfosEnrollment, setShowInfosEnrollment] = useState(true);
    const [showUserInfoEnroll, setShowUserInfoEnroll] = useState(false);
    const [disabledFields, setDisabledFields] = useState(true);
    const [updatingEnrollment, setUpdatingEnrollment] = useState(false);
    const [accessUponCompletion, setAccessUponCompletion] = useState(enrollment.access_upon_completion || false);
    const [changeUponCompletion, setChangeUponCompletion] = useState(enrollment.access_upon_completion || false);

    const [openDialog, setOpenDialog] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');

    const [statusFilter, setStatusFilter] = useState('');
    const [nameFilter, setNameFilter] = useState("");
    const [popupNameFilter, setPopupNameFilter] = useState('');
    const [addAllUsers, setAddAllusers] = useState(false);

    const handleCloseWindow = () => {
        returnNormalState()
    }

    const returnPage = () => {
        setShowUserInfoEnroll(false)
        setShowInfosEnrollment(true)
        setUserEnrollSelected(null)
    }

    const fetchUserEnrollments = async () => {
        const userEnrollments = await Promise.all(enrolledUsers.map(async (userId) => {
            const userEnrollmentRef = doc(db, `Companys/${companyId}/Users/${userId}/Enrollments/${enrollment.id}`);
            const userEnrollmentSnap = await getDoc(userEnrollmentRef);
            if (userEnrollmentSnap.exists()) {
                return { userId, enrollment: userEnrollmentSnap.data() };
            } else {
                return { userId, enrollment: null };
            }
        }));
        setEnrollmentInfos(userEnrollments);
    };

    const addUserEnrollment = async () => {
        setAddingUserEnrollment(true);
        setShowPopup(false);
        var dataCreation = Timestamp.now();

        const enrollmentItems = {
            actionDoc: "insertuser",
            active: activeEnroll,
            bookmark: {
                project: projectType,
                slide: 0,
                time: 0
            },
            concluded: false,
            conclusion_date: dataCreation,
            course_description: courseDescription,
            course_id: courseId,
            course_image: urlCourseImg,
            course_name: courseName,
            domain: selectedUsers,
            domain_id: companyId,
            enrollment_date: dataCreation,
            enrollment_id: enrollmentId,
            first_access: dataCreation,
            id: enrollmentId,
            last_access: dataCreation,
            passing_score: passingScore,
            score: 0,
            status: "not started",
            total_time: 0,
            validate_date: validateDate,
            publisher: "",
            enrolledUsers: enrolledUsers
        }

        await createEnrollment(enrollmentItems);
        setEnrolledUsers([...enrolledUsers, ...selectedUsers]);
        setAddingUserEnrollment(false);

        if (courseName != enrollment.course_name ||
            courseDescription != enrollment.course_description ||
            passingScore != enrollment.passing_score ||
            validateDate != enrollment.validate_date ||
            courseImage) {
            saveEnrollment();
        }

    }

    const handleDeleteUser = (userId) => {
        setEnrollmentUserIdDelete(userId);
        setAction("delete");
        setMsgDialog(`Are you sure do you want to delete this user enrollment? <br/>This action can't be undone!`);
        setOptionsDialog(["Cancel", "Delete"]);
        setOpenDialog(true);
    }

    const actionCloseDialogBox = () => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        setOptionsDialog([]);
    }

    const actionDelete = async (confirmed) => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        if (confirmed) {
            excludeUserEnrollment(enrollmentUserIdDelete);

        }
    }

    const excludeUserEnrollment = async (userId) => {
        const userEnrollmentRef = doc(db, `Companys/${companyId}/Users/${userId}/Enrollments/${enrollment.id}`);
        await deleteDoc(userEnrollmentRef);
        const enrolledUpdating = enrolledUsers.filter(id => id !== userId)
        setEnrolledUsers(enrolledUpdating)

        const enrollmentItems = {
            actionDoc: "updateuser",
            domain_id: companyId,
            enrollment_id: enrollmentId,
            domain: enrolledUpdating,
            publisher: "",
        }

        await createEnrollment(enrollmentItems);
    }

    const handleChangeImage = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];
            if (file) {
                setCourseImage(file);
                //await uploadImage(file);
            }
        };
        fileInput.click();
    }

    const handleSaveEnrollInfos = async () => {

        const enrollmentItems = {
            actionDoc: "updateenrollment",
            access_upon_completion: changeUponCompletion,
            active: changeActive,
            deleted: deletedEnroll,
            course_id: courseId,
            domain_id: companyId,
            enrollment_id: enrollmentId,
            id: enrollmentId,
            domain: [],
        }
        await createEnrollment(enrollmentItems);
    }

    const saveEnrollment = () => {
        setUpdatingEnrollment(true);
        if (courseImage) {
            uploadImage(courseImage);
        } else {
            changeEnrollInfos();
        }
    }

    const changeEnrollInfos = async () => {
        const enrollInfos = {
            actionDoc: "updateinfosenrollment",
            active: changeActive,
            access_upon_completion: changeUponCompletion,
            course_name: courseName,
            course_description: courseDescription,
            course_image: urlCourseImg,
            passing_score: passingScore,
            validate_date: validateDate,
            course_id: courseId,
            domain_id: companyId,
            enrollment_id: enrollmentId,
            id: enrollmentId,
            domain: enrolledUsers,
        }
        console.log("enrollInfos ", enrollInfos)
        await createEnrollment(enrollInfos);
        setUpdatingEnrollment(false);
        fetchUserEnrollments();
    }

    const uploadImage = async (file) => {

        //await handleDeleteImage();

        const forcedFileName = 'thumbnail';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {

                const maxSize = 300 * 1024;
                if (file.size > maxSize) {
                    setUpdatingEnrollment(false);
                    setOpenDialog(true);
                    setOptionsDialog(['Ok']);
                    const msg = ("The file exceeds 300 Kb.");
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                setUploadingFile(true);
                const storageRef = ref(storage, `companys/${companyId}/${enrollmentId}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlCourseImg(url);

                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            setUploadingFile(false);
            console.error('Erro geral:', error);
        }
    }

    const userInfoEnroll = (user) => {
        const userEnroll = enrollmentInfos.find(info => info.userId === user.id);
        setUserEnrollSelected(userEnroll);
        setInfoUserEnroll(user)
    }

    useEffect(() => {
        if (urlCourseImg && urlCourseImg !== enrollment.course_image) {
            changeEnrollInfos();
        }
    }, [urlCourseImg])

    useEffect(() => {
        if (userEnrollSelected && infoUserEnroll) {
            setShowUserInfoEnroll(true);
            setShowInfosEnrollment(false);
        }
    }, [userEnrollSelected, infoUserEnroll])

    useEffect(() => {
        if (changeActive != activeEnroll) {
            handleSaveEnrollInfos();
        }
    }, [changeActive])

    useEffect(() => {
        if (changeUponCompletion != accessUponCompletion) {
            handleSaveEnrollInfos();
        }
    }, [changeUponCompletion])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])


    useEffect(() => {
        if (enrolledUsers && enrolledUsers.length > 0) {
            fetchUserEnrollments();
            setDisabledFields(true);
        } else if (enrolledUsers && enrolledUsers.length === 0) {
            setDisabledFields(false);
        }

    }, [enrolledUsers]);

    useEffect(() => {
        if (enrolledUsers.length === users.length) {
            setShowButtonAdd(false);
        } else {
            setShowButtonAdd(true);
        }
    }, [enrolledUsers, users])

    useEffect(() => {
        if (updatingEnrollment) {
            setMsgDialog("Updating course...");
            setOpenDialog(true)
            setAction("processing")
        } else {
            setOpenDialog(false)
            setAction("")
            setMsgDialog("")
        }
    }, [updatingEnrollment])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };


    return (
        <div>
            {showInfosEnrollment &&
                <div>
                    <div className="row ">
                        <div className={`${styles.iconButton} d-flex align-items-end justify-content-end`}>
                            <i className={`bi bi-box-arrow-left me-4 ${styles.icons}`} onClick={() => handleCloseWindow()}></i>
                        </div>
                    </div>
                    <div className={`ps-5 pb-3 ${styles.div_informations}`}>
                        <div className="d-flex">
                            <div className="w-50 me-3">
                                <form className={`${styles.div_informations}`}>
                                    <div className='row d-flex mt-5 '>
                                        <div className={`form-group d-flex align-items-center col-4`}>
                                            <input
                                                type="checkbox"
                                                className={`form-check-input ${styles.checkbox}`}
                                                id="activeEnroll"
                                                checked={changeActive}
                                                onChange={(e) => setChangeActive(e.target.checked)}
                                                disabled={false}
                                            />
                                            <label className={`${styles.labelStyle} ${styles.formatTexts} ms-2`} htmlFor="activeEnroll">Active Course</label>
                                        </div>
                                        <div className={`form-group d-flex align-items-center justify-content-end col-8 `}>
                                            <input
                                                type="checkbox"
                                                className={`form-check-input ${styles.checkbox}`}
                                                id="activeEnroll"
                                                checked={changeUponCompletion}
                                                onChange={(e) => setChangeUponCompletion(e.target.checked)}
                                                disabled={false}
                                            />
                                            <label className={`${styles.labelStyle} ${styles.formatTexts} ms-2`} htmlFor="activeEnroll">Course accessible upon completion
                                            </label>
                                        </div>
                                    </div>

                                    <div className={`form-group `}>
                                        <label className={`${styles.labelStyle} ${styles.formatTexts} mt-3`} htmlFor="courseName">Course Name</label>
                                        <input
                                            type="text"
                                            className={`form-control ${styles.inputStyle}`}
                                            id="courseName"
                                            value={courseName}
                                            onChange={(e) => setCourseName(e.target.value)}
                                        //disabled={disabledFields}
                                        />
                                    </div>

                                    <div className={`form-group mt-3`}>
                                        <label className={`${styles.labelStyle} ${styles.formatTexts}`} htmlFor="courseDescription">Course Description</label>
                                        <textarea
                                            className={`form-control ${styles.inputStyle}`}
                                            id="courseDescription"
                                            rows="2"
                                            value={courseDescription}
                                            onChange={(e) => setCourseDescription(e.target.value)}
                                        //disabled={disabledFields}
                                        ></textarea>
                                    </div>

                                    <div className="row mt-3">
                                        <div className={`form-group col-6  `}>
                                            <label className={`${styles.labelStyle} ${styles.formatTexts}`} htmlFor="passingScore">Passing Score - 0 to 100%</label>
                                            <input
                                                type="number"
                                                className={`form-control ${styles.input}`}
                                                id="passingScore"
                                                value={passingScore}
                                                onChange={(e) => {
                                                    const value = Math.min(e.target.value, 100);
                                                    setPassingScore(value);
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                min={0}
                                                max={100}
                                            />
                                        </div>
                                        <div className={`form-group col-6  `}>
                                            <label className={`${styles.labelStyle} ${styles.formatTexts}`} htmlFor="validateDate">Valid Until</label>
                                            <input
                                                type="date"
                                                className={`form-control ${styles.input}`}
                                                id="validateDate"
                                                value={validateDate && validateDate.seconds ? new Date(validateDate.seconds * 1000).toLocaleDateString('en-CA') : ''}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={(e) => {
                                                    const selectedDate = new Date(e.target.value);
                                                    selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset());
                                                    setValidateDate(Timestamp.fromDate(selectedDate));
                                                }}
                                            //disabled={disabledFields}
                                            />
                                        </div>
                                    </div>

                                    <div className={`form-group mt-3`}>
                                        <label className={`${styles.labelStyle} ${styles.formatTexts}`} htmlFor="courseImage">Course Image</label>
                                        {courseImage ? (
                                            <div className={styles.thumbnailContainer}>
                                                <img
                                                    src={URL.createObjectURL(courseImage)}
                                                    alt="Course Thumbnail"
                                                    className={styles.thumbnail}
                                                    onClick={handleChangeImage}
                                                />
                                                {<button
                                                    className={styles.deleteButton}
                                                    onClick={() => {
                                                        setCourseImage('');
                                                        setUrlCourseImg('');
                                                    }}
                                                >
                                                    <i className="bi bi-x-circle"></i>
                                                </button>}
                                            </div>
                                        ) : urlCourseImg
                                            ? (<div>
                                                <img
                                                    src={urlCourseImg}
                                                    alt="Course Thumbnail"
                                                    className={styles.thumbnail}
                                                //disabled={disabledFields}
                                                />
                                                {
                                                    <button
                                                        className={styles.deleteButton}
                                                        onClick={() => {
                                                            setCourseImage('');
                                                            setUrlCourseImg('');
                                                        }}
                                                    >
                                                        <i className="bi bi-x-circle"></i>
                                                    </button>}
                                            </div>)
                                            : (
                                                <input
                                                    type="file"
                                                    className={`form-control ${styles.inputStyle}`}
                                                    id="courseImage"
                                                    onChange={(e) => setCourseImage(e.target.files[0])}
                                                    placeholder="No file chosen"
                                                //disabled={disabledFields}
                                                />
                                            )}
                                    </div>
                                </form>
                                {<div>
                                    <button
                                        className={`btn btn-primary ms-3 mt-4 col-2 ${styles.userButton}`}
                                        onClick={() => saveEnrollment()}
                                        disabled={
                                            courseName === enrollment.course_name &&
                                            courseDescription === enrollment.course_description &&
                                            passingScore === enrollment.passing_score &&
                                            validateDate === enrollment.validate_date &&
                                            !courseImage
                                        }
                                    >
                                        <i className="bi bi-save me-2"></i>  Save Changes
                                    </button>
                                </div>}
                            </div>
                            <div className="w-50 ms-5">
                                <div className={`mt-3`}>
                                    <h6>{`Course enrolled users:`}</h6>
                                </div>
                                {addingUserEnrollment
                                    ?
                                    <div className='d-flex align-items-center justify-content-center flex-column'>
                                        <div className={`col-6 text-center`}>
                                            <div className="spinner-grow text-primary" role="status"></div>
                                            <h5 className={`mt-1 ${styles.questionDialog}`}>
                                                {`Adding user enrollment...`}
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <div >
                                        {enrolledUsers.length > 0 && enrollmentInfos.length > 0 && (
                                            <div className={`mt-3 row d-flex`}>
                                                {/* Campo de entrada para filtrar pelo nome */}
                                                <div className="col-5 filter_name">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${styles.filterName}`}
                                                        placeholder="User name search..."
                                                        value={nameFilter}
                                                        onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
                                                    />
                                                </div>

                                                {/* Filtro por status */}
                                                <div className="col-3 position-relative">
                                                    <select
                                                        id="statusFilter"
                                                        className={`form-select ${styles.filters} pe-5`}
                                                        onChange={(e) => setStatusFilter(e.target.value)}
                                                        defaultValue=""
                                                    >
                                                        <option value="" disabled>Status</option>
                                                        <option value="all">All</option>
                                                        <option value="completed">Completed</option>
                                                        <option value="passed">Passed</option>
                                                        <option value="failed">Failed</option>
                                                        <option value="started">Started</option>
                                                        <option value="not started">Not Started</option>
                                                    </select>
                                                </div>
                                                <div className='mt-2 mb-2'></div>

                                                {/* Filtrando usuários pelo nome e status */}
                                                <div className={`ms-2 ${styles.scrollDiv}`} >
                                                    {users
                                                        .filter(user =>
                                                            enrolledUsers.flat().includes(user.id) &&
                                                            (statusFilter === '' || statusFilter === 'all' ||
                                                                enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status === statusFilter) &&
                                                            (nameFilter === "" || user.name.toLowerCase().includes(nameFilter))
                                                        )
                                                        .map((user, index) => {
                                                            return (
                                                                <div key={index} className={`d-flex align-items-center mt-1`}>
                                                                    <div className="me-3 col-auto d-flex align-items-center ">
                                                                        {user.url_user_image ? (
                                                                            <img src={user.url_user_image} alt="User" className={`${styles.thumbnailCircular}`} />
                                                                        ) : (
                                                                            <i className={`bi bi-person-circle ${styles.iconPerson}`} ></i>
                                                                        )}
                                                                    </div>
                                                                    <div className="d-flex col-4 align-items-center">
                                                                        <p className={`mb-0 ${styles.formatTexts}`}>{user.name}</p>
                                                                    </div>
                                                                    <div className='ms-3 col-4'>
                                                                        <p className={`mb-0 ${styles.formatTexts}`}>
                                                                            {enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status.charAt(0).toUpperCase() +
                                                                                enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status.slice(1) || 'Status not found'}
                                                                        </p>
                                                                    </div>

                                                                    <div className="col-1">
                                                                        <i className={`bi bi-trash ${styles.iconsEnroll}`} onClick={() => handleDeleteUser(user.id)}></i>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <i className={`bi bi-info-circle ${styles.iconsEnroll}`} onClick={() => userInfoEnroll(user)}></i>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {showButtonAdd &&
                                            <div>
                                                <button className={`btn btn-primary mt-3 ${styles.userButton}`} onClick={() => setShowPopup(true)}><i class="bi bi-person-fill-add"></i>   Add user enrollment</button>
                                            </div>}
                                    </div>}
                            </div>
                        </div>
                        {showPupup &&
                            <div className={`${styles.popup}`}>
                                <div className="d-flex justify-content-end">
                                    <button className={`${styles.closeButton}`} onClick={() => setShowPopup(false)}>
                                        <i className="bi bi-x"></i>
                                    </button>
                                </div>
                                <div className={`${styles.addEnroll}`}>
                                    <h6>{`Select the users to enroll:`}</h6>
                                    <input
                                        type="text"
                                        className={`form-control ${styles.filterName}`}
                                        placeholder="Name search..."
                                        value={popupNameFilter}
                                        onChange={(e) => setPopupNameFilter(e.target.value.toLowerCase())}
                                    />
                                    <div className={`form-check mt-2`}>
                                        <input
                                            className={`form-check-input ${styles.boxes}`}
                                            type="checkbox"
                                            id={`userCheckboxAll`}
                                            value="all"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAddAllusers(true);
                                                    setSelectedUsers(users.filter(user => !enrolledUsers.includes(user.id) && (popupNameFilter === "" || user.name.toLowerCase().includes(popupNameFilter))).map(user => user.id));
                                                } else {
                                                    setAddAllusers(false);
                                                    setSelectedUsers([]);
                                                }
                                            }}
                                        />
                                        <label className={`form-check-label  ${styles.formatTexts} fw-bold`} htmlFor={`userCheckboxAll`}>
                                            Select all
                                        </label>
                                    </div>
                                    <div className={`mt-1 ${styles.scrollDivNames}`}>
                                        {users.filter(user => !enrolledUsers.includes(user.id) && (user.user_active) && (popupNameFilter === "" || user.name.toLowerCase().startsWith(popupNameFilter))).map((user, index) => (
                                            <div key={index} className={`d-flex align-items-center mt-1 ${styles.div_enrolledUser} `}>
                                                <input
                                                    type="checkbox"
                                                    className="me-2"
                                                    checked={selectedUsers.includes(user.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedUsers([...selectedUsers, user.id]);
                                                        } else {
                                                            setSelectedUsers(selectedUsers.filter(id => id !== user.id));
                                                        }
                                                    }}
                                                />
                                                <p className={`mb-0 ${styles.formatTexts}`}>{user.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className={`btn btn-primary mt-3 ${styles.userButton}`}
                                        onClick={() => {
                                            addUserEnrollment()
                                        }}
                                        disabled={selectedUsers.length === 0}
                                    ><i class="bi bi-person-fill-add"></i>   Save enroll</button>
                                </div>
                            </div>}
                    </div>
                </div>
            }

            {showUserInfoEnroll &&
                <div>
                    <UserInfoEnrollment
                        updateInfos={fetchUserEnrollments}
                        returnPage={returnPage}
                        userEnrollSelected={userEnrollSelected}
                        infoUserEnroll={infoUserEnroll}
                    />
                </div>
            }

            {openDialog &&
                <div>
                    <DialogBox
                        action={action}
                        actionScript={action === "delete" ? actionDelete : action === "error" ? actionCloseDialogBox : ""}
                        questionDialog={msgDialog}
                        optionsDialog={optionsDialog}
                        isopen={true}
                    />
                </div>}
        </div>
    )
}

export default EditEnrollment