import { id } from 'date-fns/locale';
import { db } from '../firebase/config';
import { useInsertDocument } from './useInsertDocument';
import { Timestamp } from 'firebase/firestore';


export const useCreatUserCompany = () => {
    let userID = "";

    const { insertDocument, response } = useInsertDocument("Companys");

    const createUserCompany = async (userInfos) => {

        userID = userInfos.userID;

        const newUser = {
            job_title: userInfos.jobTitle,
            company_id: userInfos.company_id,
            name: userInfos.displayName,
            user_active: true,
            profile: userInfos.profile,
            department: userInfos.department,
            id: userID,
        }

        await insertDocument(newUser);
    
    }

    return {createUserCompany};

}
