//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './MngEnrollments.module.css'
//React Hooks
import { useState, useEffect, useRef } from 'react';
//Dev Hooks
import { useCreateEnrollment } from '../hooks/useCreateEnrollment';
import { useFetchProjects } from '../hooks/useFetchProjects';
//Dev Components
import EditEnrollment from './EditEnrollment';
import CourseReport from './CourseReport';
import DialogBox from '../components/DialogBox';
//context
import { useLanguageValue } from '../context/LanguageContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase/config";

const MngEnrollments = ({ userInfo }) => {

    const dateInputRef = useRef(null);

    const userDomain = userInfo.domain;
    const companyID = userInfo.company_id;

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();

    const [showEnrollments, setShowEnrollments] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [creatingEnrollment, setCreatingEnrollment] = useState(false);
    const [enrollmentCreated, setEnrollmentCreated] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    const [selectedCourse, setSelectedCourse] = useState("");
    const [docFile, setDocFile] = useState("word");
    const [courseId, setCourseId] = useState("");
    const [hasAssessment, setHasAssessment] = useState(false);
    const [courseImage, setCourseImage] = useState("");
    const [urlCourseImg, setUrlCourseImg] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [projectType, setProjectType] = useState("");
    const [passingScore, setPassingScore] = useState(0);
    const [enrollmenttUsers, setEnrollmenttUsers] = useState([]);
    const [validateDate, setValidateDate] = useState(null);
    const [enrollmentId, setEnrollmentId] = useState("");
    const [userStudentId, setUserStudentId] = useState([]);

    const [showEnrollmentsInfo, setShowEnrollmentsInfo] = useState(false);
    const [selectedEnrollment, setSelectedEnrollment] = useState(null);
    const [courseSelected, setCourseSelected] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');
    const [progresspercent, setProgresspercent] = useState(0);

    const [deletingEnrollment, setDeletingEnrollment] = useState(false);
    const [enrollmentToDelete, setEnrollmentToDelete] = useState(null);

    const [userLanguage, setUserLanguage] = useState(navigator.language);
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");

    const [statusFilter, setStatusFilter] = useState('');
    const [popupNameFilter, setPopupNameFilter] = useState('');
    const [popupCourseNameFilter, setPopupCourseNameFilter] = useState('');
    const [courseNameFilter, setCourseNameFilter] = useState('');
    const [startDate, setStartDate] = useState('');
    const [addAllUsers, setAddAllusers] = useState(false);

    const { createEnrollment, responseInsert, responseUpdate } = useCreateEnrollment();

    const { documents: enrollments, loadinEnrollments, errorEnrollments } = useFetchProjects("Companys", "", companyID, "Enrollments");
    const { documents: users, loadinUsers, errorUsers } = useFetchProjects("Companys", "", companyID, "Users");
    const { documents: projects, loadingProjects, error } = useFetchProjects("Folder", "", companyID);

    const dataCreation = Timestamp.now();

    const handleEditEnrollment = (enrollment) => {
        setSelectedEnrollment(enrollment);
    }

    const handleDeleteEnrollment = (enrollment) => {
        setEnrollmentToDelete(enrollment);
        setAction("delete");
        setMsgDialog("Are you sure you want to delete this enrollment? <br/>This action will delete the record and all enrollments, as well as all progress of participants in this course and cannot be undone!");
        setOptionsDialog(["Cancel", "Delete"]);
        setOpenDialog(true);
    }

    const handleReportCourse = (enrollment) => {
        setCourseSelected(enrollment);
    }

    const handleCreateEnrollment = async () => {

        const enrollmentItems = {
            actionDoc: "insert",
            active: true,
            bookmark: {
                project: projectType,
                slide: 0,
                time: 0
            },
            concluded: false,
            conclusion_date: dataCreation,
            course_description: courseDescription,
            course_id: courseId,
            course_image: urlCourseImg,
            course_name: courseName,
            domain: userStudentId,
            domain_id: companyID,
            enrollment_date: dataCreation,
            enrollment_id: enrollmentId,
            first_access: dataCreation,
            id: enrollmentId,
            last_access: dataCreation,
            passing_score: Number(passingScore),
            score: 0,
            status: "not started",
            total_time: 0,
            validate_date: validateDate,
            publisher: userDomain
        }
        await createEnrollment(enrollmentItems);

        if (responseInsert && responseInsert.error) {
            setAction("error");
            setMsgDialog("Error creating enrollment: " + responseInsert.error);
            setOptionsDialog(["Ok"]);
            setOpenDialog(true);
        } else {
            setAction("success");
            setMsgDialog(userStudentId.length > 0 ? "Course and enrollments created successfully!" : "Course created successfully!");
            setOptionsDialog(["Ok"]);
            setOpenDialog(true);
            setEnrollmentCreated(true);
        }

        setCreatingEnrollment(false);
        setShowPopup(false);

        setUrlCourseImg(null);
        setCourseName("")
        setCourseDescription("")
        setCourseImage(null)
        setPassingScore(0)
        setValidateDate(null)
        setUserStudentId([])
    }

    const handleChangeImage = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];
            if (file) {
                setCourseImage(file);
            }
        };
        fileInput.click();
    }

    const handleClosePopup = () => {
        setShowPopup(false);
        setCourseName("");
        setCourseDescription("");
        setPassingScore(0);
        setValidateDate(null);
        setCourseImage(null);
        setCourseId(null);
    }

    const deleteEnrollment = async (enrollment) => {

        const enrollmentItems = {
            actionDoc: "updateenrollment",
            active: false,
            deleted: true,
            course_id: enrollment.course_id,
            domain_id: enrollment.company_id,
            enrollment_id: enrollment.enrollment_id,
            id: enrollment.enrollment_id,
            domain: [],
        }

        await createEnrollment(enrollmentItems);
    }

    const actionDelete = async (confirmed) => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        if (confirmed) {
            deleteEnrollment(enrollmentToDelete);
            setDeletingEnrollment(true);
        }
    }

    const actionCloseDialogBox = () => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        setOptionsDialog([]);
    }

    const saveEnrollment = () => {

        setCreatingEnrollment(true);
        if (courseImage) {
            uploadImage(courseImage);
        } else {
            handleCreateEnrollment();
        }
    }

    const returnNormalState = () => {
        setShowEnrollments(true);
        setShowEnrollmentsInfo(false);
        setShowReport(false)
        setSelectedEnrollment(null);
        setCourseSelected(null);
    }

    const uploadImage = async (file) => {
        setUploadingFile(true);
        //await handleDeleteImage();

        const forcedFileName = 'thumbnail';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {

                const maxSize = 300 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 300Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                setUploadingFile(true);
                const storageRef = ref(storage, `companys/${companyId}/${enrollmentId}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlCourseImg(url);

                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            setUploadingFile(false);
            console.error('Erro geral:', error);
        }
    }

    useEffect(() => {
        if (selectedEnrollment) {
            setShowEnrollments(false);
            setShowReport(false);
            setShowEnrollmentsInfo(true);
        }
    }, [selectedEnrollment]);

    useEffect(() => {
        if (courseSelected) {
            setShowEnrollments(false);
            setShowEnrollmentsInfo(false);
            setShowReport(true);
        }
    }, [courseSelected]);

    useEffect(() => {
        if (enrollments && !showEnrollmentsInfo) {
            setShowEnrollments(true);
        }
    }, [enrollments])

    useEffect(() => {
        if (urlCourseImg) {
            handleCreateEnrollment();
        }
    }, [urlCourseImg])

    useEffect(() => {
        if (courseId) {
            const selected = projects.find(project => project.id === courseId);
            setSelectedCourse(selected);
            if (selected.assessment.length > 0) {
                setHasAssessment(true);
            } else {
                setHasAssessment(false);
            }
        }
    }, [courseId])

    useEffect(() => {
        if (courseName) {
            var enroll = "enroll_" + courseName + Timestamp.now();
            setEnrollmentId(enroll);
        }
    }, [courseName])

    useEffect(() => {
        if (selectedCourse) {
            const projectTypes = {
                include_case: "case",
                include_content: "content",
                include_slides: "ppt",
                include_video: "video"
            };

            for (const [key, value] of Object.entries(projectTypes)) {
                if (selectedCourse[key]) {
                    setProjectType(value);
                    break;
                }
            }
        }
    }, [selectedCourse])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (courseId && courseName) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [courseName, courseId, userStudentId])

    useEffect(() => {
        if (creatingEnrollment && enrollmentId) {
            const enrollmentElement = document.getElementById(`enrollment-${enrollmentId}`);
            if (enrollmentElement) {
                enrollmentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                enrollmentElement.focus();
            }
        }
    }, [creatingEnrollment, enrollmentId]);

    useEffect(() => {
        if (creatingEnrollment) {
            setMsgDialog("Creating enrollment...");
            setOpenDialog(true)
            setAction("processing")
        }
    }, [creatingEnrollment])

    return (
        <div className={`p-2 ${styles.container}`}>
            {enrollments && enrollments.length > 0
                ? <div>
                    {showEnrollments &&
                        <div className="row mb-3 ">
                            <div className='d-flex justify-content-between align-items-center mb-2 mt-3 ms-2'>
                                <div className="col-3 fw-bold">
                                    <input
                                        type="text"
                                        className={`form-control ${styles.filterName}`}
                                        placeholder="Course search..."
                                        value={courseNameFilter}
                                        onChange={(e) => setCourseNameFilter(e.target.value.toLowerCase())}
                                    />
                                </div>
                                <div className="col-2 fw-bold" >
                                    <label htmlFor="createdOn" >
                                        Created on
                                    </label>
                                    <div className={`row d-flex aling-items-center  `}>
                                        <input
                                            id="createdOn"
                                            type="date"
                                            className={`form-control col-1  ${styles.filterDate}`}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            ref={dateInputRef}
                                        />
                                    </div>
                                </div>

                                <div className="col-1 fw-bold">
                                    Enrollments
                                </div>

                                <div className="col-2 fw-bold position-relative">
                                    <select
                                        id="statusFilter"
                                        className={`form-select ${styles.filters} pe-5`}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Status</option>
                                        <option value="all">All</option>
                                        <option value="active">Active</option>
                                        <option value="not active">Not Active</option>
                                    </select>
                                </div>

                                <div className="col-2"></div>
                            </div>
                            {<hr />}

                            <div className={`${styles.divEnrollments}`}>

                                {enrollments && enrollments
                                    .filter(enrollment =>
                                        (statusFilter === '' || statusFilter === 'all' || (statusFilter === 'active' && enrollment.active) || (statusFilter === 'not active' && !enrollment.active)) &&
                                        (courseNameFilter === "" || enrollment.course_name.toLowerCase().includes(courseNameFilter)) &&
                                        (!startDate || new Date(enrollment.creation_date.seconds * 1000) >= new Date(startDate))
                                    )
                                    .filter(enrollment => !enrollment.deleted)
                                    .map((enrollment, index) => (
                                        <div key={enrollment.id} id={`enrollment-${enrollment.id}`}>
                                            <div className="d-flex justify-content-between align-items-center mb-2 ms-3">
                                                <span className={`col-3 ${styles.enroll_infos} ${styles.formatTexts}`}>
                                                    {enrollment.course_name}
                                                </span>
                                                <span className={`col-2 ${styles.formatTexts}`}>
                                                    <i className="bi bi-calendar me-2"></i>
                                                    {new Date(enrollment.creation_date.seconds * 1000).toLocaleDateString()}
                                                </span>
                                                <span className={`col-1 ${styles.formatTexts}`}>
                                                    <i className="bi bi-people-fill me-2"></i>
                                                    {enrollment.users_enrolled.length}
                                                </span>
                                                <span className={`col-1 ${styles.formatTexts}`}>
                                                    {enrollment.active ? 'Active' : 'Not active'}
                                                </span>
                                                <div className={`col-3`}>
                                                    <button className={`btn btn-primary me-2 col-2 ${styles.userButton}`} onClick={() => handleEditEnrollment(enrollment)}>
                                                        <i className="bi bi-pencil-square"></i> Edit
                                                    </button>
                                                    <button className={`btn btn-danger me-2 col-2 ${styles.userButton}`} onClick={() => handleDeleteEnrollment(enrollment)}>
                                                        <i className="bi bi-trash"></i> Delete
                                                    </button>
                                                    <button className={`btn btn-success col-2 ${styles.userButton}`} onClick={() => handleReportCourse(enrollment)} >
                                                        <i className="bi bi-file-earmark-bar-graph"></i> Report
                                                    </button>
                                                </div>
                                            </div>
                                            {<hr />}
                                        </div>
                                    ))
                                }
                            </div>


                        </div>
                    }
                </div>
                : <div className={`d-flex align-items-center ms-3 mt-2 mb-2 ${styles.formatTexts}`}>
                    No enrollments found. Please, click on the button below to add a new enrollment.
                </div>

            }

            {showEnrollments && <div>
                <button className={`btn btn-primary ms-3 mt-4 ${styles.addButton}`} onClick={() => setShowPopup(true)}>
                    <i className="bi bi-plus-circle ms-2 me-2"></i> Add Course
                </button>
            </div>}

            {showPopup && (
                <div className={styles.popup}>
                    {
                        <div className={styles.popupContent}>
                            <div>
                                <h4 className={`mb-3`}> <i className={`bi bi-ticket-perforated ${styles.icon}`}></i>Add Course</h4>
                            </div>
                            <div className={`d-flex ${styles.divContentEnrollment}`}>
                                <div className="w-50 me-3">
                                    <div className={``}>
                                        <h6>Choose the project</h6>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className={`form-control ${styles.filterName}`}
                                                placeholder="Project search..."
                                                value={popupCourseNameFilter}
                                                onChange={(e) => setPopupCourseNameFilter(e.target.value.toLowerCase())}
                                            />
                                        </div>
                                        {projects && projects.length > 0 ? (
                                            <div className={`mt-2 ${styles.scrollDivCourses}`}>
                                                {projects
                                                    .filter(project =>
                                                        (popupCourseNameFilter === "" || (project.project_name && project.project_name.toLowerCase().includes(popupCourseNameFilter))))
                                                    .filter(project => project.project_active === true)
                                                    .map((project, index) => (
                                                        project.id !== "folder" && (
                                                            <div key={project.id} className={`form-check ${styles}`}>
                                                                <input
                                                                    className={`form-check-input ${styles}`}
                                                                    type="radio"
                                                                    name="projectRadio"
                                                                    id={`projectRadio${index}`}
                                                                    value={project.id}
                                                                    onChange={() => setCourseId(project.id)}
                                                                />
                                                                <label className={`form-check-label ${styles.formatTexts}`} htmlFor={`projectRadio${index}`}>
                                                                    {project.project_name}
                                                                </label>
                                                            </div>
                                                        )
                                                    ))}
                                            </div>
                                        ) : (
                                            <p className={`${styles.formatTexts}`}>No projects available</p>
                                        )}
                                    </div>
                                    <div className={`form-group ${styles.inputGroup} `}>
                                        <label className={`${styles.formatTexts} mt-3 `} htmlFor="courseName">Course Name</label>
                                        <input
                                            type="text"
                                            className={`form-control ${styles.input}`}
                                            id="courseName"
                                            value={courseName}
                                            onChange={(e) => setCourseName(e.target.value)}
                                        />
                                    </div>

                                    <div className={`form-group ${styles.inputGroup}`}>
                                        <label className={`${styles.formatTexts}`} htmlFor="courseDescription">Course Description</label>
                                        <textarea
                                            className={`form-control ${styles.input}`}
                                            id="courseDescription"
                                            rows="2"
                                            value={courseDescription}
                                            onChange={(e) => setCourseDescription(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="row">
                                        <div className={`form-group col-6 ${styles.inputGroup}`}>
                                            <label className={`${styles.formatTexts}`} htmlFor="passingScore">Passing Score  - 0 to 100%</label>
                                            <input
                                                type="number"
                                                className={`form-control ${styles.input}`}
                                                id="passingScore"
                                                value={passingScore}
                                                onChange={(e) => {
                                                    const value = Math.min(e.target.value, 100);
                                                    setPassingScore(value);
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                min={0}
                                                max={100}
                                                disabled={!hasAssessment}
                                            />
                                        </div>
                                        <div className={`form-group col-6 ${styles.inputGroup}`}>
                                            <label className={`${styles.formatTexts}`} htmlFor="validateDate">Valid Until</label>
                                            <input
                                                type="date"
                                                className={`form-control ${styles.input}`}
                                                id="validateDate"
                                                value={validateDate && validateDate.seconds ? new Date(validateDate.seconds * 1000).toISOString().split('T')[0] : ''}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={(e) => setValidateDate(Timestamp.fromDate(new Date(e.target.value)))}
                                            />
                                        </div>
                                    </div>
                                    <div className={`form-group ${styles.inputGroup}`}>
                                        <label className={`${styles.formatTexts}`} htmlFor="courseImage">Course Image </label>
                                        {courseImage ? (
                                            <div className={``}>
                                                <img
                                                    src={URL.createObjectURL(courseImage)}
                                                    alt="Course Thumbnail"
                                                    className={styles.thumbnail}
                                                    onClick={handleChangeImage}
                                                />
                                                <button
                                                    className={styles.deleteButton}
                                                    onClick={() => {
                                                        setCourseImage('');
                                                        setUrlCourseImg('');
                                                    }}
                                                >
                                                    <i className="bi bi-x-circle"></i>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className={`custom-file-input ${styles.input}`}
                                                    id="courseImage"
                                                    onChange={(e) => setCourseImage(e.target.files[0])}
                                                    placeholder='Course Image'
                                                />

                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="w-50 ms-5">
                                    <div className={``}>
                                        <h6>{`Select the users to enroll:`}</h6>
                                        <div className="col-8 mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${styles.filterName}`}
                                                placeholder="User search..."
                                                value={popupNameFilter}
                                                onChange={(e) => setPopupNameFilter(e.target.value.toLowerCase())}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`form-check ${styles.selectAll}`}>
                                            <input
                                                className={`form-check-input ${styles.boxes}`}
                                                type="checkbox"
                                                id={`userCheckboxAll`}
                                                value="all"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setAddAllusers(true);
                                                        setUserStudentId(users.map(user => user.id));
                                                    } else {
                                                        setAddAllusers(false);
                                                        setUserStudentId([]);
                                                    }
                                                }}
                                            />
                                            <label className={`form-check-label ${styles.formatTexts} fw-bold`} htmlFor={`userCheckboxAll`}>
                                                Select all
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${styles.scrollDivNames}`}>
                                        {users && users.filter(user =>
                                            (popupNameFilter === "" || (user.name && user.name.toLowerCase().includes(popupNameFilter)))
                                        ).map((user, index) => (
                                            <div key={user.id} className={`form-check `}>
                                                <input
                                                    className={`form-check-input ${styles.boxes}`}
                                                    type="checkbox"
                                                    id={`userCheckbox${index}`}
                                                    value={user.id}
                                                    checked={userStudentId.includes(user.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setUserStudentId([...userStudentId, user.id]);
                                                        } else {
                                                            setUserStudentId(userStudentId.filter(id => id !== user.id));
                                                        }
                                                    }}
                                                />
                                                <label className={`form-check-label ${styles.formatTexts}`} htmlFor={`userCheckbox${index}`}>
                                                    {user.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={`row ${courseImage ? "mt-0" : "mt-3"} ${styles.div_buttons} `}>
                                <button
                                    onClick={handleClosePopup}
                                    className={styles.buttons}
                                >
                                    Close
                                </button>
                                <button
                                    onClick={saveEnrollment}
                                    className={`ms-3 ${styles.buttons} `}
                                    disabled={disabledButton}
                                >
                                    Create Course
                                </button>
                            </div>
                        </div>
                    }
                </div>
            )}
            {(showEnrollmentsInfo && selectedEnrollment) &&
                <div>
                    <EditEnrollment
                        returnNormalState={returnNormalState}
                        enrollment={selectedEnrollment}
                        users={users}
                    />
                </div>}
            {(showReport && courseSelected) && <div>
                <CourseReport
                    returnNormalState={returnNormalState}
                    course={courseSelected}
                    users={users}
                />
            </div>}
            {openDialog &&
                <div>
                    <DialogBox
                        action={action}
                        actionScript={action === "delete" ? actionDelete : action === "error" || action === "success" ? actionCloseDialogBox : ""}
                        questionDialog={msgDialog}
                        optionsDialog={optionsDialog}
                        isopen={true}
                    />
                </div>
            }
        </div>
    )
}

export default MngEnrollments