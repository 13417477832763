import styles from './UserInfosUpdate.module.css'
import { useState, useEffect } from 'react';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useLanguageValue } from '../context/LanguageContext';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";

import DialogBox from './DialogBox';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const UserInfosUpdate = ({ userId, isActive }) => {
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [error, setError] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState("Informações atualizadas.");
    const [loading, setLoading] = useState(true);
    const [userPicture, setUserPicture] = useState(null)
    const [filePicture, setFilePicture] = useState(null)
    const [urlUserImage, setUrlUserImage] = useState(null)

    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');


    const { t } = useTranslation(['myaccount']);
    const { preferenceLanguage } = useLanguageValue();

    const { updateDocument, responseUpdate } = useUpdateDocument("Users", userId)
    const { document: userInfo, loadingUser, errorUser } = useFetchDocument("Users", userId);

    const handleClosDialogBox = () => {
        setOpenDialog(false);
    }

    const handleUpdateUserPicture = async (file) => {

        const forcedFileName = 'thumbnail';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {
                const maxSize = 200 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 200Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                setUploadingFile(true);
                const storageRef = ref(storage, `users/${userId}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlUserImage(url);
                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            setUploadingFile(false);
            console.error('Erro geral:', error);
        }
    }

    const handleUpdateUserInfos = async () => {

        if (filePicture) {
            handleUpdateUserPicture(filePicture);
        } else {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {

        setError("");

        if (!userName) {
            setError(`Você deve indicar seu nome.`);
            return
        }

        const userInfos = {
            domain: userId,
            name: userName,
            company: company,
            job_title: jobTitle,
            user_active: true,
            url_user_image: urlUserImage,
        }
        const resDoc = updateDocument(userInfos);
        setOpenDialog(true)
    }

    const checkUser = () => {
        setUserName(userInfo.name);
        setEmail(userInfo.email);
        setCompany(userInfo.company);
        setJobTitle(userInfo.job_title);
        setUserPicture(userInfo.url_user_image);
    }

    useEffect(() => {
        if (uploadingFile) {
            setMsgDialog(t('myaccount:userinfos.atualizando usuário'));
            setOptionsDialog([]);
            setAction('processing');
            setOpenDialog(true);
        } else {
            setMsgDialog("");
            setOptionsDialog([]);
            setAction('');
            setOpenDialog(false);
        }
    }, [uploadingFile])

    useEffect(() => {
        if (urlUserImage) {
            setUploadingFile(false);
            handleSubmit();
        }
    }, [urlUserImage])

    useEffect(() => {
        userInfo && checkUser();
        userInfo && setLoading(loadingUser);
    }, [userInfo])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div >
            {loading
                ? <div class="d-flex justify-content-center mt-5">
                    <div class="align-self-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{`Carregando...`}</span>
                        </div>
                    </div>
                </div>

                : <div className={`col-12`}>

                    <div className="mb-3 align-items-center col-auto">
                        <input type="file" className="d-none" id="userPicture" onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                setFilePicture(file);
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setUserPicture(reader.result);
                                };
                                reader.readAsDataURL(file);
                            } else {
                                setUserPicture(null);
                            }
                        }} />
                        <label htmlFor="userPicture" className="me-3">
                            {userPicture ? (
                                <img src={userPicture} alt="User Profile" className={`img-thumbnail rounded-circle ${styles.profilePicture}`} style={{ width: '150px', height: '150px', objectFit: 'cover', cursor: 'pointer' }} />
                            ) : urlUserImage ? (
                                <img src={urlUserImage} alt="User Profile" className={`img-thumbnail rounded-circle ${styles.profilePicture}`} style={{ width: '150px', height: '150px', objectFit: 'cover', cursor: 'pointer' }} />
                            ) : (
                                <div className={`d-flex justify-content-center align-items-center rounded-circle bg-secondary ${styles.profilePicture}`} style={{ width: '150px', height: '150px', cursor: 'pointer', position: 'relative' }}>
                                    <i className="bi bi-person-fill-add" style={{ fontSize: '50px', color: 'white' }}></i>
                                </div>
                            )}
                        </label>
                        <div className="flex-grow-1"></div>
                    </div>

                    <label className={`row me-2`}>
                        <div>{`Nome:`}</div>
                        <input
                            className={`${styles.form_field}`}
                            type="text"
                            name="displayName"
                            required
                            placeholder={t('myaccount:userinfos.Insira o seu nome')}
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)} />
                    </label>

                    <label className={`row me-2`}>
                        <div>E-mail:</div>
                        <input
                            className={`${styles.form_field}`}
                            type="email"
                            name="email"
                            placeholder={t('myaccount:userinfos.Insira a seu e-mail')}
                            value={email}
                            disabled />
                    </label>

                    <label className={`row me-2`}>
                        <div>{`Empresa:`}</div>
                        <input
                            className={`${styles.form_field}`}
                            type="text"
                            name="company"
                            placeholder={t('myaccount:userinfos.Insira a sua empresa')}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)} />
                    </label>

                    <label className={`row me-2`}>
                        <div>{`Cargo:`}</div>
                        <input
                            className={`${styles.form_field}`}
                            type="text"
                            name="jobTitle"
                            placeholder={t('myaccount:userinfos.Insira o seu cargo')}
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)} />
                    </label>

                    <div className={`row d-flex  me-2 justify-content-end`}>
                        <button className={`${styles.btn_style}`} disabled={!isActive} onClick={handleUpdateUserInfos}>{t('myaccount:userinfos.Atualizar')}</button>
                    </div>
                    {error && <p className="error">{error}</p>}

                    {openDialog && <DialogBox
                        isopen={true}
                        questionDialog={message}
                        optionsDialog={["Ok"]}
                        actionScript={handleClosDialogBox}
                        action={action}
                    />}
                </div>}
        </div>
    )
}

export default UserInfosUpdate