//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import styles from './CompanyPage.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//React Hooks
import { useState, useEffect } from 'react';
// Dev Hooks
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { useFetchDocument } from '../../hooks/useFetchDocument';
import { useUpdateDocument } from '../../hooks/useUpdateDocument';
import { useLanguageValue } from '../../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
// Dev Components
import LaunchCourse from '../../components/LaunchCourse';
import NavbarCompany from '../../components/NavbarCompany';
import { set } from 'date-fns';
import { collection, Timestamp } from 'firebase/firestore';
import { is } from 'date-fns/locale';
// Images
import scormAiLogo from '../../images/logo ScormAI Cinza.png';
import { use } from 'i18next';

const CompanyPage = ({ userSetLanguage, company, userId, userInfo, hideNavBar, hideMenuItem }) => {

    const userProfile = userInfo.profile;

    const navigate = useNavigate();
    var companyId = company.id;

    const { documents: userEnrollments, loadingUserEnrollments, errorUserEnrollments } = useFetchProjects("Enrollments", "", userId, "", companyId);
    const { documents: enrollments, loadinEnrollments, errorEnrollments } = useFetchProjects("Companys", "", companyId, "Enrollments");
    const { document: userInfos, loading: loadinguserInfos } = useFetchDocument("Companys", companyId, "Users", userId)
    const { updateDocument, responseUpdate } = useUpdateDocument("Companys", userId);

    const [companyName, setCompanyName] = useState(null);
    const [launchCourse, setLaunchCourse] = useState(false);
    const [domainName, setDomainName] = useState(null);
    const [idProject, setIdProject] = useState(null);
    const [projectSelected, setProjectSelected] = useState(null);
    const [domainId, setDomainId] = useState(null);
    const [enrollment, setEnrollment] = useState(null);
    const [enrollmentId, setEnrollmentId] = useState(null);
    const [enrollmentsList, setEnrollmentsList] = useState([]);
    const [enrollmentListConcluded, setEnrollmentListConcluded] = useState([]);
    const [firstAccess, setFirstAccess] = useState(null);
    const [lastAccess, setLastAccess] = useState(null);

    const { t } = useTranslation(['companypage']);
    const [activeTab, setActiveTab] = useState('home');
    const { preferenceLanguage } = useLanguageValue();

    const handleLaunchCourse = (course) => {

        hideNavBar(false);
        const domainProjectId = course.company_id;
        const projectId = course.course_id;
        const projectType = course.bookmark.project;
        const id = course.enrollment_id.trim();

        setEnrollment(course);
        setIdProject(projectId);
        setProjectSelected(projectType);
        setDomainId(domainProjectId);
        setLaunchCourse(true);
        setEnrollmentId(id);
    };

    const returnMyProjects = (firstAccess, lastAccess, totalTime, bookmark, status, conclusionDate, score, courseConcluded, concluing) => {

        if (!courseConcluded) {
            const projectInfos = {
                actionDoc: "update",
                domain: companyId,
                company_id: companyId,
                id: enrollmentId,
                bookmark: bookmark,
                conclusion_date: conclusionDate,
                last_access: lastAccess,
                status: status,
                total_time: totalTime,
                score: score,
                concluded: concluing,
                userId: userId,
                collection2: "Enrollments",
            }

            updateProject(projectInfos);
        }

        const userInfos = {
            actionDoc: "update",
            domain: companyId,
            company_id: companyId,
            id: userId,
            last_access: lastAccess,
            collection2: "",
        }
        updateProject(userInfos);

        setLaunchCourse(false);
        hideNavBar(true);
    };

    const updateProject = async (projectInfos) => {
        const res = await updateDocument(projectInfos);
    }

    useEffect(() => {
        if (userEnrollments && userEnrollments.length > 0 && enrollments) {
            setEnrollmentsList(userEnrollments);
            const filteredEnrollments = userEnrollments.filter(userEnrollment => {
                const enrollment = enrollments.find(enrollment => enrollment.id === userEnrollment.enrollment_id);
                return enrollment && !enrollment.deleted && enrollment.active && !['completed', 'passed', 'failed'].includes(userEnrollment.status);
            });

            setEnrollmentsList(filteredEnrollments);

            const concludedEnrollments = userEnrollments.filter(userEnrollment => {
                const enrollment = enrollments.find(enrollment => enrollment.id === userEnrollment.enrollment_id);
                return enrollment && !enrollment.deleted && enrollment.active && ['completed', 'passed', 'failed'].includes(userEnrollment.status);
            });

            setEnrollmentListConcluded(concludedEnrollments);
        }
    }, [userEnrollments, enrollments]);

    useEffect(() => {
        if (userInfos && userInfos.first_access) {
            setFirstAccess(userInfos.first_access)
        } else if (userInfos && !userInfos.first_access) {
            const userInfos = {
                actionDoc: "update",
                domain: companyId,
                company_id: companyId,
                id: userId,
                first_access: Timestamp.now(),
                collection2: "",
            }
            updateProject(userInfos)
        }

        if (userInfos && userInfos.last_access) {
            const lastAccessDate = userInfos.last_access.toDate();
            const today = new Date();
            if (lastAccessDate.getFullYear() < today.getFullYear() ||
                (lastAccessDate.getFullYear() === today.getFullYear() && lastAccessDate.getMonth() < today.getMonth()) ||
                (lastAccessDate.getFullYear() === today.getFullYear() && lastAccessDate.getMonth() === today.getMonth() && lastAccessDate.getDate() < today.getDate())) {
                const userInfos = {
                    actionDoc: "update",
                    domain: companyId,
                    company_id: companyId,
                    id: userId,
                    last_access: Timestamp.now(),
                    collection2: "",
                }
                updateProject(userInfos);
            }
        }

    }, [userInfos])

    useEffect(() => {
        if (company) {
            setCompanyName(company.name);
            setDomainName(company.name.replace(/\s+/g, ''));
        }
    }, [company]);

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        hideMenuItem("mycourses")
    }, [])

    return (
        <div className={` ${styles.body_project} `} style={userProfile != "companyadmin" ? { backgroundColor: company.color } : null}>

            {!launchCourse &&
                <div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'home' ? styles.abaItems : styles.abaItemsInactive}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected={activeTab === 'home'} onClick={() => setActiveTab('home')}>
                                {t('companypage:home.Meus cursos')}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'profile' ? styles.abaItems : styles.abaItemsInactive}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected={activeTab === 'profile'} onClick={() => setActiveTab('profile')}>
                                {t('companypage:home.Concluidos')}
                            </button>
                        </li>

                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade show ${activeTab === 'home' ? 'active' : ''} ${styles.backAba}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                            {enrollmentsList && enrollmentsList.length > 0
                                ? <div className="d-flex overflow-auto ms-4">
                                    {enrollmentsList && enrollmentsList
                                        .map((enrollment, index) => {
                                            const isExpired = enrollment.validate_date && new Date(enrollment.validate_date.toDate()) < new Date();
                                            return (
                                                <div key={index} className={`card m-2 shadow-sm mt-4 ${!isExpired ? styles.card_custom : null}`} style={{ minWidth: '250px', maxWidth: '250px', cursor: isExpired ? 'not-allowed' : 'pointer' }} onClick={() => !isExpired && handleLaunchCourse(enrollment)}>
                                                    <div style={{ height: '150px', overflow: 'hidden', display: 'flex', alignItems: enrollment.course_image ? 'start' : 'center', justifyContent: 'center' }}>
                                                        {enrollment.course_image
                                                            ? <img src={enrollment.course_image} className="card-img-top" alt={enrollment.course_name} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                                                            : <img src={scormAiLogo} className={`card-img-top ${styles.logoScormAI}`} alt="ScormAI Logo" />
                                                        }
                                                    </div>
                                                    <div className="card-body d-flex flex-column">
                                                        <h5 className={`card-title ${styles.cardTitle}`}>{enrollment.course_name}</h5>
                                                        <p className={`card-text ${styles.cardDesc}`}>{enrollment.course_description}</p>
                                                    </div>

                                                    <div>
                                                        {isExpired ? (
                                                            <p className={styles.cardStatusExpired}>{'Expired on ' + new Date(enrollment.validate_date.toDate()).toLocaleDateString()}</p>
                                                        ) : (
                                                            <p className={`${enrollment.status === "started" || enrollment.status === "passed" ? styles.cardStatusStarted : styles.cardStatusNotStarted}`}>{t(`companypage:home.${enrollment.status}`, {
                                                                startedDate: new Date(enrollment.first_access.toDate()).toLocaleDateString(),
                                                                score: (enrollment.score || 0).toFixed(0),
                                                                interpolation: { prefix: '${', suffix: '}' }
                                                            })}</p>
                                                        )}
                                                    </div>

                                                    <div>
                                                        {enrollment.validate_date
                                                            ? (
                                                                <p className={`${styles.untilDate}`}>{t(`companypage:home.valido ate`, {
                                                                    validateDate: new Date(enrollment.validate_date.toDate()).toLocaleDateString(),
                                                                    interpolation: { prefix: '${', suffix: '}' }
                                                                })}</p>
                                                            )
                                                            : <p className={`${styles.untilDate}`}>
                                                                No time limit
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                : <div className='d-flex overflow-auto ms-2'>
                                    <p className="ms-4 mt-4">{t('companypage:home.sem matriculas')} </p>
                                </div>
                            }
                        </div>

                        <div className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''} ${styles.backAba}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {enrollmentListConcluded && enrollmentListConcluded.length > 0
                                ? <div className="d-flex overflow-auto ms-4">
                                    {enrollmentListConcluded && enrollmentListConcluded
                                        .map((enrollment, index) => {
                                            const enrollmentData = enrollments.find(enrollmentData => enrollmentData.id === enrollment.enrollment_id);
                                            if ((enrollmentData.access_upon_completion === true && enrollment.access_upon_completion === true) ||
                                                (enrollmentData.access_upon_completion === false && enrollment.access_upon_completion === true)
                                            ) {
                                                return (
                                                    <div key={index} className={`card m-2 shadow-sm mt-4 ${styles.card_custom}`} style={{ minWidth: '250px', maxWidth: '250px', cursor: 'pointer' }} onClick={() => handleLaunchCourse(enrollment)}>
                                                        <div style={{ height: '150px', overflow: 'hidden', display: 'flex', alignItems: enrollment.course_image ? 'start' : 'center', justifyContent: 'center' }}>
                                                            {enrollment.course_image
                                                                ? <img src={enrollment.course_image} className="card-img-top" alt={enrollment.course_name} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                                                                : <img src={scormAiLogo} className={`card-img-top ${styles.logoScormAI}`} alt="ScormAI Logo" />
                                                            }
                                                        </div>
                                                        <div className="card-body d-flex flex-column">
                                                            <h5 className={`card-title ${styles.cardTitle}`}>{enrollment.course_name}</h5>
                                                            <p className={`card-text ${styles.cardDesc}`}>{enrollment.course_description}</p>
                                                        </div>
                                                        <div>
                                                            <p className={`${enrollment.status === "started" || enrollment.status === "passed" || enrollment.status === "completed" ? styles.cardStatusStarted : styles.cardStatusNotStarted}`}>{t(`companypage:home.${enrollment.status}`, {
                                                                startedDate: new Date(enrollment.first_access.toDate()).toLocaleDateString(),
                                                                score: (enrollment.score || 0).toFixed(0),
                                                                interpolation: { prefix: '${', suffix: '}' }
                                                            })}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                                : <div className='d-flex overflow-auto ms-2'>
                                    <p className="ms-4 mt-4">{t('companypage:home.sem curso concluido')}</p>
                                </div>}
                        </div>

                    </div>
                </div>
            }
            {(launchCourse && idProject) &&
                <div>
                    <LaunchCourse
                        id={idProject}

                        domainId={domainId}
                        returnHome={returnMyProjects}
                        reviewProject={true}
                        enrollment={enrollment}
                        userId={userId}
                    />
                </div>
            }
        </div>
    );
};

export default CompanyPage;