import React, { useRef, useEffect, useState } from 'react';
import styles from './Footer.module.css';
import { useAuthValue } from '../context/AuthContext';
import { useLanguageValue } from '../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

/* --- IMAGENS --- */
import image1 from '../images/logoFooter.png';
import image2 from '../images/instagram.png';
import image3 from '../images/x.png';
import image4 from '../images/linkedin.png';
import image5 from '../images/youtube.png';
import image6 from '../images/thread.png';
import scormAiLogo from '../images/logo-ScormAI.png';
import logoFB from '../images/logofb.png';
import logoGPT from '../images/logogpt.png';
import logoPaddle from '../images/logopaddle.png';

const Footer = ({ userSetLanguage }) => {
  const { user } = useAuthValue();
  const { t } = useTranslation(['footer']);
  const { preferenceLanguage } = useLanguageValue();
  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

  const fadeInElements = useRef([]);

  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/home")
  }

  const goContact = () => {
    navigate("/contact")
  }

  const goFaq = () => {
    navigate("/faq")
  }

  const goPrices = () => {
    navigate("/catalog")
  }

  const goFeatures = () => {
    navigate("/features")
  }

  const goGdpr = () => {
    navigate("/gdpr")
  }

  const goPrivacy = () => {
    navigate("/privacy")
  }

  const goTerms = () => {
    navigate("/terms")
  }

  const goDpa = () => {
    navigate("/dpa")
  }

  const goHelp = () => {
    navigate("/help")
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    console.log("changeLanguage: " + language)
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <footer className={`${styles.footer} text-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
      {<div className={`row d-flex justify-content-center`}>
        <div className={`col-4 text-center ${styles.text_md_left}`}>
          <a href="#" onClick={() => { goHome() }} className="href">
            <img className={`img-fluid ${styles.img_fluid}`} src={scormAiLogo} alt="Logo" />
          </a>
          <figcaption>{t(`footer:items.scormai`)}</figcaption>
        </div>

        <div className="col-4">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-auto fw-bold">
              Powered by:
            </div>
            <div className="col-auto">
              <img className={`img-fluid ${styles.img_fluid_logo}`} src={logoFB} alt="Logo" />
            </div>
            <div className="col-auto">
              <img className={`img-fluid ${styles.img_fluid_logo}`} src={logoGPT} alt="Logo" />
            </div>
            <div className="col-auto">
              <img className={`img-fluid ${styles.img_fluid_paddle}`} src={logoPaddle} alt="Logo" />
            </div>

          </div>
        </div>
        <div className={`col-4  text-center ${styles['text_md_right']}`}>
          <a href="https://www.instagram.com/quizai_biz/ " target="_blank">
            <img src={image2} alt="Instagram" />
          </a>
          <a href="https://twitter.com/QuizAI_biz" target="_blank">
            <img src={image3} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/quizai-biz " target="_blank">
            <img src={image4} alt="Linkedin" />
          </a>
          <a href="https://www.youtube.com/@QuizAI-biz" target="_blank">
            <img src={image5} alt="YouTube" />
          </a>
          <a href="https://www.threads.net/@quizai_biz" target="_blank">
            <img src={image6} alt="Thread" />
          </a>
        </div>
        <div className={`col-12`}>
          <hr />
        </div>
        <div className={`col-12 ${styles.col_md_4} p-0 text-center ${styles.text_md_left}`}>
          <ul className={`${styles.ul_}`}>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goFeatures() }}>{t(`footer:items.Funcionalidades`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goFaq() }}>{t(`footer:items.FAQ`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goPrices() }} >{t(`footer:items.Planos`)}</a></li>
            {/* <li><a href="#" onClick={() => { goContact() }}>{t(`footer:items.Contato`)}</a></li> */}
          </ul>
        </div>
        <div className={`col-12 ${styles.col_md_4}  p-0 text-center ${styles.text_md_left}`}>
          <ul className={`${styles.ul_}`}>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goHelp() }}>{t(`footer:items.Tutoriais`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#">{t(`footer:items.Changelog`)}</a></li>
          </ul>
        </div>
        <div className={`col-12 ${styles.col_md_4}  p-0 text-center ${styles.text_md_left}`}>
          <ul className={`${styles.ul_}`}>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goTerms() }}>{t(`footer:items.Termos`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goPrivacy() }}>{t(`footer:items.Privacidade`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goGdpr() }}>{t(`footer:items.GDPR`)}</a></li>
            <li><a className={`${styles.textColor}`} href="#" onClick={() => { goDpa() }}>{t(`footer:items.Contrato de processamento de dados`)}</a></li>
          </ul>
        </div>
        <div className={`col-12`}>
          <hr />
          <h5>{t(`footer:items.QuizAI. Todos os direitos reservados`)}</h5>
        </div>
      </div>}
    </footer>
  );
};

export default Footer;
