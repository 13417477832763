//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './CourseReport.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
//Dev Components
import DialogBox from '../components/DialogBox';
import UserInfoEnrollment from './UserInfoEnrollment';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';
//Firebase
import { Timestamp, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";

const CourseReport = ({ returnNormalState, course, users }) => {

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();

    const [companyId, setCompanyId] = useState(course.company_id);
    const [enrolledUsers, setEnrolledUsers] = useState(course.users_enrolled || []);
    const [courseName, setCourseName] = useState(course.course_name);
    const [courseDescription, setCourseDescription] = useState(course.course_description);
    const [courseImage, setCourseImage] = useState(null);
    const [urlCourseImg, setUrlCourseImg] = useState(course.course_image);
    const [passingScore, setPassingScore] = useState(course.passing_score);
    const [validateDate, setValidateDate] = useState(course.validate_date);
    const [enrollmentInfos, setEnrollmentInfos] = useState([]);
    const [enrollmentId, setEnrollmentId] = useState(course.id);
    const [courseId, setCourseId] = useState(course.course_id);
    const [projectType, setProjectType] = useState(course.project_type);
    const [activeEnroll, setActiveEnroll] = useState(course.active);
    const [deletedEnroll, setDeletedEnroll] = useState(course.deleted);
    const [changeActive, setChangeActive] = useState(course.active);

    const [statusFilter, setStatusFilter] = useState('');
    const [nameFilter, setNameFilter] = useState("");
    const [startDate, setStartDate] = useState('');

    const [sortOrder, setSortOrder] = useState('asc');

    const fetchUserEnrollments = async () => {
        const userEnrollments = await Promise.all(enrolledUsers.map(async (userId) => {
            const userEnrollmentRef = doc(db, `Companys/${companyId}/Users/${userId}/Enrollments/${course.id}`);
            const userEnrollmentSnap = await getDoc(userEnrollmentRef);
            if (userEnrollmentSnap.exists()) {
                return { userId, enrollment: userEnrollmentSnap.data() };
            } else {
                return { userId, enrollment: null };
            }
        }));
        setEnrollmentInfos(userEnrollments);
    };

    const handleCloseWindow = () => {
        returnNormalState()
    }

    useEffect(() => {
        if (enrolledUsers && enrolledUsers.length > 0) {
            fetchUserEnrollments();
        }

    }, [enrolledUsers]);

    useEffect(() => {
        console.log("course ", course)
    }, [course])

    const downloadCSV = () => {

        const headers = [
            "Name",
            "Enrollment Date",
            "Last Access",
            "Status",
            "Score",
            "Total Time",
            "Enrollment"
        ];

        const rows = users.filter(user =>
            (statusFilter === '' || statusFilter === 'all' || (statusFilter === 'active' && user.user_active) || (statusFilter === 'not active' && !user.user_active)) &&
            (nameFilter === "" || user.name.toLowerCase().includes(nameFilter)) &&
            (!startDate || (user.last_access && new Date(user.last_access.seconds * 1000) >= new Date(startDate)))
        ).filter(user =>
            enrolledUsers.flat().includes(user.id) &&
            (statusFilter === '' || statusFilter === 'all' ||
                enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status === statusFilter) &&
            (nameFilter === "" || user.name.toLowerCase().includes(nameFilter))
        ).filter(user => !user.deleted
        ).map(user => [
            user.name,
            enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.enrollment_date?.toDate().toLocaleDateString() || 'N/A',
            enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.last_access?.toDate().toLocaleDateString() || 'N/A',
            enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status || 'N/A',
            ['passed', 'failed'].includes(enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status) ? enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.score || 'N/A' : 'N/A',
            enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time ? `${Math.floor(enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time / 60)} min ${enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time % 60} sec` : 'N/A',
            enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.active ? 'Active' : 'Inactive'
        ]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + headers.join(",") + "\n"
            + rows.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${courseName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log("Downloading CSV...");
    };

    return (
        <div>
            <div className="row ">
                <div className={`${styles.iconButton} d-flex align-items-end justify-content-end`}>
                    <i className={`bi bi-box-arrow-left me-4 ${styles.icons}`} onClick={() => handleCloseWindow()}></i>
                </div>
            </div>
            <div className={`ms-3 mb-3 ${styles.divTitle}`}>
                {courseName}
            </div>
            {enrolledUsers.length > 0
                ? <div className={`ms-3 ${styles.divUsers}`}>
                    <table className="table table-striped">
                        <thead className={`${styles.stickyHeader}`}>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">
                                    <input
                                        type="text"
                                        value={nameFilter}
                                        onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
                                        placeholder="Name"
                                        className={`${styles.nameFilterInput}`}
                                        style={{ border: '1px solid lightgray', fontWeight: 'bold' }}
                                    />
                                </th>
                                <th scope="col" onClick={() => setSortOrder({ field: 'validate_date', order: sortOrder.order === 'asc' ? 'desc' : 'asc' })} style={{ cursor: 'pointer' }}>
                                    Valid Until {sortOrder.field === 'validate_date' ? (sortOrder.order === 'asc' ? '↑' : '↓') : '↕'}
                                </th>
                                <th scope="col" onClick={() => setSortOrder({ field: 'last_access', order: sortOrder.order === 'asc' ? 'desc' : 'asc' })} style={{ cursor: 'pointer' }}>
                                    Last Access {sortOrder.field === 'last_access' ? (sortOrder.order === 'asc' ? '↑' : '↓') : '↕'}
                                </th>
                                <th scope="col">
                                    <select
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        className={`${styles.statusFilterDropdown}`}
                                    >
                                        <option value="">Status</option>
                                        <option value="all">All</option>
                                        <option value="started">Started</option>
                                        <option value="not started">Not Started</option>
                                        <option value="passed">Passed</option>
                                        <option value="failed">Failed</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                </th>
                                <th scope="col" onClick={() => setSortOrder({ field: 'score', order: sortOrder.order === 'asc' ? 'desc' : 'asc' })} style={{ cursor: 'pointer' }}>
                                    Score {sortOrder.field === 'score' ? (sortOrder.order === 'asc' ? '↑' : '↓') : '↕'}
                                </th>
                                <th scope="col" onClick={() => setSortOrder({ field: 'total_time', order: sortOrder.order === 'asc' ? 'desc' : 'asc' })} style={{ cursor: 'pointer' }}>
                                    Total Time {sortOrder.field === 'total_time' ? (sortOrder.order === 'asc' ? '↑' : '↓') : '↕'}
                                </th>
                                <th scope="col">Enrollment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.filter(user =>
                                (statusFilter === '' || statusFilter === 'all' ||
                                    enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status === statusFilter) &&
                                (nameFilter === "" || user.name.toLowerCase().includes(nameFilter)) &&
                                (!startDate || (user.last_access && new Date(user.last_access.seconds * 1000) >= new Date(startDate)))
                            ).filter(user =>
                                enrolledUsers.flat().includes(user.id) &&
                                (statusFilter === '' || statusFilter === 'all' ||
                                    enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status === statusFilter) &&
                                (nameFilter === "" || user.name.toLowerCase().includes(nameFilter))
                            ).filter(user => !user.deleted
                            ).sort((a, b) => {
                                const getValue = (user, key) => {
                                    const enrollment = enrollmentInfos.find(info => info.userId === user.id)?.enrollment;
                                    if (key === 'last_access') return enrollment?.last_access?.toDate() || new Date(0);
                                    if (key === 'validate_date') return enrollment?.validate_date?.toDate() || new Date(0);
                                    if (key === 'score') return enrollment?.score || 0;
                                    if (key === 'total_time') return enrollment?.total_time || 0;
                                    return 0;
                                };
                                const valueA = getValue(a, sortOrder.field);
                                const valueB = getValue(b, sortOrder.field);
                                return sortOrder.order === 'asc' ? valueA - valueB : valueB - valueA;
                            }).map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        {user.url_user_image ? (
                                            <img src={user.url_user_image} alt="User" className={`${styles.thumbnailCircular}`} />
                                        ) : (
                                            <i className={`bi bi-person-circle ${styles.iconPerson}`} ></i>
                                        )}
                                    </td>
                                    <td className={`${styles.formatTexts}`}>{user.name}</td>
                                    <td className={`${styles.formatTexts}`}>{enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.validate_date?.toDate().toLocaleDateString() || 'N/A'}</td>
                                    <td className={`${styles.formatTexts}`}>{enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.last_access?.toDate().toLocaleDateString() || 'N/A'}</td>
                                    <td className={`${styles.formatTexts}`}>{enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status || 'N/A'}</td>
                                    <td className={`${styles.formatTexts}`}>{['passed', 'failed'].includes(enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.status) ? enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.score || 'N/A' : 'N/A'}</td>
                                    <td className={`${styles.formatTexts}`}>{enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time ? `${Math.floor(enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time / 60)} min ${enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.total_time % 60} sec` : 'N/A'}</td>
                                    <td className={`${styles.formatTexts}`}>{enrollmentInfos.find(info => info.userId === user.id)?.enrollment?.active ? 'Active' : 'Inactive'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : <div  className={`ms-3 ${styles.formatTexts}`}>
                    No enrollments found in this course.
                </div>
            }
            {enrolledUsers.length > 0 &&
                <button className={`ms-4 mt-3 ${styles.csvButton}`} onClick={downloadCSV}>Download CSV</button>}
        </div>
    );
}

export default CourseReport;