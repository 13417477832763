import React, { useRef, useEffect, useState } from 'react';
import styles from './Footer.module.css';
import { useAuthValue } from '../context/AuthContext';
import { useLanguageValue } from '../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

/* --- IMAGENS --- */
import image1 from '../images/logoFooter.png';
import image2 from '../images/instagram.png';
import image3 from '../images/x.png';
import image4 from '../images/linkedin.png';
import image5 from '../images/youtube.png';
import image6 from '../images/thread.png';
import scormAiLogo from '../images/logo-ScormAI.png';
import logoFB from '../images/logofb.png';
import logoGPT from '../images/logogpt.png';
import logoPaddle from '../images/logopaddle.png';


const FooterCompany = ({ userSetLanguage }) => {
    const { user } = useAuthValue();
    const { t } = useTranslation(['footer']);

    const { preferenceLanguage } = useLanguageValue();
    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

    const fadeInElements = useRef([]);

    return (
        <footer className={`${styles.footer}`}>
            <div className={`row d-flex justify-content-center`}>
                <div className={`col-4 text-center  ${styles.text_md_left}`}>
                    <img className={`img-fluid ${styles.img_fluid}`} src={scormAiLogo} alt="Logo" />

                </div>
                <div className="col-4">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-auto fw-bold">
                            Powered by:
                        </div>
                        <div className="col-auto">
                            <img className={`img-fluid ${styles.img_fluid_logo}`} src={logoFB} alt="Logo" />
                        </div>
                        <div className="col-auto">

                            <img className={`img-fluid ${styles.img_fluid_logo}`} src={logoGPT} alt="Logo" />
                        </div>
                        <div className="col-auto">

                            <img className={`img-fluid ${styles.img_fluid_paddle}`} src={logoPaddle} alt="Logo" />
                        </div>

                    </div>
                </div>
                <div className={`col-4  text-center ${styles['text_md_right']}`}>
                    <a href="https://www.instagram.com/quizai_biz/ " target="_blank">
                        <img src={image2} alt="Instagram" />
                    </a>
                    <a href="https://twitter.com/QuizAI_biz" target="_blank">
                        <img src={image3} alt="Twitter" />
                    </a>
                    <a href="https://www.linkedin.com/company/quizai-biz " target="_blank">
                        <img src={image4} alt="Linkedin" />
                    </a>
                    <a href="https://www.youtube.com/@QuizAI-biz" target="_blank">
                        <img src={image5} alt="YouTube" />
                    </a>
                    <a href="https://www.threads.net/@quizai_biz" target="_blank">
                        <img src={image6} alt="Thread" />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default FooterCompany