//APP VERSION 3.0

// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { db } from './firebase/config';
import { doc, onSnapshot, getDoc, getDocs, collection } from "firebase/firestore";

// ReactRouter
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Estilos
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Context
import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import './i18n';

// Hooks
import { useState, useEffect, Suspense } from 'react';
import { useAuthentication } from './hooks/useAuthentications';
import { useFetchProjects } from './hooks/useFetchProjects';
import { useFetchDocument } from './hooks/useFetchDocument'

// Pages
import Home from './pages/home/HomeNova';
import MyProjects from './pages/home/MyProjects.js';
import About from './pages/about/About';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import LoginStudent from './pages/login/LoginStudent.js';
import Catalog from './pages/catalog/Catalog';
import MyAccount from './pages/perfil/MyAccount';
import CreateAssessment from './pages/createassessment/CreateAssessment';
import UpdateAssessment from './pages/assessment/UpdateAssessment';
import Faq from './pages/faq/Faq';
import Plans from './pages/plans/Plans.js';
import Features from './pages/features/Features';
import Homepage from './pages/homepage/Homepage';
import Help from './pages/help/Help';
import Gdpr from './pages/gdpr/Gdpr';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import DataProcessingAgreement from './pages/dpa/DataProcessingAgreement';
import Contact from './pages/contact/Contact';
import CompanyPage from './pages/companypage/CompanyPage.js';
import LearnHub from './pages/learnhub/LearnHub.js';

// Components
import Navbar from './components/Navbar';
import NavbarCompany from './components/NavbarCompany.js';
import Footer from './components/Footer';
import FooterCompany from './components/FooterCompany.js';

// i18next
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { set } from 'date-fns';

function App() {
  const [user, setUser] = useState(undefined);
  const [userId, setUserId] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [authorShip, setAuthorShip] = useState(false);
  const [userDomain, setUserDomain] = useState("");
  const [preferenceLanguage, setPreferenceLanguage] = useState("Português");
  const [userSetLanguage, setUserSetLanguage] = useState("");
  const [hideItem, setHideItem] = useState("");
  const [userCompany, setUserCompany] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [navScormAI, setNavScormAI] = useState(true);
  const [initialPage, setInitialPage] = useState("home");
  const [userDocInfo, setUserDocInfo] = useState([]);
  const [companyDocInfo, setCompanyDocInfo] = useState([]);
  const [showNavBar, setShowNavBar] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  const { auth } = useAuthentication();
  const { documents: companys, loadingCompanys, errorCompanys } = useFetchProjects("Companys");
  const { document: userInfo, loadingDocDomain, errorDocDomain } = useFetchDocument("Users", userId)

  const hideMenuItem = (item) => {
    console.log("item", item);
    setHideItem(item);
  };

  const changeSiteLanguage = (lang) => {
    if (lang === "Português") {
      setUserSetLanguage("pt");
    } else if (lang === "Espanhol") {
      setUserSetLanguage("es");
    } else {
      setUserSetLanguage("en");
    }
  };

  const changeNavBar = (opt) => {
    setUserProfile("")
    setNavScormAI(opt);
  }

  const hideNavBar = (opt) => {
    setShowNavBar(opt);
  }

  const hideFooter = (opt) => {
    setShowFooter(opt);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {

      setUser(user);
      if (user) {
        const fetchUserDocument = async (userId) => {
          const docRefUser = doc(db, "Users", userId);
          const docSnapUser = await getDoc(docRefUser);
          if (docSnapUser.exists()) {
            setUserDocInfo(docSnapUser.data());
          } else {
            console.log("No such document!");
          }

          const querySnapshot = await getDocs(collection(db, "Companys"));
          const companies = querySnapshot.docs.map(doc => doc.data());

          if (companies) {
            setCompanyDocInfo(companies);
          } else {
            console.log("No such document!");
          }
        };

        fetchUserDocument(user.uid);
        setUserId(auth.currentUser.uid);

      }
    });
  }, [auth]);

  useEffect(() => {
    if (userId) {
      const docRef = doc(db, "Users", userId);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setPreferenceLanguage(docSnap.data().language);
        }
      });
      return () => unsubscribe();
    }
  }, [userId]);

  useEffect(() => {

    if (userDocInfo && companyDocInfo) {
      setUserDomain(userDocInfo.domain);
      const companyId = userDocInfo.company_id;
      const companyInfo = companyDocInfo.find(c => c.id === companyId);
      if (companyInfo) {
        setUserCompany(companyInfo);
        setCompanyName(companyInfo.name.replace(/\s+/g, ''));
        setUserDomain(companyInfo.company_id);
      }
    }
  }, [userDocInfo, companyDocInfo])

  useEffect(() => {
    if (userDocInfo) {
      console.log("userDocInfo", userDocInfo);
      setUserDomain(userDocInfo.domain);
    }
  }, [userDocInfo])

  useEffect(() => {
    if (companyName) {
      setInitialPage(companyName);
    }
  }, [companyName])

  useEffect(() => {
    if (userProfile === "student") {
      setNavScormAI(false);
    } else {
      setNavScormAI(true);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userInfo) {
      setUserDocInfo(userInfo);
      setUserProfile(userInfo.profile);
      setAuthorShip(userInfo.user_author);
    }
  }, [userInfo])

  useEffect(() => {
    if (companys) {
      setCompanyDocInfo(companys);
    }
  }, [companys])


  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="App p-0 m-0">
          <AuthProvider value={{ user }}>
            <LanguageProvider value={{ preferenceLanguage }}>
              {userDocInfo &&
                <BrowserRouter key={user ? user.uid : 'loggedOut'}>
                  {navScormAI
                    ? <Navbar
                      changeSiteLanguage={changeSiteLanguage}
                      changeNavBar={changeNavBar}
                      hideItem={hideItem}
                      userInfo={userDocInfo} />
                    : showNavBar &&
                    <NavbarCompany
                      changeSiteLanguage={changeSiteLanguage}
                      changeNavBar={changeNavBar}
                      company={userCompany}
                      userInfo={userDocInfo}
                      hideItem={hideItem}
                      authorShip={authorShip}
                    />
                  }

                  <div className="container m-0 p-0">
                    <Routes>
                      {userProfile === "student" &&
                        <Route path={`/${companyName}`} element={
                          user
                            ? <CompanyPage userSetLanguage={userSetLanguage} company={userCompany} userId={userId} userInfo={userInfo} hideNavBar={hideNavBar} hideMenuItem={hideMenuItem} />
                            : <Navigate to="/hub" />} />}
                      <Route path='/' element={
                        user
                          ? (userProfile !== "student" || !userProfile
                            ? <Home userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={user.uid} />
                            : <Navigate to={`/${initialPage}`} />)
                          : <Homepage userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={userId} />
                      } />
                      <Route path='/about' element={<About />} />
                      <Route path='/login' element={
                        !user
                          ? <Login userSetLanguage={userSetLanguage} />
                          : (userProfile !== "student" || !userProfile
                            ? <Home userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={user.uid} />
                            : <Navigate to={`/${initialPage}`} />
                          )
                      } />
                      <Route path='/hub' element={
                        !user
                          ? <LoginStudent userSetLanguage={userSetLanguage} changeNavBar={changeNavBar} hideFooter={hideFooter} />
                          : userCompany && userCompany.id && <CompanyPage userSetLanguage={userSetLanguage} company={userCompany} userId={user.uid} userInfo={userInfo} hideNavBar={hideNavBar} hideMenuItem={hideMenuItem} />
                      } />
                      <Route path='/home' element={
                        !user
                          ? <Homepage userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={userId} />
                          : userProfile === "student"
                            ? <Navigate to={`/${initialPage}`} />
                            : <Home userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={user.uid} />
                      } />
                      <Route path='/register' element={
                        !user
                          ? <Register userSetLanguage={userSetLanguage} />
                          : <Navigate to="/" />} />
                      <Route path='/assessment/create' element={
                        user
                          ? <CreateAssessment />
                          : <Navigate to="/" />} />
                      <Route path='/assessment' element={
                        user
                          ? <UpdateAssessment />
                          : <Navigate to="/" />} />
                      <Route path='/seuperfil' element={
                        user
                          ? <MyAccount hideMenuItem={hideMenuItem} />
                          : <Navigate to="/" />} />
                      <Route path='/homepage' element={<Homepage userSetLanguage={userSetLanguage} />} />
                      {authorShip != false && <Route path='/myprojects' element={
                        user
                          ? <MyProjects userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userdomain={userDomain ? userDomain : user.uid} />
                          : <Navigate to="/" />} />}
                      <Route path='/features' element={<Features userSetLanguage={userSetLanguage} />} />
                      <Route path='/faq' element={<Faq userSetLanguage={userSetLanguage} />} />
                      <Route path='/catalog' element={<Plans userSetLanguage={userSetLanguage} />} />
                      <Route path='/help' element={<Help userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} />} />
                      <Route path='/gdpr' element={<Gdpr userSetLanguage={userSetLanguage} />} />
                      <Route path='/privacy' element={<Privacy userSetLanguage={userSetLanguage} />} />
                      <Route path='/terms' element={<Terms userSetLanguage={userSetLanguage} />} />
                      <Route path='/dpa' element={<DataProcessingAgreement userSetLanguage={userSetLanguage} />} />
                      <Route path='/newproject' element={
                        user
                          ? <Home userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={userDomain} userInfo={userDocInfo} authorShip={authorShip} />
                          : <Navigate to="/" />} />
                      <Route path='/learnhub' element={
                        user
                          ? <LearnHub userSetLanguage={userSetLanguage} userInfo={userDocInfo} hideMenuItem={hideMenuItem} />
                          : <Navigate to="/" />} />
                      <Route path='/companypage' element={
                        user
                          ? <CompanyPage userSetLanguage={userSetLanguage} company={userCompany} userId={userId} userInfo={userInfo} hideNavBar={hideNavBar} hideMenuItem={hideMenuItem} />
                          : <Navigate to="/" />} />
                    </Routes>
                  </div>
                  {navScormAI
                    ? <Footer userSetLanguage={userSetLanguage} />
                    : <FooterCompany userSetLanguage={userSetLanguage} />
                  }
                </BrowserRouter>}
            </LanguageProvider>
          </AuthProvider>
        </div>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;