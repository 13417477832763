//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './VideoInteractionObject.module.css'
//React Hooks
import { useEffect, useState } from 'react';
import { set, setMilliseconds } from 'date-fns';
//Dev Components
import DialogBox from './DialogBox';
import AssessmentCase from './AssessmentCase';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import defaultStyles from '../templates/default.module.css'

const VideoInteractionObject = ({ changeVideoState, currentInteractionTime, projectVideoInteraction, changeVideoInteraction, editProject, language }) => {

    console.log("language: ", language)

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const [interactionType, setInteractionType] = useState("")
    const [activeInteraction, setActiveInteraction] = useState(true)
    const [contentText, setContentText] = useState("")
    const [questions, setQuestions] = useState([])
    const [qtyQuestions, setQtyQuestions] = useState(0)
    const [originalCorrectAnswers, setOriginalCorrectAnswers] = useState([])
    const [selectedAlternative, setSelectedAlternative] = useState();
    const [quizAnswers, setQuizAnswers] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [showButtonClose, setShowButtonClose] = useState(false);
    const [showButtonNext, setShowButtonNext] = useState(false);
    const [projectVideoInteractionUpdated, setProjectVideoInteractionUpdated] = useState([]);
    const [interactionIndex, setInteractionIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');
    const [deleteInteraction, setDeleteInteraction] = useState(false);
    const [videoCase, setVideoCase] = useState([]);
    const [stringsIndex, setStringsIndex] = useState(0);
    const [strings, setStrings] = useState([]);

    var correctanswers = 0;

    const stringTexts = [
        ["Para saber mais", "Resumo", "Case de aplicação", "Quiz", "Resultado", `Você acertou ${correctanswers} de ${qtyQuestions} questões:`, "Fechar", "Próximo", "Enviar"],
        ["To know more", "Summary", "Application case", "Quiz", "Result", `You got ${correctanswers} out of ${qtyQuestions} questions right:`, "Close", "Next", "Send"],
        ["Para saber más", "Resumen", "Caso de aplicación", "Quiz", "Resultado", `Acertaste ${correctanswers} de ${qtyQuestions} preguntas:`, "Cerrar", "Siguiente", "Enviar"],
        ["Pour en savoir plus", "Résumé", "Cas d'application", "Quiz", "Résultat", `Vous avez obtenu ${correctanswers} bonnes réponses sur ${qtyQuestions} questions:`, "Fermer", "Suivant", "Envoyer"],
        ["Per saperne di più", "Riepilogo", "Caso di applicazione", "Quiz", "Risultato", `Hai risposto correttamente a ${correctanswers} domande su ${qtyQuestions}:`, "Chiudi", "Avanti", "Invia"],
        ["Um mehr zu erfahren", "Zusammenfassung", "Anwendungsfall", "Quiz", "Ergebnis", `Sie haben ${correctanswers} von ${qtyQuestions} Fragen richtig beantwortet:`, "Schließen", "Weiter", "Senden"],
        ["Чтобы узнать больше", "Резюме", "Пример применения", "Викторина", "Результат", `Вы правильно ответили на ${correctanswers} из ${qtyQuestions} вопросов:`, "Закрыть", "Далее", "Отправить"],
        ["もっと知るために", "要約", "適用事例", "クイズ", "結果", `あなたは${qtyQuestions}問中${correctanswers}問正解しました:`, "閉じる", "次へ", "送信"],
        ["더 알아보기", "요약", "적용 사례", "퀴즈", "결과", `${qtyQuestions}문제 중 ${correctanswers}문제를 맞혔습니다:`, "닫기", "다음", "보내기"],
        ["了解更多", "摘要", "应用案例", "测验", "结果", `你在${qtyQuestions}个问题中答对了${correctanswers}个问题:`, "关闭", "下一个", "发送"],
    ]

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const convertTimeToSeconds = (time) => {
        const [hours, minutes, seconds, frames] = time.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds + frames / 30;
    };

    const findMatchingInteraction = (currentTime, interactions) => {
        const matchingInteraction = interactions.find((interaction, index) => {
            const interactionTimeInSeconds = convertTimeToSeconds(interaction.interaction_time);
            if (Math.abs(currentTime - interactionTimeInSeconds) < 0.2) {
                setInteractionIndex(index);
                return true;
            }
            return false;
        });
        return matchingInteraction;
    };

    const returnPlayVideo = (option) => {
        changeVideoState(option)
    }

    const storeQuizAnswers = (answer) => {
        setQuizAnswers(prevAnswers => [...prevAnswers, answer]);
        setSelectedAlternative()
    }

    const changeInteractionType = () => {
        setShowButtonNext(false)
        setInteractionType("quiz")
    }

    const checkNextQuiz = () => {
        if (quizAnswers.length === qtyQuestions) {
            checkQuizResults();
        }
    }

    const checkQuizResults = () => {
        setShowResults(true);
        setShowButtonClose(true);
    }

    const handleAlternativeChange = (alternative) => {
        setSelectedAlternative(alternative);
    };

    const handleChangeContent = (indexSummary, newValue) => {
        setContentText(newValue)
        const updatedInteractions = [...projectVideoInteraction];
        updatedInteractions[indexSummary].content_text = newValue;
        setProjectVideoInteractionUpdated(updatedInteractions);
    }

    const handleChangeQuestion = (indexQuestion, newValue) => {
        setQuestions(newValue);
        const updatedQuestions = [...projectVideoInteraction];
        updatedQuestions[indexQuestion].questions = newValue;
        setProjectVideoInteractionUpdated(updatedQuestions);
    }

    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }

        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    };

    const handleChangeCase = (indexVideoCase, change) => {
        if (!deepEqual(change, videoCase)) {

            const differences = Object.keys(change).reduce((diff, key) => {
                if (videoCase[key] !== change[key]) {
                    diff[key] = { oldValue: videoCase[key], newValue: change[key] };
                }
                return diff;
            }, {});

            handleChangeVideoCase(indexVideoCase, change)
        }
    };

    const handleChangeVideoCase = (indexVideoCase, newValue) => {
        setVideoCase(newValue);
        const updatedInteractions = [...projectVideoInteraction];
        updatedInteractions[indexVideoCase].video_case = newValue;
        setProjectVideoInteractionUpdated(updatedInteractions);
    }

    const handleDeleteQuestion = (option) => {
        if (option) {
            const updatedInteractions = [...projectVideoInteraction];
            updatedInteractions[interactionIndex].active = false;
            setDeleteInteraction(true);
            setProjectVideoInteractionUpdated(updatedInteractions);
            setActiveInteraction(false);
        }
        setOpenDialog(false);
        setMsgDialog('');
        setAction('');
        setOptionsDialog([]);
    }

    const handleDeleteDialog = () => {
        setMsgDialog(t('projectpreview:videointeractionobject.Você deseja mesmo apagar essa interação'))
        setOptionsDialog([t('projectpreview:videointeractionobject.Sim'), t('projectpreview:videointeractionobject.Cancelar')])
        setAction("delete")
        setOpenDialog(true);
    }

    const createHyperlinks = (text) => {
        const urlPattern = /(?<!\[)(https?:\/\/[^\s\)\]]+)/g;
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line.split(urlPattern).map((part, index) => {
                    if (urlPattern.test(part)) {
                        return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                    }
                    return <span key={index}>{part}</span>;
                })}
                <br />
            </span>
        ));
    };

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        if (changeVideoInteraction && projectVideoInteractionUpdated && projectVideoInteractionUpdated.length > 0) {
            changeVideoInteraction(true, projectVideoInteractionUpdated, deleteInteraction)
        }
    }, [projectVideoInteractionUpdated])

    useEffect(() => {
        if (questions) {
            const correctAnswers = questions.map(question => question.correct_answer);
            setOriginalCorrectAnswers(correctAnswers);
        }
    }, [questions])

    useEffect(() => {
        if (interactionType && interactionType === "quiz") {
            setShowButtonClose(false);
            const qty = questions.length;
            setQtyQuestions(qty);
        } else if (interactionType && interactionType === "summary" || interactionType === "supplementaryContent") {
            setShowButtonClose(true);
        } else if (interactionType === "videoCase") {
            setShowButtonNext(true)
        }
    }, [interactionType, questions])

    useEffect(() => {
        if (quizAnswers && quizAnswers.length > 0) {
            checkNextQuiz();
        }
    }, [quizAnswers])

    useEffect(() => {
        if (!activeInteraction) {
            returnPlayVideo(false);
        }
    }, [activeInteraction])

    useEffect(() => {
        const matchingInteraction = findMatchingInteraction(currentInteractionTime, projectVideoInteraction);
        if (matchingInteraction) {
            setInteractionType(matchingInteraction.interaction_type);
            setActiveInteraction(matchingInteraction.active);
            setContentText(matchingInteraction.content_text);
            setQuestions(matchingInteraction.questions);
            setVideoCase(matchingInteraction.video_case);
        }
    }, [])

    return (
        <div>
            {activeInteraction &&
                <div className={`${styles.videoOverlay} `}>
                    {editProject &&
                        <div className={`row mt-4 me-3`}>
                            <button className={`${styles.buttonX}`} onClick={() => handleDeleteDialog()}>
                                <i className={`d-flex justify-content-end align-items-end bi bi-x-circle ${styles.iconDelete}`}></i>
                            </button>
                        </div>}

                    {interactionType === "supplementaryContent" &&
                        <div>
                            <div className={`row ${styles.objectTitle}`}>
                                {t('projectpreview:videointeractionobject.Saber Mais')}
                            </div>
                            <div className={`col-11 ${styles.formatContent}`}>
                                {editProject ? (
                                    <textarea
                                        className={`${styles.supplementaryContent} col-12`}
                                        value={contentText}
                                        onChange={(e) => handleChangeContent(interactionIndex, e.target.value)}
                                        rows={15}
                                        disabled={!editProject}
                                    />
                                ) : (
                                    <div className={`${styles.supplementaryContent} col-12 mb-5`}>
                                        {createHyperlinks(contentText)}
                                    </div>
                                )}
                            </div>
                        </div>}

                    {interactionType === "summary" &&
                        <div>
                            <div className={`row ${styles.objectTitle}`}>
                                {t('projectpreview:videointeractionobject.Resumo')}
                            </div>
                            <div className={`col-11 ${styles.formatContent}`}>
                                <textarea
                                    className={`${styles.contentText} col-12`}
                                    value={contentText}
                                    onChange={(e) => handleChangeContent(interactionIndex, e.target.value)}
                                    rows={Math.ceil((contentText.length / 100) + 1)}
                                    disabled={!editProject}
                                />
                            </div>
                        </div>}

                    {interactionType === "videoCase" &&
                        <div>
                            <div className={`row ${styles.objectTitle}`}>
                                {t('projectpreview:videointeractionobject.Case')}
                            </div>
                            <div className={`col-11 ${styles.formatCase}`}>
                                <div className="mb-1">
                                    <AssessmentCase
                                        contentGenerated={videoCase}
                                        editProject={editProject}
                                        template={defaultStyles}
                                        language={language}
                                        handleReviewContentGenerated={() => { }}
                                        handleChangeContent={handleChangeCase}
                                        infoText={`Revise seu case abaixo:`}
                                        changeProjectName={() => { }}
                                        indexCase={interactionIndex}
                                    />
                                </div>
                            </div>
                        </div>}

                    {interactionType === "quiz" && !showResults &&
                        <div>
                            <div className={`row ${styles.objectTitle}`}>
                                Quiz
                            </div>
                            <div className={`col-12 ${styles.formatQuestion}`}>
                                {questions.length > 0 && quizAnswers.length < qtyQuestions &&
                                    <div className={styles.questionOption}>
                                        <div>
                                            <h4>
                                                <textarea
                                                    className={`${styles.contentText} col-12`}
                                                    value={questions[quizAnswers.length].ask_question}
                                                    onChange={(e) => {
                                                        const updatedQuestions = [...questions];
                                                        updatedQuestions[quizAnswers.length].ask_question = e.target.value;
                                                        handleChangeQuestion(interactionIndex, updatedQuestions);
                                                    }}
                                                    rows={Math.ceil((questions[quizAnswers.length].ask_question.length / 100) + 1)}
                                                    disabled={!editProject}
                                                />
                                            </h4>
                                        </div>
                                        <div className='mt-4'>
                                            {questions[quizAnswers.length].alternatives.map((alternative, j) =>
                                                <div className={`row d-flex ms-3`} key={j}>
                                                    <div className='col-auto'>
                                                        <input
                                                            className={`form-check-input ${styles.radioInput}`}
                                                            type="radio"
                                                            name={`alternative-${quizAnswers.length}`}
                                                            value={alternative.option}
                                                            checked={alternative.option === selectedAlternative}
                                                            onChange={() => handleAlternativeChange(alternative.option)}
                                                        />
                                                    </div>
                                                    <textarea
                                                        className={`col-auto ${styles.textArea} `}
                                                        type="text"
                                                        id="title"
                                                        name="title"
                                                        value={alternative.text}
                                                        onChange={(e) => {
                                                            const updatedQuestions = [...questions];
                                                            updatedQuestions[quizAnswers.length].alternatives[j].text = e.target.value;
                                                            handleChangeQuestion(interactionIndex, updatedQuestions);
                                                        }}
                                                        rows={Math.ceil((alternative.text.length / 100) + 1)}
                                                        disabled={!editProject}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>}

                    {showResults &&
                        <div>
                            <div className={`row ${styles.objectTitle}`}>
                                {t('projectpreview:videointeractionobject.Resultado')}
                            </div>
                            <div className={`col-11 ${styles.formatContent}`}>
                                <h3 className={`${styles.contentText}`}>
                                    {t('projectpreview:videointeractionobject.Você acertou', {
                                        correctanswers: quizAnswers.filter((answer, i) => answer === originalCorrectAnswers[i]).length,
                                        qtyQuestions: qtyQuestions,
                                        interpolation: { prefix: '${', suffix: '}' }
                                    })}
                                </h3>
                            </div>
                            <div className={`row`}>
                                {questions.map((question, i) =>
                                    <div key={i} className={`row mt-3 ms-5`}>
                                        <h5 className={`col-10 ms-3 ${styles.resultQuestions}`}>
                                            {question.ask_question}
                                        </h5>
                                        <div className="col-1">
                                            {quizAnswers[i] === originalCorrectAnswers[i] ? (
                                                <span className={`${styles.icons}`}>✅</span>
                                            ) : (
                                                <span className={`${styles.icons}`}>❌</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}

                    {showButtonClose &&
                        <div className={`d-flex align-items-end justify-content-end me-4`}>
                            <button className={`col-11 ${styles.buttons}`} onClick={() => returnPlayVideo(false)}>
                                {t('projectpreview:videointeractionobject.Fechar')}
                            </button>
                        </div>}

                    {showButtonNext &&
                        <div className={`d-flex align-items-end justify-content-end me-4`}>
                            <button className={`col-11 ${styles.buttons}`} onClick={() => changeInteractionType()}>
                                {t('projectpreview:videointeractionobject.Próximo')}
                            </button>
                        </div>}

                    {!showButtonClose && !showButtonNext &&
                        <div className={`d-flex align-items-end justify-content-end me-4`}>
                            <button className={`col-11 ${styles.buttons}`} onClick={() => storeQuizAnswers(selectedAlternative)}>
                                {t('projectpreview:videointeractionobject.Enviar')}
                            </button>
                        </div>}
                </div>}
            <div>
                {openDialog && <DialogBox
                    isopen={true}
                    questionDialog={msgDialog}
                    optionsDialog={optionsDialog}
                    action={action}
                    actionScript={handleDeleteQuestion}

                />}
            </div>
        </div>
    )
}

export default VideoInteractionObject