//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import styles from './ChooseProject.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
// React Hooks
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
//Images
import menu1 from '../images/menu1.png';
import menu2 from '../images/menu2.png';
import menu3 from '../images/menu3.png';
import menu4 from '../images/menu4.png';

const ChooseProject = ({ handleChangeProjectSelected, domainPlan, domainActive, profile, author }) => {

    console.log("author ", author) 

    const { preferenceLanguage } = useLanguageValue();
    const { t } = useTranslation([`chooseproject`]);
    const [avaliableProject, setAvaliableProject] = useState(true)
    const [profileAdmin, setProfileAdmin] = useState(false)
    const navigate = useNavigate();

    const handleChangeModule = (module) => {
        navigate('/learnhub');
    }

    useEffect(() => {
        if (profile === "companyadmin") {
            setProfileAdmin(true)
        }
    }, [profile])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div className={`container m-0 p-0 ${styles.mainMenu}`}>
            <div className="row d-flex">
                {profileAdmin &&
                    <div className={`col-3 d-flex align-items-center ps-5 ${styles.texts}`}>
                        <div>
                            <h5 className={`p-0  m-0 ${styles.texts}`}>{t('chooseproject:texts.Ola voce esta')}</h5>
                            <h5 className={`p-0  m-0 ${styles.texts}`}>{t('chooseproject:texts.ScormAI modulo')}</h5>
                        </div>
                    </div>}
                <div className={`${profileAdmin ? `col-6` : `col-12`} d-flex align-items-center justify-content-center`}>
                    <h1 className={styles.menu_title}>
                        {t('chooseproject:title')}
                    </h1>
                </div>
                {profileAdmin && !author &&
                    <div className={`col-3 d-flex align-items-center justify-content-end pe-5`}>
                        <div className="" onClick={() => handleChangeModule("hub")}>
                            <h5 className={`p-0  m-0 ${styles.texts} ${styles.textsLink}`}>{t('chooseproject:texts.mude para')}</h5>
                            <h5 className={`p-0 m-0 ${styles.texts} ${styles.textsLink}`}>{t('chooseproject:texts.Learn Hub')}</h5>
                        </div>
                    </div>}
            </div>

            <div className={`row align-items-center justify-content-center mt-5`}>
                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive && domainPlan != "free" ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive && domainPlan != "free" ? () => handleChangeProjectSelected("file") : null}>
                    <img src={menu1} alt="menu1" />
                    <h1>{t('chooseproject:menu.curso')}</h1>
                    <h5 className={styles.subtitle}>
                        {t('chooseproject:description.curso').split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}
                    </h5>
                </div>

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive ? () => handleChangeProjectSelected("assessment") : null}>
                    <img src={menu2} alt="menu2" />
                    <h1>{t('chooseproject:menu.assessment')}</h1>
                    <h5>
                        {t('chooseproject:description.assessment').split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}
                    </h5>
                </div>

                {/*<div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive && domainPlan != "free"  ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive && domainPlan != "free"  ? () => handleChangeProjectSelected("content") : null}>
                    <img src={menu3} alt="menu3" />
                    <h1>{t('chooseproject:menu.text')}</h1>
                    <h5>
                        {t('chooseproject:description.text').split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}
                    </h5>
                </div>*/}

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive && domainPlan != "free" ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive && domainPlan != "free" ? () => handleChangeProjectSelected("subtitle") : null}>
                    <img src={menu4} alt="menu4" />
                    <h1>{t('chooseproject:menu.caption')}</h1>
                    <h5>
                        {t('chooseproject:description.caption').split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}
                    </h5>
                </div>
            </div>
            <div className='row d-flex justify-content-end align-items-center me-4'>
                <div className={`d-flex align-items-cener ${styles.button}`} onClick={() => navigate('/myprojects')}>
                    <i className="bi bi-files align-text-bottom me-3"> </i>
                    {t('chooseproject:texts.Meus Projetos')}
                </div>
            </div>
        </div>
    );
};

export default ChooseProject;