//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import styles from './ProjectTemplate.module.css'
//React Hooks
import { useEffect, useState } from 'react';
import { useFetchProjects } from '../hooks/useFetchProjects';
//Dev hooks
import { useLanguageValue } from '../context/LanguageContext';


const ProjectTemplate = ({ handleChangeTemplate, actualTemplate, controlComponent }) => {

  console.log("actualTemplate", actualTemplate)

  const projectTemplate = actualTemplate;

  const { documents: templates, loadingTemplates, errorTemplates } = useFetchProjects("Templates");

  const [templateImages, setTemplateImages] = useState({});
  const [selectedCard, setSelectedCard] = useState(projectTemplate);
  const [cssTemplate, setCssTemplate] = useState("");
  const [templatesList, setTemplatesList] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [templatesData, setTemplatesData] = useState([])

  const { t } = useTranslation(['projectpreview']);
  const { preferenceLanguage } = useLanguageValue();

  const handleCardClick = (templateId, templateCSS) => {
    if (selectedCard === templateId) {
      setSelectedCard(null);
    } else {
      handleChangeTemplate(templateId, templateCSS)
      setCssTemplate(templateCSS);
      setSelectedCard(templateId);
    }
  };

  useEffect(() => {
    if (templates) {
      console.log("templates", templates)
      setTemplatesList(templates);
      const foundTemplate = templates.find((template) => template.id === projectTemplate);

      console.log("foundTemplate", foundTemplate)

      handleChangeTemplate(projectTemplate, foundTemplate.css)
    }
  }, [templates])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
      <div >
        <h6 className={`m-2`}>{t('projectpreview:projecttemplate.Escolha abaixo template projeto')} </h6>
      </div>
      <div className="">
        {imageLoading
          ? <div class="d-flex justify-content-center mt-5">
            <div class="align-self-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">{`Avançar`}</span>
              </div>
            </div>
          </div>
          : templatesList && templatesList.map((template) => (
            <div
              className={`mb-3  d-flex justify-content-center ${styles.card_config}`}
              onClick={() => handleCardClick(template.id, template.css)}
              key={template.id}
            >
              <img
                src={template.url}
                alt={template.name}
                className={` ${selectedCard === template.id ? styles.selected_card : ""
                  } ${styles.templateImage}`}
                //onLoad={() => setImageLoading(false)}
                style={{ display: imageLoading ? "none" : "block" }}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProjectTemplate