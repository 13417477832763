import React from 'react'


import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

export const useGenerateHTML = () => {
  const storage = getStorage();

  const previewHTML = (project) => {

    const htmlString = generateHTML(project);
    const blobHTML = new Blob([htmlString], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(blobHTML);

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  const generateHTML = (project) => {

    let carouselTotalSlides = 0;

    let showPPtTogether = project.showSlides ? project.showTogether : true;

    let showVideoTogether = project.showVideo ? project.showVideoTogether : true;

    let thereIsSlides = (project.listUrlSlides && project.listUrlSlides.length > 0 || project.imagesList && project.imagesList.length > 0) ? true : false


    let thereIsVideo = (project.videoUrl || project.videoFile) ? true : false

    const cssString = project.css;
    const templateName = project.templateName;
    let caroselColor = "carousel-dark"

    const showProgress = project.showProgress;
    const menuItems = project.menuItems;
    const menuFreeNavigation = project.menuFreeNavigation;
    const includeSlides = project.includeSlides;
    const includeVideo = project.includeVideo;

    const projectContent = project.projectContent;
    const includeCase = project.includeCase;
    const includeContent = project.includeContent;

    const srclang = project.srclang;
    const subtitleLabel = project.label;
    const showSubtitle = project.showSubtitle;

    console.log("Deve mostrar a legenda: ", showSubtitle)

    const projectVideoInteraction = project.videoInteraction;
    const interactionTimes = project.interactionTimes;

    const hasAssessment = project.assessment && project.assessment.length > 0 ? true : false

    if (templateName === "blue" || templateName === "purpleRain" || templateName === "quizai" || templateName === "aqua" || templateName === "cleanDark" || templateName === "fresh" || templateName === "green") {
      caroselColor = ""
    }

    let html = `
          <html dir="ltr" mozdisallowselectionprint moznomarginboxes>
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
            <meta name="google" content="notranslate">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <title>${project.projectName}</title>
            
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>`

    if (project.projectDestiny === "scorm12") {
      html += `
        <script src="scorm/APIWrapper.js"></script>
        <script src="scorm/SCOFunctions.js"></script>`;
    } else if (project.projectDestiny === "scorm2004") {
      html += `
        <script src="scorm/contentfunctions.js"></script>
        <script src="scorm/scormfunctions.js"></script>`;
    }

    if (project.projectDestiny === "preview" || project.projectDestiny === "word") {
      html += `
            <style>${cssString}</style>
          `;
    } else if (project.projectDestiny === "generatehtml" || project.projectDestiny === "scorm12" || project.projectDestiny === "scorm2004") {

      html += `
            <link rel="stylesheet" href="css/styles.css" />
          `;
    }

    html += `
      ${project.showSlides
        ? `
            <style type="text/css">
body {
      margin: 0 auto !important;
      padding-top: 0 !important;
      top: 0 !important;
      border: 0 !important;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      min-width: 100% !important;
      max-width: 100% !important;
    }

    .fadeIn {
      opacity: 0;
      animation: fadeInAnima .7s ease-in-out forwards;
      animation-delay: .9s;
    }

    @keyframes fadeInAnima {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .fadeOut {
      opacity: 1;
      animation: fadeOutAnima 1s ease-in-out forwards;
    }

    @keyframes fadeOutAnima {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    .capsula {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
    }

    .content-wrapper {
      margin-top: 0 !important;
      /*padding-top: 0!important;*/
      padding: .5em;
      top: 0 !important;
      border: 0 !important;
    }

    #proximo-conteudo {
      position: absolute;
    }

    #menuButton {
      border: 0;
      background-color: transparent;
      color: white;
    }

    .disabled-menu-item {
      opacity: 0.5;
      /* por exemplo, torna a cor mais clara ajustando a opacidade */
      pointer-events: none;
      /* impede eventos de clique nos itens desativados */
    }

    .dropdown-item {
      font-size: 10px;
    }

    #slideMenu {
      max-height: 300px;
      overflow-y: auto;
    }

    .force-scroll {
      overflow-y: scroll !important;
    }

    .force-down {
      margin-top: 10px !important;
    }

    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 90%;
      height: 100%;
      max-width: 90%;
      max-height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .video-interactive-overlay {
      position: absolute;
      backdrop-filter: blur(10px);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 90%;
      height: 100%;
      max-width: 90%;
      max-height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .responsive-video {
      width: 90%;
      height: 90%;
      max-width: 90%;
      max-height: 90%;
      display: block;
      margin: 0 auto;
    }

    .objectTitle,
    .objectknowMoreTitle {
      margin-top: 10%;
      margin-left: 5%;
      font-size: 40px;
      font-weight: bold;
      color: yellow;
    }

    .videoCaseTitle {
      margin-top: -1%;
      margin-left: 5%;
      margin-bottom: 2%;
      font-size: 40px;
      font-weight: bold;
      color: yellow;
    }

    .formatContent,
    .formatKnowMore {
      margin-top: 5%;
      margin-left: 5%;
    }

    .knowMoreText {
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: bold;
      margin-top: 50px;
      margin-left: 30px;
    }

    .contentText {
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: bold;
      margin-top: 50px;
      margin-left: 30px;
    }

    .button-container {
      text-align: right;
      margin-bottom: 2em;
      margin-right: 1em;
    }

    .button-next-container {
      text-align: right;
      margin-top: -2em;
      margin-right: -12em;
    }

    .close-button,
    .next-button {
      background-color: #0083dd;
      color: #ffffff;
      border: none;
      padding: 0.5em 1em;
      border-radius: 0.5em;
      cursor: pointer;
      font-size: 1em;
      width: 10em;
      margin-top: 2em;
      margin-right: 1em;
    }

    .adjust-nextButton {
      margin-right: 15em;
    }

    .close-button:hover,
    .next-button:hover {
      background-color: #005fa3;
    }

    .questionOption {
      margin-top: 3%;
      margin-left: 5%;
      margin-right: 2%;
      color: rgb(255, 255, 255);
      font-size: 20px;
    }

    .radioInput {
      cursor: pointer;
    }

    label {
      background-color: #ffffff38;
      border-radius: 7em;
      min-width: 80%;
      max-width: 80%;
      padding: 0.5em;
      margin-top: 0.1em;
      color: #ffffff;
      margin-left: 1em;
      cursor: pointer;
    }

    .resultQuestions {
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 2em;
    }

    .accordion-button {
      padding: .5em;
      font-size: .6em;
    }

    .accordion-item {
      opacity: 0.75;
    }

    .fontSize {
      font-size: 1rem;
    }

    .subject {
      font-size: 1.5em
    }

    .scrollText {
      max-height: 400px;
      overflow-y: scroll;
    }



    /* Tamanho médio */
    @media (min-width: 915px) {
      .fontSize {
        font-size: 1.5rem;
      }

      .subject {
        font-size: 3em
      }

      .objectTitle,
      .objectknowMoreTitle {
        margin-top: 2%;
      }

      .contentText {
        color: rgb(255, 255, 255);
        font-size: 25px;
        font-weight: bold;
        margin-top: 40px;
        margin-left: 10px;
      }

      .knowMoreText {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: bold;
        margin-top: 30px;
        margin-left: 50px;
        margin-right: 50px;
      }

      .scrollText {
        max-height: 500px;
        overflow-y: scroll;
      }
    }

    /* Tamanho pequeno */
    @media (max-width: 667px) {
      .fontSize {
        font-size: 1rem;
      }

      .subject {
        font-size: 2em;
      }

      .objectTitle,
      .objectknowMoreTitle {
        margin-top: 2%;
        margin-left: 2%;
      }

      .contentText {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: bold;
        margin-top: 40px;
        margin-left: 20px;
      }

      .knowMoreText {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: bold;
        margin-top: 40px;
        margin-left: 20px;
      }

      .scrollText {
        max-height: 400px;
        overflow-y: scroll;
      }
    }
  </style>`
        : ``
      }
    </head>
    <body>`

    if (project.projectDestiny === "preview" || project.projectDestiny === "word") {
      html += `
              <div class="col-12 col-md-8 col-lg-10 text-center text-md-right" id="Titulo">  
                <h1 class="subject">${project.projectName}</h1>
              </div>
          `;
    } else {

      html +=
        `<div class="container p-0 m-0 ${project.showSlides && project.imagesList && project.imagesList.length > 0 ? `d-none` : ''}" id="topBarProject">
              <div class="row custom_bar p-0 mt-1 mb-1 rounded-top d-flex align-items-center">
                ${project.urlLogo
          ? `<div class="col-2 col-md-4 col-lg-2 text-center text-md-left" id="logoCliente">
                          <img class="img-fluid p-1" src=${project.urlLogo} id="div_logo">
                        </div>`
          : ''
        }
                <div class="col-10 ${project.urlLogo ? `text-end` : `text-center`}" id="Titulo">  
                    <h1 class="subject">${project.projectName}</h1>
                </div>
              </div>
            </div> 
          </div> `
    }

    if (project.showVideo && project.videoFile) {
      html += `
      <div class="container p-0 m-0 position-relative" id="videoContainer">
      <video controls controlsList="nodownload" class="responsive-video" id="myVideo">
          <source src="video/video.mp4" type="video/mp4" />
          ${showSubtitle ? `<track src="video/subtitle.vtt" kind="subtitles" srclang=${srclang} label=${subtitleLabel} />` : ''}
      </video>
      <div class="video-interactive-overlay d-none" id="videoInteractiveOverlay">

        <div class="d-none" id="divSummary">
        <div class="row objectTitle" id="summaryTitle">
          Resumo
        </div>
        <div class="col-11 formatContent">
          <h3 class="contentText" id="summary"></h3>
        </div>
        </div>

        <div class="d-none" id="divKnowMore">
          <div class="row objectknowMoreTitle" id="knowMoreTitle">
        Para Saber Mais
          </div>
          <div class="col-11 formatKnowMore">
        <h3 class="knowMoreText scrollText" id="knowMore"></h3>
          </div>
        </div>

        <div class="d-none w-100" id="divVideoCase">
        <div class="row videoCaseTitle" id="videoCaseTitle">
          Case de aplicação
        </div>
        <div class="col-11 videoCase">
          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseIntro" aria-expanded="true" aria-controls="caseIntro" id="textCaseIntro">
              Introdução
            </button>
          </h2>
          <div id="caseIntro" class="accordion-collapse collapse show p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseIntro
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseDesc" aria-expanded="false" aria-controls="caseDesc" id="textCaseDesc">
              Descrição
            </button>
          </h2>
          <div id="caseDesc" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseDesc
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseChallengeContext" aria-expanded="false" aria-controls="caseChallengeContext"  id="textCaseChallengeContext">
              Desafios
            </button>
          </h2>
          <div id="caseChallengeContext" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseChallengeContext
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseProblemAnalysis" aria-expanded="false" aria-controls="caseProblemAnalysis" id="textCaseProblemAnalysis">
              Análise do Problema
            </button>
          </h2>
          <div id="caseProblemAnalysis" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseProblemAnalysis
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseProposedSolution" aria-expanded="false" aria-controls="caseProposedSolution" id="textCaseProposedSolution">
              Solução Proposta
            </button>
          </h2>
          <div id="caseProposedSolution" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseProposedSolution
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseResults" aria-expanded="false" aria-controls="caseResults" id="textCaseResults">
              Resultados e Impactos
            </button>
          </h2>
          <div id="caseResults" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseResults
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseLessons" aria-expanded="false" aria-controls="caseLessons" id="textCaseLessons">
              Lições Aprendidas
            </button>
          </h2>
          <div id="caseLessons" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseLessons
            </div>
          </div>
            </div>

            <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseConclusion" aria-expanded="false" aria-controls="caseConclusion" id="textCaseConclusion">
              Conclusão
            </button>
          </h2>
          <div id="caseConclusion" class="accordion-collapse collapse p-3" data-bs-parent="#accordionExample">
            <div class="accordion-body acordionBody caseTexts">
              caseConclusion
            </div>
          </div>
            </div>
          </div>
        </div>
        </div>

        <div class="d-none col-12" id="divQuiz">
          <div class="row objectTitle">
          Quiz
          </div>
          <div class="col-12">
        <div class="questionOption">

          <h4 class="askQuestion fontSize" id="quizAskQuestion"></h4>
          <div class="mt-4">

            <div class="row ms-1 ms-md-3 d-none" id="divOpt1">
          <div class="col-12 d-flex align-items-center">
            <input 
              class="form-check-input radioInput"
              type="radio"
              name="quizOption"
              id="option1"
              value="a">
            <label for="option1" class="form-check-label fontSize">Option A</label>
          </div>
           </div>
           <div class="row ms-1 ms-md-3 d-none" id="divOpt2">
          <div class="col-12 d-flex align-items-center">
            <input 
              class="form-check-input radioInput"
              type="radio"
              name="quizOption"
              id="option2"
              value="b">
            <label for="option2" class="form-check-label fontSize">Option B</label>
          </div>
           </div>
           <div class="row ms-1 ms-md-3 d-none" id="divOpt3">
          <div class="col-12 d-flex align-items-center">
            <input 
              class="form-check-input radioInput"
              type="radio"
              name="quizOption"
              id="option3"
              value="c">
            <label for="option3" class="form-check-label fontSize">Option C</label>
          </div>
           </div>
           <div class="row ms-1 ms-md-3 d-none" id="divOpt4">
          <div class="col-12 d-flex align-items-center">
            <input 
              class="form-check-input radioInput"
              type="radio"
              name="quizOption"
              id="option4"
              value=
                    <label for="option4" class="form-check-label fontSize">Option D</label>
                  </div>
               </div>
               <div class="row ms-1 ms-md-3 d-none" id="divOpt5">
                  <div class="col-12 d-flex align-items-center">
                    <input 
                      class="form-check-input radioInput"
                      type="radio"
                      name="quizOption"
                      id="option5"
                      value="e">
                    <label for="option5" class="form-check-label fontSize">Option E</label>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="d-none" id="divQuizResults">
          <div class="row objectTitle" id="resultTitle">
              Resultado
          </div>
          <div class="col-11 formatContent">
            <h3 class="contentText" id="quizResult"> </h3>
          </div>
          <div class="row">
            <div class="row mt-3 ms-1 ms-md-5">
              <h5 class="col-9 col-md-8 ms-3 resultQuestions" id="askQuestion1"></h5>
              <div class="col-2 col-md-2 icons" id="resultQuestion1"></div>
            </div>
            <div class="row mt-3 ms-1 ms-md-5">
              <h5 class="col-9 col-md-8 ms-3 resultQuestions" id="askQuestion2"></h5>
              <div class="col-2 col-md-2 icons" id="resultQuestion2"></div>
            </div>
            <div class="row mt-3 ms-1 ms-md-5">
              <h5 class="col-9 col-md-8 ms-3 resultQuestions" id="askQuestion3"></h5>
              <div class="col-2 col-md-2 icons" id="resultQuestion3"></div>
            </div>
          </div>
        </div>

      </div> 
      <div class="video-overlay d-none" id="videoOverlay"></div> 
        <div class="row position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center">
            <div class=" col-3 ">
                <button class="w-100 mt-3 btn btn-primary d-none" id="reviewVideo" style="font-size: 20px;
                    border-radius: 5em;
                    z-index: 100;
                    min-width: 10em;
                    max-width: 10em;">Rever <br /> Vídeo</button>
            </div>
            <div class=" col-3 ">
                <button class="w-100 mt-3 ms-5 btn btn-primary d-none" id="answerAssessment" style="font-size: 20px;
                    border-radius: 5em;
                    z-index: 100;
                    min-width: 10em;
                    max-width: 10em;">Responder avaliação</button>
            </div>
            <div class=" col-3 ">
                <button class="w-100 mt-3 btn btn-primary d-none" id="finishCourse" style="font-size: 20px;
                    border-radius: 5em;
                    z-index: 100;
                    min-width: 10em;
                    max-width: 10em;">Finalizar <br /> Curso</button>
            </div>
        </div>
    </div>`;
    }

    if (project.showVideo && project.videoUrl) {
      html += `
      <div class="container" id="videoContainer">
        <video controls controlsList="nodownload" class="responsive-video" id="myVideo">
          <source src=${project.videoUrl} type="video/mp4" />
          ${ showSubtitle ? <track src="${project.subtitleUrl}" kind="subtitles" srclang="pt" label="Português" /> : ''}
        </video>
      </div>
      <div class="row align-items-center justify-content-center " >
          <button class="w-25 mt-3 ms-5 btn btn-primary d-none" id="answerAssessment" style="font-size: 20px;
          border-radius: 5em;
          min-width: 10em;
          max-width: 10em;">Responder avaliação</button>
      </div>`
    }

    //HTML and SCORM packages
    if (project.showSlides && project.imagesList && project.imagesList.length > 0) {
      carouselTotalSlides = project.imagesList.length;
      html += `
        <div class="capsula fadeIn" id="scalingContainer">
          <div class="content-wrapper" id="carouselSlides" >
            <div class="custom_bar top_bar w-100 d-flex justify-content-around">`
      if (project.urlLogo) {
        html += `<div class="logo"><img src="${project.urlLogo}" class="d-block w-100" alt="${project.urlLogo}"></div>`
      }
      html += `<div class="course_title ${project.urlLogo ? "adjust_align" : ""}">${project.projectName}</div>`

      if (showProgress) {
        html += `<div class="progressBarContainer" id="progressBarContainer">
                <div class="progressBar" id="progressBar" style="width: 0; margin-left: 0;"></div>
                <div class="progressTextContainer" id="progressTextContainer">0%</div>
              </div>`
      }

      html += `</div>
              <div class="carousel ${caroselColor} slide w-100">
                <div class="carousel-inner">`;

      project.imagesList.map((image, index) => {
        html += `<div key=${index} class="carousel-item ${index === 0 ? 'active' : ''}" >
                    <img src="img/${image.name}" class="d-block w-100 " alt=${image.name} >
                 </div>`;
      });

      html += `
            </div>
              <button class="carousel-control-prev d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="prev" id="prevButton">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="next" id="nextButton">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>

            <div class="custom_bar bottom_bar w-100">
              <div class="col-auto btn-group dropup">
                <button type="button" class="btn btn-secondary dropdown-toggle" id="menuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-list menu_icon"></i>
                </button>
                <ul class="dropdown-menu" id="slideMenu">
                </ul>
              </div>

              <div class="col-auto menu_adjust">
                  <button class="button_navigation" id="prevButtonCarousel"><i class="bi bi-arrow-left-circle"></i></button>
                  <button class="button_navigation" id="nextButtonCarousel"><i class="bi bi-arrow-right-circle"></i></button>
                  <button class=" d-none" id="answerAssessment">Responder avaliação</button>
              </div>
            </div>
          </div>
        </div>
        `;
    }

    //preview in QuizAI
    if (project.showSlides && project.listUrlSlides && project.listUrlSlides.length > 0) {
      carouselTotalSlides = project.listUrlSlides.length;
      html += `
      <div class=" capsula fadeIn" id="scalingContainer" >
        <div class="content-wrapper" id="carouselSlides" >

          <div class="custom_bar top_bar w-100 d-flex justify-content-around">`
      if (project.urlLogo) {
        html += `<div class="logo"><img src="${project.urlLogo}" class="d-block w-100" alt="${project.urlLogo}"></div>`
      }
      html += `<div class="course_title ${project.urlLogo ? "adjust_align" : ""}">${project.projectName}</div>`

      if (showProgress) {
        html += `<div class="progressBarContainer" id="progressBarContainer">
              <div class="progressBar" id="progressBar" style="width: 0; margin-left: 0;"></div>
              <div class="progressTextContainer" id="progressTextContainer">0%</div>
            </div>`
      }

      html += `<div  class="carousel ${caroselColor} slide">
      <div class="carousel-inner">`;

      project.listUrlSlides.map((slide, index) => {
        html += `<div key=${index} class="carousel-item ${index === 0 ? 'active' : ''}" >
            <img src=${slide} class="d-block w-100 " alt=${slide} >
          </div>`;
      });

      html += `
          </div>
              <button class="carousel-control-prev d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="prev" id="prevButton">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="next" id="nextButton">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>

            <div class="custom_bar bottom_bar w-100">
              <div class="col-auto btn-group dropup">
                <button type="button" class="btn btn-secondary dropdown-toggle" id="menuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-list menu_icon"></i>
                </button>
                <ul class="dropdown-menu" id="slideMenu">
                </ul>
              </div>

              <div class="col-auto menu_adjust">
                  <button class="button_navigation" id="prevButtonCarousel"><i class="bi bi-arrow-left-circle"></i></button>
                  <button class="button_navigation" id="nextButtonCarousel"><i class="bi bi-arrow-right-circle"></i></button>
                  <button class=" d-none" id="answerAssessment">Responder avaliação</button>
                </div>
            </div>
          </div>
        </div>
    `;
    }

    html += `<div class="container ${(includeSlides && !showPPtTogether) ? `force-down` : ''}" id="proximo-conteudo">

    <div class="${thereIsSlides
        ? showPPtTogether
          ? ''
          : 'd-none'
        : thereIsVideo
          ? showVideoTogether
            ? ''
            : 'd-none'
          : ''}" 
          id="allAssessment">`

    if (project.introAssessment) {
      html += `<h3 class="mensagem ms-5 me-5">${project.introAssessment}</h3>`
    }

    html += `
      <div class="scrollable-area mt-5">
        <div class="content">`;

    if (projectContent) {
      if (includeCase) {
        const caseTitle = projectContent.title;
        const caseIntro = projectContent.intro;
        const caseDesc = projectContent.company_description;
        const caseContext = projectContent.problem_context;
        const caseChallengeContext = projectContent.problem_or_challenge_description;
        const caseProblemAnalysis = projectContent.problem_analysis;
        const caseProposedSolution = projectContent.proposed_solution;
        const caseImplementation = projectContent.solution_implementation;
        const caseResults = projectContent.results_and_impacts;
        const caseLessons = projectContent.lessons_learned;
        const caseConclusion = projectContent.conclusion;

        html += `
            <div class="accordion mb-3" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseIntro" aria-expanded="true" aria-controls="caseIntro" id="textCaseIntro">
                    Introdução
                  </button>
                </h2>
                <div id="caseIntro" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseIntro}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseDesc" aria-expanded="false" aria-controls="caseDesc" id="textCaseDesc">
                    Descrição
                  </button>
                </h2>
                <div id="caseDesc" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseDesc}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseContext" aria-expanded="false" aria-controls="caseContext" id="textCaseContext">
                    Contexto do Problema
                  </button>
                </h2>
                <div id="caseContext" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseContext}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseChallengeContext" aria-expanded="false" aria-controls="caseChallengeContext"  id="textCaseChallenge">
                    Desafios
                  </button>
                </h2>
                <div id="caseChallengeContext" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseChallengeContext}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseProblemAnalysis" aria-expanded="false" aria-controls="caseProblemAnalysis" id="textCaseAnalysis">
                    Análise do Problema
                  </button>
                </h2>
                <div id="caseProblemAnalysis" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseProblemAnalysis}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseProposedSolution" aria-expanded="false" aria-controls="caseProposedSolution" id="textCaseProposal">
                    Solução Proposta
                  </button>
                </h2>
                <div id="caseProposedSolution" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseProposedSolution}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseImplementation" aria-expanded="false" aria-controls="caseImplementation" id="textCaseImplementation">
                    Implementaçao
                  </button>
                </h2>
                <div id="caseImplementation" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseImplementation}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseResults" aria-expanded="false" aria-controls="caseResults" id="textCaseResults">
                    Resultados e Impactos
                  </button>
                </h2>
                <div id="caseResults" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseResults}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseLessons" aria-expanded="false" aria-controls="caseLessons" id="textCaseLearn">
                    Lições Aprendidas
                  </button>
                </h2>
                <div id="caseLessons" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseLessons}
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed subtitles" type="button" data-bs-toggle="collapse" data-bs-target="#caseConclusion" aria-expanded="false" aria-controls="caseConclusion" id="textCaseConclusion">
                    Conclusão
                  </button>
                </h2>
                <div id="caseConclusion" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body acordionBody caseTexts">
                    ${caseConclusion}
                  </div>
                </div>
              </div>

            </div>`

      } else if (includeContent) {
        const contentTitle = projectContent.title;
        const contentIntro = projectContent.intro;
        const contentTopic1 = projectContent.topics[0].subtitle;
        const contentText1 = projectContent.topics[0].content;
        const contentTopic2 = projectContent.topics[1].subtitle;
        const contentText2 = projectContent.topics[1].content;
        const contentTopic3 = projectContent.topics[2].subtitle;
        const contentText3 = projectContent.topics[2].content;
        const contentTopic4 = projectContent.topics[3].subtitle;
        const contentText4 = projectContent.topics[3].content;
        const contentTopic5 = projectContent.topics[4].subtitle;
        const contentText5 = projectContent.topics[4].content;
        const contentConclusion = projectContent.conclusion;

        html += `
        <div class="d-flex align-items-start">
          <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <button class="nav-link active rounded-0" id="v-pills-contentIntro-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentIntro" type="button" role="tab" aria-controls="v-pills-contentIntro" aria-selected="true">Introdução</button>

            <button class="nav-link rounded-0" id="v-pills-contentText1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentText1" type="button" role="tab" aria-controls="v-pills-contentText1" aria-selected="false">${contentTopic1}</button>

            <button class="nav-link rounded-0" id="v-pills-contentText2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentText2" type="button" role="tab" aria-controls="v-pills-contentText2" aria-selected="false">${contentTopic2}</button>

            <button class="nav-link rounded-0" id="v-pills-contentText3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentText3" type="button" role="tab" aria-controls="v-pills-contentText3" aria-selected="false">${contentTopic3}</button>

            <button class="nav-link rounded-0" id="v-pills-contentText4-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentText4" type="button" role="tab" aria-controls="v-pills-contentText4" aria-selected="false">${contentTopic4}</button>

            <button class="nav-link rounded-0" id="v-pills-contentText5-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentText5" type="button" role="tab" aria-controls="v-pills-contentText5" aria-selected="false">${contentTopic5}</button>

            <button class="nav-link rounded-0" id="v-pills-contentConclusion-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contentConclusion" type="button" role="tab" aria-controls="v-pills-contentConclusion" aria-selected="false">Conclusão</button>

          </div>

          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-contentIntro" role="tabpanel" aria-labelledby="v-pills-contentIntro-tab" tabindex="0">${contentIntro}</div>
            <div class="tab-pane fade" id="v-pills-contentText1" role="tabpanel" aria-labelledby="v-pills-contentText1-tab" tabindex="0">${contentText1}</div>
            <div class="tab-pane fade" id="v-pills-contentText2" role="tabpanel" aria-labelledby="v-pills-contentText2-tab" tabindex="0">${contentText2}</div>
            <div class="tab-pane fade" id="v-pills-contentText3" role="tabpanel" aria-labelledby="v-pills-contentText3-tab" tabindex="0">${contentText3}</div>
            <div class="tab-pane fade" id="v-pills-contentText4" role="tabpanel" aria-labelledby="v-pills-contentText4-tab" tabindex="0">${contentText4}</div>
            <div class="tab-pane fade" id="v-pills-contentText5" role="tabpanel" aria-labelledby="v-pills-contentText5-tab" tabindex="0">${contentText5}</div>
            <div class="tab-pane fade" id="v-pills-contentConclusion" role="tabpanel" aria-labelledby="v-pills-contentConclusion-tab" tabindex="0">${contentConclusion}</div>
          </div>
        </div>`
      }
    }

    if (hasAssessment) {
      html += `<div class="results display-none" id="feedbackAssessment"></div>`;

      project.assessment.forEach((question, index) => {
        let questionHtml = `
        </div>
        <form>
            <div class="question-feedback">
              <h2 class="question">${index + 1}. ${question.ask_question}</h2>
          `;

        question.alternatives.forEach((alternative, altIndex) => {
          const optionLetter = String.fromCharCode(97 + altIndex);
          let optionHtml = '';
          let optionClass = '';
          let correctAnswer = '';
          let clickedLabelValue;

          if (project.projectDestiny === "word") {
            optionHtml = `
                ${alternative.option})
              `;
            alternative.option === question.correct_answer ? optionClass = "selected" : optionClass = "";
          }

          questionHtml += `
        <label class="${correctAnswer}" id="label_${altIndex}">
          <span class="${optionClass}">${optionHtml}</span>
          <input type="radio" name="answer${index}" value="${alternative.option}" class="${optionClass}" />
          <span class="${optionClass}">${alternative.text}</span>
        </label><br>
            `;
        });

        questionHtml += `<div  id="feedbackDiv${index}"></div>`;
        html += questionHtml;
      });

    }

    (project.projectDestiny != "word" && hasAssessment) && (html += `
            <p></p>
            <button id="submitButton">OK</button>`)

    html +=
      ` 
        </form>
        </div>
    </div>

    <script>

        var currentSlideIndex = 0
        var listItemsMenu = ${JSON.stringify(menuItems)};
        var menuFree = ${menuFreeNavigation}
        var totalSlides = ${carouselTotalSlides};
        var slide = 1;
        var visitedSlide = 1;
        var lastPageVisited = false;
        var hasAssessment = ${project.hasAssessment}
        var interactions = ${JSON.stringify(projectVideoInteraction)}
        var interactionTimes = ${JSON.stringify(interactionTimes)}
        var interactionTimesInSeconds = [];
      
        if (interactionTimes) {
          interactionTimesInSeconds = interactionTimes.map(time => {
            const [hours, minutes, seconds, frames] = time.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds + frames / 30; 
          });
        }

        document.addEventListener("DOMContentLoaded", function() {
          var contentButtons;
          const accordionButtons = document.querySelectorAll('.accordion-button');
          const navButtons = document.querySelectorAll('.nav-link');
          if(accordionButtons.length > 0){
            contentButtons = accordionButtons;
            const textCaseIntro = document.getElementById('textCaseIntro');
            if (textCaseIntro) {
               textCaseIntro.textContent = checkLanguage(0).stringCaseIntro;
            }         
            const textCaseDesc = document.getElementById('textCaseDesc');
            if (textCaseDesc) {
               textCaseDesc.textContent = checkLanguage(0).stringCaseDescription;
            }         
            const textCaseContext = document.getElementById('textCaseContext');
            if (textCaseContext) {
               textCaseContext.textContent = checkLanguage(0).stringCaseContext;
            }         
            const textCaseChallenge = document.getElementById('textCaseChallenge');
            if (textCaseChallenge) {
               textCaseChallenge.textContent = checkLanguage(0).stringCaseChallenge;
            }         
            const textCaseAnalysis = document.getElementById('textCaseAnalysis');
            if (textCaseAnalysis) {
               textCaseAnalysis.textContent = checkLanguage(0).stringCaseAnalysis;
            }         
            const textCaseProposal = document.getElementById('textCaseProposal');
            if (textCaseProposal) {
               textCaseProposal.textContent = checkLanguage(0).stringCaseSolution;
            }         
            const textCaseImplementation = document.getElementById('textCaseImplementation');
            if (textCaseImplementation) {
               textCaseImplementation.textContent = checkLanguage(0).stringCaseImplementation;
            }         
            const textCaseResults = document.getElementById('textCaseResults');
            if (textCaseResults) {
               textCaseResults.textContent = checkLanguage(0).stringCaseResult;
            }         
            const textCaseLearn = document.getElementById('textCaseLearn');
            if (textCaseLearn) {
               textCaseLearn.textContent = checkLanguage(0).stringCaseLearning;
            }         
            const textCaseConclusion = document.getElementById('textCaseConclusion');
            if (textCaseConclusion) {
               textCaseConclusion.textContent = checkLanguage(0).stringCaseConclusion;
            }         
          } else if (navButtons.length > 0) {
            contentButtons = navButtons;
            const textIntro = document.getElementById('v-pills-contentIntro-tab');
            if (textIntro) {
               textIntro.textContent = checkLanguage(0).stringCaseIntro;
            }
            const textConclusion = document.getElementById('v-pills-contentConclusion-tab');
            if (textConclusion) {
               textConclusion.textContent = checkLanguage(0).stringCaseConclusion;
            }    
          }
          let allClicked = false;
          const clickedItems = {};
          
          if(contentButtons){
            contentButtons.forEach(button => {
              const targetId = button.getAttribute('data-bs-target');
              clickedItems[targetId] = targetId != "#caseIntro" && targetId != "#v-pills-contentIntro" ? false : true;
              button.addEventListener('click', () => {
                clickedItems[targetId] = true;
                checkAllClicked();
              });
            });
          }

          function checkAllClicked() {
            allClicked = Object.values(clickedItems).every(value => value);
            if (allClicked) {
              if (destiny === "scorm12") {
                const completionStatus = 'completed';
                doLMSSetValue('cmi.core.lesson_status', completionStatus);
                doLMSCommit();
              } else if (destiny === "scorm2004"){
                  const completionStatus = 'completed';
                  ScormProcessSetValue('cmi.success_status', "passed")
                  ScormProcessSetValue('cmi.completion_status', completionStatus);
                  doLMSCommit();
              }
            }
          }

          var bookmark;
          if (destiny === "scorm12") {
            bookmark = doLMSGetValue('cmi.core.lesson_location');
          }
      
          const bookmarkPage = bookmark ? parseInt(bookmark.substring(6)) : 0;
          visitedSlide = bookmarkPage ? bookmarkPage + 1 : 1;
          slide = visitedSlide;
  
          var carousel = new bootstrap.Carousel(document.getElementById("carouselSlides"), {
            interval: false
          });
          carousel.to(bookmarkPage);

          function updateProgressBar() {
            const percentCompleted = (visitedSlide / totalSlides) * 100;
            document.getElementById('progressBar').style.width = percentCompleted + '%';
            document.getElementById('progressTextContainer').innerText = percentCompleted.toFixed(0) + '%';
          }
  
          if (visitedSlide > 0) {
            updateProgressBar()
          };
        });

        document.addEventListener("DOMContentLoaded", function() {
          var prevButton = document.getElementById("prevButtonCarousel");
          var nextButton = document.getElementById("nextButtonCarousel");
          var answerAssessment = document.getElementById("answerAssessment");
          var slideMenu = document.getElementById('slideMenu');
          var carousel = new bootstrap.Carousel(document.getElementById("carouselSlides"), {
            interval: false
          });
          const topBarProject = document.getElementById('topBarProject');

          updateMenuState();

          function updateProgressBar() {
            const percentCompleted = (visitedSlide / totalSlides) * 100;
            if(percentCompleted === 100 && !hasAssessment) {
              if (destiny === "scorm12") {
                if(conclusion === "conclusion"){
                  const completionStatus = 'completed';
                  doLMSSetValue('cmi.core.lesson_status', completionStatus);
                } 
              } else if (destiny === "scorm2004"){
                if(conclusion === "conclusion"){
                  const completionStatus = 'completed';
                  ScormProcessSetValue('cmi.completion_status', completionStatus)
                } 
              }
              doLMSCommit()
            }
            document.getElementById('progressBar').style.width = percentCompleted + '%';
            document.getElementById('progressTextContainer').innerText = percentCompleted.toFixed(0) + '%';
          }

          function updateMenuState() {
            slideMenu.innerHTML = '';
            for (var index = 0; index < totalSlides; index++) {
              var menuItemText = listItemsMenu[index];
              var listItem = document.createElement('li');
              var anchor = document.createElement('a');
              anchor.classList.add('dropdown-item');
              anchor.href = '#';
              anchor.textContent = menuItemText;
              anchor.setAttribute('data-slide-index', index);
              if (!menuFree && visitedSlide < (index + 1)) {
                anchor.classList.add('disabled-menu-item');
              } 
              anchor.addEventListener('click', handleMenuItemClick);
              listItem.appendChild(anchor);
              slideMenu.appendChild(listItem);
            }
            slideMenu.classList.add('force-scroll');
          }

          function handleMenuItemClick(event) {
            event.preventDefault();
            var slideIndex = parseInt(event.target.getAttribute('data-slide-index'));
            if (menuFree || visitedSlide >= (slideIndex + 1)) {
              carousel.to(slideIndex);
              slide = slideIndex + 1;
              if(menuFree && slideIndex > visitedSlide){
                visitedSlide = slideIndex + 1;   
                updateProgressBar();
              }
            } 
          }

          document.getElementById("nextButtonCarousel").addEventListener("click", function () {
            slide++;
            const carousel = new bootstrap.Carousel(document.getElementById("carouselSlides"));
            carousel.next();
            if (slide > visitedSlide ) {
              visitedSlide++;
              updateProgressBar();
              updateMenuState();
            }
          });
      
          document.getElementById("prevButtonCarousel").addEventListener("click", function () {
            slide--
            const carousel = new bootstrap.Carousel(document.getElementById("carouselSlides"));
            carousel.prev();

          });

          function updatePrevButtonVisibility() {
            var actualSlide = document.querySelector(".carousel-item.active");
      
            if (actualSlide === document.querySelector(".carousel-inner").firstElementChild) {
              prevButton.classList.add("d-none");
            } else {
              prevButton.classList.remove("d-none");
            }

            if (actualSlide === document.querySelector(".carousel-inner").lastElementChild) {
              nextButton.classList.add("d-none");
              if(${!showPPtTogether && project.hasAssessment}){
                answerAssessment.classList.remove("d-none");
              }
            } else {
              nextButton.classList.remove("d-none");
            }
          }

		      var carouselNavigation = document.getElementById("carouselSlides");
          carouselNavigation.addEventListener("slid.bs.carousel", updatePrevButtonVisibility);
          
          carouselSlides.addEventListener("slid.bs.carousel", function (event) {
            currentSlideIndex = event.to; 
          });

          updatePrevButtonVisibility();
          updateVideoVisibility();
        });

        window.addEventListener('beforeunload', function (e) {
          const locationValue = "slide_" + currentSlideIndex;
          if (destiny === "scorm12" || destiny === "scorm2004") {
            doLMSSetValue('cmi.core.lesson_location', locationValue);
            doLMSCommit();
          }
        });

        const showAssessment = document.getElementById("answerAssessment")
        const allAssessment = document.getElementById("allAssessment");
        const carouselSlides  = document.getElementById("carouselSlides");
        const video = document.getElementById('myVideo');
        var safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const videocontainer = document.getElementById('videoContainer');
        const topBarProject = document.getElementById('topBarProject');
        var answerAssessment = document.getElementById("answerAssessment");
        var reviewVideo = document.getElementById("reviewVideo");
        var scalingContainer = document.getElementById("scalingContainer");
        var finishCourse = document.getElementById("finishCourse");
        const videoInteractiveOverlay = document.getElementById('videoInteractiveOverlay');

        if(video){
          const tolerance = 0.1; 
          const triggeredTimes = new Set(); 

          const checkTime = () => {

          const currentTime = video.currentTime;

          interactionTimesInSeconds.forEach(time => {
              if (Math.abs(currentTime - time) < tolerance && !triggeredTimes.has(time)) {
                triggeredTimes.add(time);
                const interaction = interactions.find(interaction => {
                  const [hours, minutes, seconds, frames] = interaction.interaction_time.split(':').map(Number);
                  const interactionTimeInSeconds = hours * 3600 + minutes * 60 + seconds + frames / 30;
                  return Math.abs(interactionTimeInSeconds - time) < tolerance;
                });

                if (interaction) {
                  videoInteractiveOverlay.classList.remove('d-none');
                  video.pause();
                  video.removeAttribute('controls');
                  const interactionType = interaction.interaction_type;

                  if (interactionType === "summary"){
                    const divSummary = document.getElementById('divSummary');
                    if (divSummary) {
                      const summaryTitle = document.getElementById('summaryTitle');
                      if (summaryTitle) {
                        summaryTitle.textContent = checkLanguage(0).stringSummary;
                      }
                      divSummary.classList.remove('d-none');
                      const summaryElement = document.getElementById("summary");
                      summaryElement.textContent = interaction.content_text;
                      const closeButton = document.createElement("button");
                      closeButton.textContent = stringCloseButton;
                      closeButton.className = "close-button";   
                      closeButton.onclick = () => {
                        summaryElement.textContent = "";  
                        closeButton.remove();   
                        videoInteractiveOverlay.classList.add('d-none');
                        divSummary.classList.add('d-none');  
                        video.play(); 
                        video.setAttribute('controls', 'controls');
                      };
                      const buttonContainer = document.createElement("div");
                      buttonContainer.className = "button-container";
                      buttonContainer.appendChild(closeButton);
                      divSummary.appendChild(buttonContainer);
                    }
                  } else if (interactionType === "supplementaryContent") {
                      const divKnowMore = document.getElementById('divKnowMore');
                      if(divKnowMore) {
                        const knowMoreTitle = document.getElementById('knowMoreTitle');
                        if (knowMoreTitle) {
                          knowMoreTitle.textContent = checkLanguage(0).stringKnowMore;
                        }
                        divKnowMore.classList.remove('d-none');
                        const knowMoreElement = document.getElementById("knowMore");
                        const result = createHyperlinks(interaction.content_text);
                        knowMoreElement.innerHTML = result;
                        const closeButton = document.createElement("button");
                        closeButton.textContent = stringCloseButton;
                        closeButton.className = "close-button";   
                        closeButton.onclick = () => {
                          knowMoreElement.textContent = "";  
                          closeButton.remove();   
                          videoInteractiveOverlay.classList.add('d-none');
                          divKnowMore.classList.add('d-none');  
                          video.play(); 
                          video.setAttribute('controls', 'controls');
                        };
                        const buttonContainer = document.createElement("div");
                        buttonContainer.className = "button-container";
                        buttonContainer.appendChild(closeButton);
                        divKnowMore.appendChild(buttonContainer);
                      }
                   } else if (interactionType === "videoCase") {
                      const divVideoCase = document.getElementById('divVideoCase');
                      if(divVideoCase) {
                        const videoCaseTitle = document.getElementById('videoCaseTitle');
                      if (videoCaseTitle) {
                        videoCaseTitle.textContent = checkLanguage(0).stringVideoCase;
                      }
                      divVideoCase.classList.remove('d-none');

                      const textCaseIntro = document.getElementById('textCaseIntro');
                      const caseIntro = document.getElementById('caseIntro');
                      const intro = interaction.video_case.intro
                      if (textCaseIntro) {
                        textCaseIntro.textContent = checkLanguage(0).stringCaseIntro;
                      }
                      if (caseIntro) {
                        caseIntro.textContent = intro;
                      }

                      const textCaseDesc = document.getElementById('textCaseDesc');
                      const caseDesc = document.getElementById('caseDesc'); 
                      const description = interaction.video_case.company_description
                      if (textCaseDesc) {
                        textCaseDesc.textContent = checkLanguage(0).stringCaseDescription;
                      }
                      if (caseDesc) {
                        caseDesc.textContent = description;
                      }

                      const textCaseChallengeContext = document.getElementById('textCaseChallengeContext');
                      const caseChallengeContext = document.getElementById('caseChallengeContext');
                      const challenge = interaction.video_case.problem_or_challenge_description
                      if (textCaseChallengeContext) {
                        textCaseChallengeContext.textContent = checkLanguage(0).stringCaseChallenge;
                      }
                      if (caseChallengeContext) {
                        caseChallengeContext.textContent = challenge;
                      }

                      const textCaseProblemAnalysis = document.getElementById('textCaseProblemAnalysis');
                      const caseProblemAnalysis = document.getElementById('caseProblemAnalysis');
                      const problem = interaction.video_case.problem_analysis
                      if (textCaseProblemAnalysis) {
                        textCaseProblemAnalysis.textContent = checkLanguage(0).stringCaseAnalysis;
                      }
                      if (caseProblemAnalysis) {
                        caseProblemAnalysis.textContent = problem;
                      }

                      const textCaseProposedSolution = document.getElementById('textCaseProposedSolution');
                      const caseProposedSolution = document.getElementById('caseProposedSolution');
                      const proposedSolution = interaction.video_case.proposed_solution
                      if (textCaseProposedSolution) {
                        textCaseProposedSolution.textContent = checkLanguage(0).stringCaseSolution;
                      }
                      if (caseProposedSolution) {
                        caseProposedSolution.textContent = proposedSolution;
                      }

                      const textCaseResults = document.getElementById('textCaseResults');
                      const caseResults = document.getElementById('caseResults');
                      const results = interaction.video_case.results_and_impacts
                      if (textCaseResults) {
                        textCaseResults.textContent = checkLanguage(0).stringCaseResult;
                      }
                      if (caseResults) {
                        caseResults.textContent = results;
                      }

                      const textCaseLessons = document.getElementById('textCaseLessons');
                      const caseLessons = document.getElementById('caseLessons');
                      const lessons = interaction.video_case.lessons_learned
                      if (textCaseLessons) {
                        textCaseLessons.textContent = checkLanguage(0).stringCaseLearning;
                      }
                      if (caseLessons) {
                        caseLessons.textContent = lessons;
                      }

                      const textCaseConclusion = document.getElementById('textCaseConclusion');
                      const caseConclusion = document.getElementById('caseConclusion');
                      const conclusion = interaction.video_case.conclusion
                      if (textCaseConclusion) {
                        textCaseConclusion.textContent = checkLanguage(0).stringCaseConclusion;
                      }
                      if (caseConclusion) {
                        caseConclusion.textContent = conclusion;
                      }

                      const nextButton = document.createElement("button");
                      nextButton.textContent = stringNextButton;
                      nextButton.className = "next-button adjust-nextButton";
                      nextButton.onclick = () => {
                        divVideoCase.classList.add('d-none');
                        showVideoQuiz(interaction);
                      };
                      const buttonContainer = document.createElement("div");
                      buttonContainer.className = "button-next-container";
                      buttonContainer.appendChild(nextButton);
                      divVideoCase.appendChild(buttonContainer);  
                    }
                      
                  } else if (interactionType === "quiz") {
                      showVideoQuiz(interaction);
                    } 
                  
                }
              }
           });
          };

          let intervalId = setInterval(checkTime, 100)

          video.addEventListener('pause', function() {
            clearInterval(intervalId);
          });

          video.addEventListener('play', function() {
            clearInterval(intervalId); // Limpa qualquer intervalo anterior
            videoInteractiveOverlay.classList.add('d-none');  
            intervalId = setInterval(checkTime, 100); // Reinicia o intervalo
          });

          video.addEventListener('ended', function() {
            clearInterval(intervalId);
            reviewVideo.classList.remove('d-none');
            // Remova a classe 'd-none' do próprio botão
            reviewVideo.classList.remove('d-none');
            document.getElementById('videoOverlay').classList.remove('d-none');

            const texts = checkLanguage(0);

            if(${!showVideoTogether && project.hasAssessment}){
              answerAssessment.classList.remove('d-none');
              answerAssessment.innerHTML = texts.stringAnswerButton;
              reviewVideo.classList.remove('d-none');
              reviewVideo.innerHTML = texts.stringReviewVideo;

            }
            if(${!project.hasAssessment}){
              finishCourse.classList.remove('d-none');
              finishCourse.textContent = texts.stringFinishCourse;
              reviewVideo.innerHTML = texts.stringReviewVideo;
            }

            if (safari) {
              var controls = video.controls;
              video.controls = false;
              video.addEventListener('play', function() {
              video.controls = controls;
            });
           }
          });

          reviewVideo.addEventListener("click", () => {
            showAssessment.classList.add("d-none");
            reviewVideo.classList.add("d-none");
            finishCourse.classList.add('d-none');
            document.getElementById('videoOverlay').classList.add('d-none');
            video.currentTime = 0;
            video.play();
          });

          finishCourse.addEventListener("click", () => {
            if (destiny === "scorm12") {
              const completionStatus = 'completed';
              doLMSSetValue('cmi.core.lesson_status', completionStatus);
              doLMSCommit();
            } else if (destiny === "scorm2004"){
                const completionStatus = 'completed';
                ScormProcessSetValue('cmi.completion_status', completionStatus);
                doLMSCommit();
            }
            window.close();
          });
        }

        if(${!project.showSlides}){
          topBarProject.classList.remove('d-none');
        }

        if(showAssessment){
          showAssessment.addEventListener("click", () => {
            topBarProject.classList.remove("d-none");
            topBarProject.classList.add("fadeIn");
            allAssessment.classList.remove("d-none");
            showAssessment.classList.add("d-none");
            allAssessment.classList.add("fadeIn");
            
            if(carouselSlides){
              scalingContainer.classList.add("fadeOut");
              setTimeout(hidePPt, 1000)
            }
            if(videoContainer){
              videoContainer.classList.add("d-none");
            }
          })
        }

        function hidePPt () {
          scalingContainer.classList.add("d-none");
          scaleContent();
        }

        function createHyperlinks(text) {
          const urlPattern = /https?:\\/\\/[^\\s)]+/g;
          return text.split("\\n").map((line, index) => {
            const parts = line.split(urlPattern);
            const matches = line.match(urlPattern) || [];
            const elements = [];
            parts.forEach((part, i) => {
            elements.push(part);
            if (matches[i]) {
              elements.push('<a href="' + matches[i] + '" target="_blank" rel="noopener noreferrer">' + matches[i] + '</a>');
            }
          });
          return elements.join('');
        }).join('<br>');
      };

        function showVideoQuiz (interaction) {
            const divQuiz = document.getElementById('divQuiz');
            quizQty = interaction.questions.length;
            var questionNumber = 0;
            var userAnswers = [];
            var questions = [];
            var resultAnswers = [];

            if(divQuiz){
                const resultTitle = document.getElementById('resultTitle');
                if (resultTitle) {
                  resultTitle.textContent = checkLanguage(0).stringResultQuiz;
                }
                divQuiz.classList.remove('d-none');
                const quizAskQuestion = document.getElementById("quizAskQuestion");
                const divOpt1 = document.getElementById("divOpt1")
                const divOpt2 = document.getElementById("divOpt2")
                const divOpt3 = document.getElementById("divOpt3")
                const divOpt4 = document.getElementById("divOpt4")
                const divOpt5 = document.getElementById("divOpt5")
                quizAskQuestion.textContent = interaction.questions[questionNumber].ask_question;
                const nextOptions = interaction.questions[questionNumber].alternatives;
                var alternatives = 0
                nextOptions.forEach((option, index) => {
                    alternatives = alternatives + 1
                    const divName = ${`\`divOpt\${alternatives}\``};
                    document.getElementById(divName).classList.remove('d-none');
                    const radioInput = document.getElementById(${`\`option\${index + 1}\``});
                    const radioLabel = document.querySelector(${`\`label[for=option\${index + 1}]\``});
                    if (radioInput && radioLabel) {
                      radioInput.value = option.option;
                      radioLabel.textContent = option.text
                    }
                })

                const sendButton = document.createElement("button");
                sendButton.textContent = stringSendButton;
                sendButton.className = "close-button";   
                sendButton.onclick = () => {
                const selectedOption = document.querySelector('input[name="quizOption"]:checked');
                if (selectedOption) {
                  questions.push(interaction.questions[questionNumber].ask_question)
                  userAnswers.push(selectedOption.value);
                  const isCorrect = interaction.questions[questionNumber].correct_answer === selectedOption.value;
                  resultAnswers.push(isCorrect);
                }

                questionNumber = questionNumber + 1;
                divOpt1.classList.add('d-none');
                divOpt2.classList.add('d-none');
                divOpt3.classList.add('d-none');
                divOpt4.classList.add('d-none');
                divOpt5.classList.add('d-none');

                document.querySelectorAll('input[name="quizOption"]').forEach(input => input.checked = false);    
                if (questionNumber < quizQty) {
                    quizAskQuestion.textContent = interaction.questions[questionNumber].ask_question;
                    divQuiz.classList.remove('d-none');
                    const nextOptions = interaction.questions[questionNumber].alternatives;
                    var alternatives = 0
                    nextOptions.forEach((option, index) => {
                        alternatives = alternatives + 1
                        const divName = ${`\`divOpt\${alternatives}\``};
                        document.getElementById(divName).classList.remove('d-none');
                        const radioInput = document.getElementById(${`\`option\${index + 1}\``});
                        const radioLabel = document.querySelector(${`\`label[for=option\${index + 1}]\``});

                        if (radioInput && radioLabel) {
                            radioInput.value = option.option;
                            radioLabel.textContent = option.text;
                        }
                        })} else {
                           const divQuizResults = document.getElementById('divQuizResults');
                           const quizResult = document.getElementById('quizResult'); 
                           divQuizResults.classList.remove('d-none');
                           divQuiz.classList.add('d-none');
                           const sendButton = document.querySelector(".close-button");
                           if (sendButton) {
                              sendButton.remove();
                           }
                                
                           totalResult = resultAnswers.filter(Boolean).length;

                           quizResult.textContent = checkLanguage(0).stringCorrectAnswersQuiz;

                           questions.forEach((question, index) => {
                              const askQuestion = ${`\`askQuestion\${index+1}\``};
                              const resultQuestion = ${`\`resultQuestion\${index+1}\``};
                              const divAskQuestion = document.getElementById(askQuestion);
                              const divResultQuestion = document.getElementById(resultQuestion);
                              divAskQuestion.textContent = question;
                              divResultQuestion.textContent = resultAnswers[index] ? "✅" : "❌" ;
                           })
                          
                           const closeButton = document.createElement("button");
                                closeButton.textContent = stringCloseButton;
                                closeButton.className = "close-button";   
                                closeButton.onclick = () => {
                                  const askQuestions = document.querySelectorAll('[id^="askQuestion"]');
                                  const resultQuestions = document.querySelectorAll('[id^="resultQuestion"]');

                                  askQuestions.forEach(div => {
                                    div.textContent = "";
                                  });

                                  resultQuestions.forEach(div => {
                                    div.textContent = "";
                                  });
                                  quizAskQuestion.askQuestion = "";  
                                  closeButton.remove();   
                                  videoInteractiveOverlay.classList.add('d-none');
                                  divQuiz.classList.add('d-none');  
                                  divQuizResults.classList.add('d-none');
                                  video.play(); 
                                  video.setAttribute('controls', 'controls');
                                };
                                const buttonContainer = document.createElement("div");
                                buttonContainer.className = "button-container";
                                buttonContainer.appendChild(closeButton);
                                divQuizResults.appendChild(buttonContainer);  
                            }  
                        }
                        
                        const buttonContainer = document.createElement("div");
                        buttonContainer.className = "button-container";
                        buttonContainer.appendChild(sendButton);
                        divQuiz.appendChild(buttonContainer); 
                }
        }

        document.addEventListener('DOMContentLoaded', () => {
          /* --- ÍNICIO ADICIONAR CLASSE AO CLICAR NA <LABEL> --- */
          const questionFeedbackDivs = document.querySelectorAll('.question-feedback');

          questionFeedbackDivs.forEach(questionFeedbackDiv => {
            const labels = questionFeedbackDiv.querySelectorAll('label');
            
            labels.forEach(label => {
              label.addEventListener('click', () => {
                labels.forEach(l => l.classList.remove('selected_alnl'));
                label.classList.add('selected_alnl');
              });
            });
          });
        });
        /* --- FIM ADICIONAR CLASSE AO CLICAR NA <LABEL> --- */

        /* --- INICIO SCROLL PARA RESULTADOS --- */
        const scrollToForm = () => {
          const formElement = document.querySelector('form');
          const offsetTop = formElement.offsetTop - 10 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Subtrai 20em da posição vertical do formulário
          const scrollOptions = {
            top: offsetTop,
            behavior: 'smooth'
          };

          window.scrollTo(scrollOptions);
        };
        /* --- FIM SCROLL PARA RESULTADOS --- */

        /* INÍCIO ESCONDE CASO NÃO TENHA ADICIONADO TEXTO INICIAL */
        document.addEventListener('DOMContentLoaded', function() {
          const mensagemDiv = document.querySelector('.mensagem');
        
          if (mensagemDiv && mensagemDiv.innerHTML.trim() === '') {
            mensagemDiv.style.display = 'none';
          }
        });
        /* FIM ESCONDE CASO NÃO TENHA ADICIONADO TEXTO INICIAL */

        /* --- ÍNICIO FECHAR AO CLICAR EM .RESULTS --- */
        const resultsDiv = document.querySelector('.results');
        if(resultsDiv){
          resultsDiv.addEventListener('click', () => {
            window.close();
          });
          resultsDiv.style.cursor = 'pointer';
        }
        /* --- ÍNICIO FECHAR AO CLICAR EM .RESULTS --- */
        
        const destiny = ${'"' + project.projectDestiny + '"'};
        const conclusion = ${'"' + project.conclusion + '"'};

        const language = ${'"' + project.language + '"'};
        const method = ${'"' + project.method + '"'};
        const passedScore = ${'"' + project.score + '"'};
        let stringResults;
        let stringClose;
        let stringRight;
        let stringWrong;
        let stringSendButton;
        let stringNextButton;
        let stringAnswerButton;
        let stringReviewVideo;
    
        if(destiny === "scorm12" || destiny === "scorm2004" ){
          loadPage(); 
          window.addEventListener("unload", unloadPage)
          window.addEventListener("beforeunload", unloadPage)
        }
    
        const assessment = ${JSON.stringify(project.assessment)};
        const totalQuestions = ${project.assessment.length}
        let totalCorrect = 0;
        const form = document.querySelector('form');
        const submitButton = document.getElementById('submitButton');
        let totalResult = 0
        let quizQty = 0
        
        if (form) {
          form.addEventListener('submit', (event) => {
            event.preventDefault();
            checkAnswers();
            const mensagemDiv = document.querySelector('.mensagem');
            if (mensagemDiv) {
              mensagemDiv.style.display = 'none';
            }
            scrollToForm();
          });
        }
    
        const checkAnswers = () => {
          assessment.forEach((question, index) => {
            const userAnswer = getSelectedAnswer('answer' + index);
            const correctAnswer = question.correct_answer;
            const feedback = question.feedback;
            displayFeedback(index, userAnswer, correctAnswer, feedback);
          });
          submitButton.classList.add('display-none');
          /* INICIO DESATIVA ALTERNATIVAS DEPOIS DE CLICAR EM ENVIAR*/
          const labels = document.querySelectorAll('label');
          labels.forEach(label => {
            label.style.pointerEvents = 'none';
          });
          /* FIM DESATIVA ALTERNATIVAS DEPOIS DE CLICAR EM ENVIAR*/
          setTimeout(displayFinalResult, 100);
        };
    
        const getSelectedAnswer = (name) => {
          const input = document.querySelector('input[name="' + name + '"]:checked');
          return input ? input.value : null;
        };
    
        / INICIO Estrutura do Feedback /
        const displayFeedback = (questionIndex, userAnswer, correctAnswer, feedback) => {
          checkLanguage(0);
          const feedbackDiv = document.getElementById('feedbackDiv' + questionIndex);
        
          const feedbackText = document.createElement('p');
          feedbackText.textContent = feedback;
        
          if (userAnswer === correctAnswer) {
            const correctFeedback = document.createElement('h3');
            correctFeedback.textContent = stringRight;
            feedbackDiv.classList.add('correct-answer');
            totalCorrect = totalCorrect + 1;
            feedbackDiv.appendChild(correctFeedback);
          } else {
            const incorrectFeedback = document.createElement('h3');
            incorrectFeedback.textContent = stringWrong;
            feedbackDiv.classList.add('incorrect-answer');
            feedbackDiv.appendChild(incorrectFeedback);
          }
        
          feedbackDiv.appendChild(feedbackText);
        };
        / FIM Estrutura do Feedback /
    
        const displayFinalResult = () => {
          const score = (totalCorrect / totalQuestions) * 100;
          checkLanguage(score);
          const feedbackAssessment = document.getElementById('feedbackAssessment');
          const feedbackText = document.createElement('p');
          feedbackText.innerHTML = stringResults;
          
          const h5Element = document.createElement('h5');
          h5Element.textContent = stringClose;
          
          feedbackAssessment.appendChild(feedbackText);
          feedbackAssessment.appendChild(h5Element);
          feedbackAssessment.classList.add('display-block');

          feedbackAssessment.scrollIntoView({ behavior: "smooth" });
          if (destiny === "scorm12") {
            if(conclusion === "conclusion"){
              const completionStatus = 'completed';
              doLMSSetValue('cmi.core.score.raw', score.toString());
              doLMSSetValue('cmi.core.lesson_status', completionStatus);
            } else {
              if(method === "passed/failed"){
                const successStatus = score >= passedScore ? 'passed' : 'failed';
                doLMSSetValue('cmi.core.score.raw', score.toString());
                doLMSSetValue('cmi.core.lesson_status', successStatus);
              } else {
                const completionStatus = score >= passedScore ? 'completed' : 'incomplete';
                doLMSSetValue('cmi.core.score.raw', score.toString());
                doLMSSetValue('cmi.core.lesson_status', completionStatus);
              }
            } 
            doLMSCommit();
          } else if (destiny === "scorm2004"){
            if(conclusion === "conclusion"){
              ScormProcessSetValue('cmi.score.raw', score.toString())
              const completionStatus = 'completed';
              ScormProcessSetValue('cmi.completion_status', completionStatus)
            } else {
              if(method === "passed/failed"){
                const successStatus = score >= passedScore ? 'passed' : 'failed';
                const completionStatus = score >= passedScore ? 'completed' : 'incomplete';
                const scoreString = score.toString()
                ScormProcessSetValue('cmi.score.raw', scoreString)
                ScormProcessSetValue('cmi.score.scaled', score / 100)
                ScormProcessSetValue('cmi.success_status', successStatus)
                ScormProcessSetValue('cmi.completion_status', completionStatus)
              } else {
                const successStatus = score >= passedScore ? 'passed' : 'failed';
                const completionStatus = score >= passedScore ? 'completed' : 'incomplete';
                const scoreString = score.toString()
                ScormProcessSetValue('cmi.score.raw', scoreString)
                ScormProcessSetValue('cmi.score.scaled', score / 100)
                ScormProcessSetValue('cmi.success_status', successStatus)
                ScormProcessSetValue('cmi.completion_status', completionStatus)
              }
            }
            doLMSCommit();
          }
        }

        const checkBookmarking = () => {
          var bookmark = doLMSGetValue("cmi.location"); 
        }

        const checkLanguage = (score) => {
          switch(language) {
            case "Portuguese":
              stringResults = "Você acertou " + totalCorrect  + " de " + totalQuestions + " questões e seu aproveitamento foi de "  + score.toFixed(2) +  "%";
              stringClose + "Revise o resultado da sua avaliacão abaixo ou clique aqui para fechar.";
              stringRight = "Você acertou!";
              stringWrong = "Resposta incorreta.";
              stringSendButton = "Enviar";
              stringNextButton = "Seguinte";
              stringAnswerButton = "Responder Avaliação";
              stringReviewVideo = "Rever Vídeo";
              stringFinishCourse = "Finalizar Curso";
              stringCloseButton = "Fechar";
              stringSummary = "Resumo";
              stringResultQuiz = "Resultado do Quiz";
              stringCorrectAnswersQuiz = "Você acertou " + totalResult + " de "+ quizQty + " questões";
              stringCaseIntro = "Introdução";
              stringCaseDescription = "Descrição";
              stringCaseContext = "Contexto do Problema";
              stringCaseChallenge = "Desafio";
              stringCaseAnalysis = "Análise do Problema";
              stringCaseSolution = "Solução Proposta";
              stringCaseImplementation = "Implementação";
              stringCaseResult = "Resultado e Impactos";
              stringCaseLearning = "Lições e Aprendizados";
              stringCaseConclusion = "Conclusão";
              stringVideoCase = "Case de Aplicação";
              stringKnowMore = "Para saber mais";
              break;
            case "English":
              stringResults = "You got "  + totalCorrect  + " out of " + totalQuestions + " questions right and your grade was "  + score.toFixed(2) +  "%";
              stringClose = "Review the result of your assessment below or click here to close.";
              stringRight = "You got it!";
              stringWrong = "Incorrect answer.";
              stringSendButton = "Send";
              stringNextButton = "Next";
              stringAnswerButton = "Answer Assessment";
              stringReviewVideo = "Review Video";
              stringFinishCourse = "Finish Course";
              stringCloseButton = "Close";
              stringSummary = "Summary";
              stringResultQuiz = "Quiz Result";
              stringCorrectAnswersQuiz = "You got " + totalResult + " out of "+ quizQty + " questions";
              stringCaseIntro = "Introduction";
              stringCaseDescription = "Description";
              stringCaseContext = "Problem Context";
              stringCaseChallenge = "Challenge";
              stringCaseAnalysis = "Problem Analysis";
              stringCaseSolution = "Proposed Solution";
              stringCaseImplementation = "Implementation";
              stringCaseResult = "Results and Impacts";
              stringCaseLearning = "Lessons and Learnings";
              stringCaseConclusion = "Conclusion";
              stringVideoCase = "Application Case";
              stringKnowMore = "To learn more";
              break;
            case "Spanish":
              stringResults = "Acertó " + totalCorrect  + " de " + totalQuestions + " preguntas y su calificación fue " + score.toFixed(2) +  "%";
              stringClose = "Revise el resultado de su evaluación a continuación o haga clic aquí para cerrar.";
              stringRight = "¡Acertaste!";
              stringWrong = "Respuesta incorrecta.";
              stringSendButton = "Enviar";
              stringNextButton = "Siguiente";
              stringAnswerButton = "Responda a la Evaluación";
              stringReviewVideo = "Revisar Video";
              stringFinishCourse = "Finalizar Curso";
              stringCloseButton = "Cerrar";
              stringSummary = "Resumen";
              stringResultQuiz = "Resultado del Quiz";
              stringCorrectAnswersQuiz = "Acertaste " + totalResult + " de "+ quizQty + " preguntas";
              stringCaseIntro = "Introducción";
              stringCaseDescription = "Descripción";
              stringCaseContext = "Contexto del Problema";
              stringCaseChallenge = "Desafío";
              stringCaseAnalysis = "Análisis del Problema";
              stringCaseSolution = "Solución Propuesta";
              stringCaseImplementation = "Implementación";
              stringCaseResult = "Resultados e Impactos";
              stringCaseLearning = "Lecciones y Aprendizajes";
              stringCaseConclusion = "Conclusión";
              stringVideoCase = "Caso de Aplicación";
              stringKnowMore = "Para saber más";
              break;
            case "Italian":
              stringResults = "Hai risposto correttamente " + totalCorrect  + " su " + totalQuestions + " e il tuo punteggio è stato del "  + score.toFixed(2) +  "%";
              stringClose = "Rivedi il risultato della tua valutazione qui sotto o clicca qui per chiudere.";
              stringRight = "Hai indovinato!";
              stringWrong = "Risposta errata.";
              stringSendButton = "Inviare";
              stringNextButton = "Seguente";
              stringAnswerButton = "Rispondi alla Valutazione"
              stringReviewVideo = "Rivedi Video";
              stringFinishCourse = "Finire il Corso";
              stringCloseButton = "Chiudi";
              stringSummary = "Riassunto";
              stringResultQuiz = "Risultato del Quiz";
              stringCorrectAnswersQuiz = "Hai risposto correttamente a " + totalResult +  " di " + quizQty + " domande";
              stringCaseIntro = "Introduzione";
              stringCaseDescription = "Descrizione";
              stringCaseContext = "Contesto del Problema";
              stringCaseChallenge = "Sfida";
              stringCaseAnalysis = "Analisi del Problema";
              stringCaseSolution = "Soluzione Proposta";
              stringCaseImplementation = "Implementazione";
              stringCaseResult = "Risultati e Impatti";
              stringCaseLearning = "Lezioni e Apprendimenti";
              stringCaseConclusion = "Conclusione";
              stringVideoCase = "Application Case";
              stringVideoCase = "Caso di Applicazione";
              stringKnowMore = "Per saperne di più";
              break;
            case "French":
              stringResults = "Vous avez répondu correctement à " + totalCorrect  + " questions sur " + totalQuestions  + " et votre score était de " + score.toFixed(2) +  "%";
              stringClose = "Veuillez vérifier le résultat de votre évaluation ci-dessous ou cliquez ici pour fermer.";
              stringRight = "Tu as raison!";
              stringWrong = "Réponse incorrecte.";
              stringSendButton = "Envoyer";
              stringNextButton = "Suivant";
              stringAnswerButton = "Répondez à l'Évaluation";
              stringReviewVideo = "Revoir la Vidéo";
              stringFinishCourse = "Terminer le Cours";
              stringCloseButton = "Fermer";
              stringSummary = "Résumé";
              stringResultQuiz = "Résultat du Quiz";
              stringCorrectAnswersQuiz = "Vous avez répondu correctement à " + totalResult + " sur " + quizQty + " questions";
              stringCaseIntro = "Introduction";
              stringCaseDescription = "Description";
              stringCaseContext = "Contexte du Problème";
              stringCaseChallenge = "Défi";
              stringCaseAnalysis = "Analyse du Problème";
              stringCaseSolution = "Solution Proposée";
              stringCaseImplementation = "Mise en Œuvre";
              stringCaseResult = "Résultats et Impacts";
              stringCaseLearning = "Leçons et Apprentissages";
              stringCaseConclusion = "Conclusion";
              stringVideoCase = "Cas d'Application";
              stringKnowMore = "Pour en savoir plus";
              break;
            case "Germany":
              stringResults = "Sie haben "  + totalCorrect  + " von " + totalQuestions  + " fragen richtig beantwortet und Ihre punktzahl lag bei " + score.toFixed(2) +  "%";
              stringClose = "Überprüfen Sie das Ergebnis Ihrer Bewertung unten oder klicken Sie hier, um zu schließen.";
              stringRight = "Du hast es richtig!";
              stringWrong = "Falsche Antwort.";
              stringSendButton = "Senden";
              stringNextButton = "Weiter";
              stringAnswerButton = "Antworten Sie auf die Bewertung";
              stringReviewVideo = "Video Überprüfen";
              stringFinishCourse = "Kurs Beenden";
              stringCloseButton = "Schließen";
              stringSummary = "Zusammenfassung";
              stringResultQuiz = "Quiz-Ergebnis";
              stringCorrectAnswersQuiz = "Du hast " + totalResult + " von " +  quizQty + "  Fragen richtig beantwortet";
              stringCaseIntro = "Einleitung";
              stringCaseDescription = "Beschreibung";
              stringCaseContext = "Problemkontext";
              stringCaseChallenge = "Herausforderung";
              stringCaseAnalysis = "Problemanalyse";
              stringCaseSolution = "Vorgeschlagene Lösung";
              stringCaseImplementation = "Implementierung";
              stringCaseResult = "Ergebnisse und Auswirkungen";
              stringCaseLearning = "Lektionen und Erkenntnisse";
              stringCaseConclusion = "Fazit";
              stringVideoCase = "Anwendungsfall";
              stringKnowMore = "Um mehr zu erfahren";
              break;
            case "Russian":
              stringResults = "Вы правильно ответили на " + totalCorrect  + " из " + totalQuestions + " вопросов и ваша оценка составила "  + score.toFixed(2) +  "%";
              stringClose = "Пожалуйста, пересмотрите результат вашей оценки ниже или нажмите здесь, чтобы закрыть.";
              stringRight = "Ты правильно ответил!";
              stringWrong = "Неверный ответ.";
              stringSendButton = "Отправить";
              stringNextButton = "Далее";
              stringAnswerButton = "Ответьте на оценку";
              stringReviewVideo = "Просмотреть Видео";
              stringFinishCourse = "Завершить курс";
              stringCloseButton = "Закрыть";
              stringSummary = "Резюме";
              stringResultQuiz = "Результат викторины";
              stringCorrectAnswersQuiz = "Вы правильно ответили на " + totalResult + " из " + quizQty + " вопросов";
              stringCaseIntro = "Введение";
              stringCaseDescription = "Описание";
              stringCaseContext = "Контекст Проблемы";
              stringCaseChallenge = "Задача";
              stringCaseAnalysis = "Анализ Проблемы";
              stringCaseSolution = "Предложенное Решение";
              stringCaseImplementation = "Реализация";
              stringCaseResult = "Результаты и Влияние";
              stringCaseLearning = "Уроки и Опыт";
              stringCaseConclusion = "Заключение";
              stringVideoCase = "Пример применения";
              stringKnowMore = "Узнать больше";
              break;
            case "Mandarin":
              stringResults = "您答對了 " + totalCorrect  + " 題中的 " + totalQuestions  + " 題，得分為 " + score.toFixed(2) +  "%";
              stringClose = "请在下方查看您的评估结果，或点击这里关闭。";
              stringRight = "你答对了！";
              stringWrong = "答案错误。";
              stringSendButton = "发送";
              stringNextButton = "下一步";
              stringAnswerButton = "回答评估";
              stringReviewVideo = "查看视频";
              stringFinishCourse = "完成课程";
              stringCloseButton = "关闭";
              stringSummary = "摘要";
              stringResultQuiz = "测验结果";
              stringCorrectAnswersQuiz = "你答对了"+quizQty+"个问题中的"+totalResult+"个。";
              stringCaseIntro = "介绍";
              stringCaseDescription = "描述";
              stringCaseContext = "问题背景";
              stringCaseChallenge = "挑战";
              stringCaseAnalysis = "问题分析";
              stringCaseSolution = "建议的解决方案";
              stringCaseImplementation = "实施";
              stringCaseResult = "结果和影响";
              stringCaseLearning = "经验和教训";
              stringCaseConclusion = "结论";
              stringVideoCase = "案例分析";
              stringKnowMore = "了解更多";
              break;
            case "Japanese":
              stringResults = totalCorrect + " 中 "  + totalQuestions  + " 問正解し、得点率 " + score.toFixed(2) +  "%";
              stringClose = "以下で評価結果を確認するか、閉じるにはここをクリックしてください。";
              stringRight = "正解です！";
              stringWrong = "不正解です。";
              stringSendButton = "送信する";
              stringNextButton = "次へ";
              stringAnswerButton = "評価に回答";
              stringReviewVideo = "ビデオを見直す";
              stringFinishCourse = "コースを終了する";
              stringCloseButton = "閉じる";
              stringSummary = "概要";
              stringResultQuiz = "クイズの結果";
              stringCorrectAnswersQuiz = "あなたは"+quizQty+"問中"+totalResult+"問正解しました。";
              stringCaseIntro = "紹介";
              stringCaseDescription = "説明";
              stringCaseContext = "問題の背景";
              stringCaseChallenge = "課題";
              stringCaseAnalysis = "問題の分析";
              stringCaseSolution = "提案された解決策";
              stringCaseImplementation = "実施";
              stringCaseResult = "結果と影響";
              stringCaseLearning = "教訓と学び";
              stringCaseConclusion = "結論";
              stringVideoCase = "ケーススタディ";
              stringKnowMore = "もっと詳しく知る";
              break;
            case "Korean":
              stringResults = totalCorrect + " 문제 중 " + totalQuestions  + " 문제를 맞혔고 점수는 " + score.toFixed(2) +  "%" + " 였습니다.";
              stringClose = "평가 결과를 아래에서 확인하거나 닫으려면 여기를 클릭하세요.";
              stringRight = "맞았어요!！";
              stringWrong = "오답입니다.";
              stringSendButton = "보내기";
              stringNextButton = "다음";
              stringAnswerButton = "평가에 응답";
              stringReviewVideo = "비디오 검토";
              stringFinishCourse = "코스 완료";
              stringCloseButton = "닫기";
              stringSummary = "요약";
              stringResultQuiz = "퀴즈 결과";
              stringCorrectAnswersQuiz = "당신은 " + quizQty+"문제 중 "+totalResult+"문제를 맞추셨습니다.";
              stringCaseIntro = "소개";
              stringCaseDescription = "설명";
              stringCaseContext = "문제의 맥락";
              stringCaseChallenge = "도전";
              stringCaseAnalysis = "문제 분석";
              stringCaseSolution = "제안된 해결책";
              stringCaseImplementation = "실행";
              stringCaseResult = "결과 및 영향";
              stringCaseLearning = "교훈 및 학습";
              stringCaseConclusion = "결론";
              stringVideoCase = "사례 연구";
              stringKnowMore = "더 알아보기";
              break;
            default:
              stringResults = "You got "  + totalCorrect  + " out of " + totalQuestions + " questions right and your grade was "  + score.toFixed(2) +  "%";
              stringClose = "Review the result of your assessment below or click here to close.";
              stringRight = "You got it!";
              stringWrong = "Incorrect answer.";
              stringSendButton = "Send";
              stringNextButton = "Next";
              stringAnswerButton = "Answer Assessment";
              stringReviewVideo = "Review Video";
              stringFinishCourse = "Finish Course";
              stringCloseButton = "Close";
              stringSummary = "Summary";
              stringResultQuiz = "Quiz Result";
              stringCorrectAnswersQuiz = "You got " + totalResult + " out of "+ quizQty + " questions";
              stringCaseIntro = "Introdução";
              stringCaseDescription = "Description";
              stringCaseContext = "Problem Context";
              stringCaseChallenge = "Challenge";
              stringCaseAnalysis = "Problem Analysis";
              stringCaseSolution = "Proposed Solution";
              stringCaseImplementation = "Implementation";
              stringCaseResult = "Results and Impacts";
              stringCaseLearning = "Lessons and Learnings";
              stringCaseConclusion = "Conclusion";
              stringVideoCase = "Application Case";
              stringKnowMore = "To learn more";
          }
          return { stringResults, stringClose, stringRight, stringWrong, stringSendButton, stringNextButton, stringAnswerButton, stringReviewVideo, stringFinishCourse, stringCloseButton, stringSummary, stringResultQuiz, stringCorrectAnswersQuiz, stringCaseIntro, stringCaseDescription, stringCaseContext, stringCaseChallenge, stringCaseAnalysis, stringCaseSolution, stringCaseImplementation, stringCaseResult, stringCaseLearning, stringCaseConclusion, stringVideoCase, stringKnowMore };
        };
        const subBut = document.querySelector('submitButton');
        const ansAs = document.querySelector('answerAssessment');
        const revVideo = document.querySelector('reviewVideo');
        const finCourse = document.querySelector('finishCourse');
        if(subBut && ansAs){
          subBut.innerHTML = checkLanguage(0).stringSendButton;
          ansAs.innerHTML = checkLanguage(0).stringAnswerButton;
          revVideo.innerHTML = checkLanguage(0).stringReviewVideo;
        }
        if(finCourse){ 
          revVideo.innerHTML = checkLanguage(0).stringReviewVideo;
          finCourse.innerHTML = checkLanguage(0).stringFinishCourse;
        }

        /* -----  SCALE DO PPT -- INICIO ----- */

        function scaleContent() {
          var windowWidth = window.innerWidth;
          var windowHeight = window.innerHeight;
          var scalingContainer = document.getElementById('scalingContainer');
          var proxConteudo = document.getElementById('proximo-conteudo');
          var contentWrapper = document.querySelector('.content-wrapper');

          var containerWidth = scalingContainer.clientWidth;
          var containerHeight = scalingContainer.clientHeight;

          var scaleRatioWidth = windowWidth / containerWidth;
          var scaleRatioHeight = windowHeight / containerHeight;

          var scaleRatio = (Math.min(scaleRatioWidth, scaleRatioHeight));
          contentWrapper.style.transform = 'scale(' + scaleRatio + ')';

          // Calcula e ajusta o top de proxConteudo
          var offsetY = (containerHeight * (1 - scaleRatio)) / 2;
          contentWrapper.style.top = offsetY + 'px';
          
          proxConteudo.style.top = scalingContainer.offsetTop + scalingContainer.clientHeight + 'px';
          proxConteudo.style.left = "50%";
          proxConteudo.style.transform = "translateX(-50%)";
          proxConteudo.style.marginTop = - scaleRatio + "%"
        }

        if(carouselSlides){
          scaleContent();
          window.addEventListener('resize', scaleContent);
          setTimeout(scaleContent, 1000)
        }

        /* -----  SCALE DO PPT -- FIM ----- */

      </script>
    </body>
  </html>`;
    return html
  };

  const saveHTMLToFirebase = async (project) => {
    const htmlString = generateHTML(project);
    const storageRef = ref(storage, `projects/${project.id}/index.html`);
    await uploadString(storageRef, htmlString, 'raw', { contentType: 'text/html' });
    const url = await getDownloadURL(storageRef);
    return url;
  };

  return { previewHTML, generateHTML, saveHTMLToFirebase }
}
