//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './EditUser.module.css'
//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useLanguageValue } from '../context/LanguageContext';
//Dev Components
import DialogBox from '../components/DialogBox';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';

const EditUser = ({ returnNormalState, selectedUser }) => {

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();

    const [userId] = useState(selectedUser.id)
    const [email, setEmail] = useState(null)
    const [name, setName] = useState(null)
    const [profile, setProfile] = useState(null)
    const [department, setDepartment] = useState(null)
    const [jobTitle, setJobTitle] = useState(null)
    const [userActive, setUserActive] = useState(true)
    const [userPicture, setUserPicture] = useState(null)
    const [filePicture, setFilePicture] = useState(null)
    const [urlUserImage, setUrlUserImage] = useState(null)
    const [companyId, setCompanyId] = useState(selectedUser.company_id)
    const [createDate, setCreateDate] = useState(null)
    const [firstAccess, setFirstAccess] = useState(null)
    const [lastAccess, setLastAccess] = useState(null)
    const [courseAuthorship, setCourseAuthorship] = useState(selectedUser.course_authorship || false)
    const [uploadingFile, setUploadingFile] = useState(false);
    const [msgDialog, setMsgDialog] = useState('');
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [action, setAction] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const { document: userInfos, loading: loadinguserInfos } = useFetchDocument("Users", userId)
    const { updateDocument, responseUpdate } = useUpdateDocument("Users", companyId)
    const { updateDocument: updateDocCompanyUser, responseUpdate: responseUpdateDocCompanyUser } = useUpdateDocument("Companys", userId)
    const { updateDocument: updateEnrollUser, responseUpdate: responseUpdateEnrollUser } = useUpdateDocument("Companys", "")


    const handleCloseWindow = () => {
        returnNormalState()
    }

    const handleSaveUserInfos = () => {
        setMsgDialog("Updating user information...");
        setOptionsDialog([]);
        setAction('processing');
        setOpenDialog(true);
        if (filePicture) {
            handleUpdateUserPicture(filePicture)
        } else {
            handleUpdateUser()
        }
    }

    const handleUpdateUserPicture = async (file) => {
        setUploadingFile(true);

        const forcedFileName = 'thumbnail';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {
                const maxSize = 200 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 200Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                setUploadingFile(true);
                const storageRef = ref(storage, `users/${userId}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlUserImage(url);
                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            setUploadingFile(false);
            console.error('Erro geral:', error);
        }
    }

    const handleUpdateUser = async () => {

        if (userActive != userInfos.user_active) {
            const userInfosUpdate = {
                id: userId,
                company_id: companyId,
                user_active: userActive === "true" ? true : false,
            }
            const resDoc = updateDocCompanyUser(userInfosUpdate);
            await toggleUserActiveStatus(userId, userActive === "true" ? false : true);
        }
        const userInfosUpdate = {
            domain: userId,
            email: email,
            name: name,
            profile: profile,
            department: department,
            job_title: jobTitle,
            user_active: userActive,
            url_user_image: urlUserImage,
            user_author: courseAuthorship,
        }
        console.log("userInfosUpdate ", userInfosUpdate)
        const resDoc = updateDocument(userInfosUpdate);
        if (filePicture) {
            const userInfosUpdate = {
                id: userId,
                url_user_image: urlUserImage,
                company_id: companyId,
                user_active: userActive,
            }
            const resDoc = updateEnrollUser(userInfosUpdate);
        }

        returnNormalState()
    }

    const toggleUserActiveStatus = async (userId, isActive) => {
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/toggleUserAccount?uid=${userId}&active=${isActive}`, {
                method: 'POST',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('User status updated successfully');
            // Optionally, you can refresh the user list or update the state here
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    const actionCloseDialogBox = (f, option) => {
        setAction("");
        setOpenDialog(false);
        setMsgDialog("")
        setOptionsDialog([])
    }

    useEffect(() => {
        if (userInfos) {
            setEmail(userInfos.email)
            setName(userInfos.name)
            setProfile(userInfos.profile)
            setDepartment(userInfos.department)
            setJobTitle(userInfos.job_title)
            setUserActive(userInfos.user_active)
            setUrlUserImage(userInfos.url_user_image)
            setCreateDate(userInfos.creation_date)
            setCourseAuthorship(userInfos.user_author || false)
            setFirstAccess(selectedUser.first_access)
            setLastAccess(selectedUser.last_access)
        }
    }, [userInfos])

    useEffect(() => {
        if (urlUserImage && urlUserImage !== userInfos.url_user_image) {
            setUploadingFile(false);
            handleUpdateUser();
        }
    }, [urlUserImage])

    const [activeTab, setActiveTab] = useState('informations');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        if (profile && profile === "admin") {
            setCourseAuthorship(true)
        }
    }, [[profile]])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div className={``}>
            <div className="row ">
                <div className={`${styles.iconButton} d-flex align-items-end justify-content-end`}>
                    <i className={`bi bi-box-arrow-left me-4 ${styles.icons}`} onClick={() => handleCloseWindow()}></i>
                </div>
            </div>
            <div className={`ps-5 pb-3`} id="informations">
                {loadinguserInfos
                    ? (
                        <div>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <form className={`${styles.div_informations}`}>
                                <div className="row d-flex">
                                    <div className="mb-3 align-items-center col-auto">
                                        <input type="file" className="d-none" id="userPicture" onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                setFilePicture(file);
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setUserPicture(reader.result);
                                                };
                                                reader.readAsDataURL(file);
                                            } else {
                                                setUserPicture(null);
                                            }
                                        }} />
                                        <label htmlFor="userPicture" className="me-3">
                                            {userPicture ? (
                                                <img src={userPicture} alt="User Profile" className={`img-thumbnail rounded-circle ${styles.profilePicture}`} style={{ width: '150px', height: '150px', objectFit: 'cover', cursor: 'pointer' }} />
                                            ) : urlUserImage ? (
                                                <img src={urlUserImage} alt="User Profile" className={`img-thumbnail rounded-circle ${styles.profilePicture}`} style={{ width: '150px', height: '150px', objectFit: 'cover', cursor: 'pointer' }} />
                                            ) : (
                                                <div className={`d-flex justify-content-center align-items-center rounded-circle bg-secondary ${styles.profilePicture}`} style={{ width: '150px', height: '150px', cursor: 'pointer', position: 'relative' }}>
                                                    <i className="bi bi-person-fill-add" style={{ fontSize: '50px', color: 'white' }}></i>
                                                </div>
                                            )}
                                        </label>
                                        <div className="flex-grow-1"></div>
                                    </div>
                                    <div className="col-auto d-flex flex-column justify-content-center">
                                        <div className={`${styles.formatTexts}`}>{`Creation date: ${createDate ? createDate.toDate().toLocaleDateString() : 'N/A'}`}</div>
                                        <div className={`${styles.formatTexts}`}>{`First access: ${firstAccess ? firstAccess.toDate().toLocaleDateString() : 'N/A'}`}</div>
                                        <div className={`${styles.formatTexts}`}>{`Last access: ${lastAccess ? lastAccess.toDate().toLocaleDateString() : 'N/A'}`}</div>
                                    </div>
                                </div>
                                <div className="mb-3 mt-2d-flex align-items-center">
                                    <div className="form-check form-switch">
                                        <input className={`form-check-input me-2${styles.labelStyle}`} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={courseAuthorship} onChange={(e) => setCourseAuthorship(e.target.checked)} />
                                        <label className={`form-check-label ${styles.authorText}`} htmlFor="flexSwitchCheckDefault">
                                            {courseAuthorship ? "Course Author" : "Not Course Author"}
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 d-flex align-items-center">
                                    <label htmlFor="name" className={`form-label me-2 ${styles.labelStyle}`}>Name:</label>
                                    <input type="text" className={`form-control ${styles.inputStyle}`} id="name" value={name || ''} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-3 d-flex align-items-center ">
                                    <label htmlFor="email" className={`form-label me-2 ${styles.labelStyle}`}>Email:</label>
                                    <input type="email" className={`form-control ${styles.inputStyle}`} id="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} disabled />
                                </div>
                                <div className="mb-3 d-flex align-items-center">
                                    <label htmlFor="profile" className={`form-label me-2 ${styles.labelStyle}`}>Profile</label>
                                    <select className={`form-control ${styles.inputStyle}`} id="profile" value={profile || ''} onChange={(e) => setProfile(e.target.value)}>
                                        <option value="student">Student</option>
                                        <option value="companyadmin">Admin</option>
                                    </select>
                                </div>
                                <div className="mb-3 d-flex align-items-center">
                                    <label htmlFor="department" className={`form-label me-2 ${styles.labelStyle}`}>Department:</label>
                                    <input type="text" className={`form-control ${styles.inputStyle}`} id="department" value={department || ''} onChange={(e) => setDepartment(e.target.value)} />
                                </div>
                                <div className="mb-3 d-flex align-items-center">
                                    <label htmlFor="jobTitle" className={`form-label me-2 ${styles.labelStyle}`}>Job Title:</label>
                                    <input type="text" className={`form-control ${styles.inputStyle}`} id="jobTitle" value={jobTitle || ''} onChange={(e) => setJobTitle(e.target.value)} />
                                </div>
                                <div className="mb-3 d-flex align-items-center">
                                    <label htmlFor="userActive" className={`form-label me-2 ${styles.labelStyle}`}>Active:</label>
                                    <select className={`form-control ${styles.inputStyle}`} id="userActive" value={userActive || ''} onChange={(e) => setUserActive(e.target.value)}>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                            </form>
                            <button type="submit" className={`btn btn-primary ${styles.button}`} onClick={handleSaveUserInfos} disabled={uploadingFile} >Save Changes</button>
                        </div>
                    )
                }
            </div>
            {openDialog &&
                <div>
                    <DialogBox
                        action={action}
                        questionDialog={msgDialog}
                        isopen={true}
                    />
                </div>
            }
        </div>
    )
}

export default EditUser;
