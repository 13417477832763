import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Plans.module.css';
import CheckIcon from './CheckIcon';
import CrossIcon from './CrossIcon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DialogBox from '../../components/DialogBox';

import { useLanguageValue } from '../../context/LanguageContext';
import { useAuthValue } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useUpdateDocument } from '../../hooks/useUpdateDocument';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { PaddleSDK } from 'paddle-sdk';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import axios from 'axios';
import { initializePaddle, Paddle } from '@paddle/paddle-js';

//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { ca } from 'date-fns/locale';


const Plans = ({ userSetLanguage }) => {

  let countryIndex = 0;

  let periodo = 'mensal';

  const navigate = useNavigate();

  const { t } = useTranslation(['plans']);
  const { user } = useAuthValue();
  const userEmail = user ? user.email : null;

  const [selectedLanguage, setSelectedLanguage] = useState('pt_br');
  const [mensal, setMensal] = useState(true);
  const [pricePro, setPricePro] = useState('');
  const [periodPro, setPeriodPro] = useState('');
  const [priceBasico, setPriceBasico] = useState('');
  const [periodBasico, setPeriodBasico] = useState('');
  const [pricePremium, setPricePremium] = useState('');
  const [premiumPlan, setPremiumPlan] = useState('Premium 50')
  const [periodPremium, setPeriodPremium] = useState('');
  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
  const [language, setLanguage] = useState("");
  const [userId, setUserId] = useState(user ? user.uid : "");
  const [search, setSearch] = useState();

  const [choicedPlan, setChoicedPlan] = useState("");
  const [planName, setPlanName] = useState("");
  const [actualPlan, setActualPlan] = useState("");
  const [planId, setPlanId] = useState("");
  const [dataStorage, setDataStorage] = useState(0);
  const [projectsLimit, setProjectsLimit] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [paddleUserId, setPaddleUserId] = useState(null);
  const [paddleAlert, setPaddleAlert] = useState(null);
  const [signatureDate, setSignatureDate] = useState(new Date());
  const [nextBirthDate, setNextBirthDate] = useState(new Date());
  const [showPlanInfos, setShowPlanInfos] = useState(false);
  const [keepSubscribe, setKeepSubscribe] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [errorPaddle, setErrorPaddle] = useState("");
  const [status, setStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [dialogOption, setDialogOption] = useState([]);
  const [keepSubscription, setKeepSubscription] = useState(false);
  const [paddle, setPaddle] = useState();
  const [action, setAction] = useState("");
  const [planPaddleId, setPlanPaddleId] = useState("");

  const [projectQty, setProjectQty] = useState(25)
  const [premLimitProjects, setPremLimitProjects] = useState(25);
  const [premAdminUsers, setPremAdminUsers] = useState(2);
  const [premStorageLmt, setPremStorageLmt] = useState(2);
  const [premTransferLmt, setPremTransferLmt] = useState(4);
  const [premTransferPrice, setPremTransferPrice] = useState(0);
  const [premRegisterUsers, setPremRegisterUsers] = useState(50);

  const [choosedPlan, setChoosedPlan] = useState("");
  const [periodPlan, setPeriodPlan] = useState("mensal");


  const { preferenceLanguage } = useLanguageValue();
  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
  //const userLanguage = "en" //ANA MUDAR ESSA LINHA PARA pt ou en ou es

  let planIdChoiced;

  const { document: domain, loadingDomain, errorDomain } = useFetchDocument("Domains", userId);

  const { documents: avaliablePlans, loadingPlans, error } = useFetchProjects("Plans", search);

  const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userId)

  const myModal = document.getElementById('myModal')
  const myInput = document.getElementById('myInput')

  const cancelPaddlePlan = async (subscriptionId) => {

    setUpdatingPlan(true)

    paddle.Spinner.show()
    try {
      const response = await axios.post(
        `https://us-central1-quizzai-4b3cd.cloudfunctions.net/postCancelSubscription?subscriptionId=${subscriptionId}`
      );
    } catch (error) {

      console.error('Erro:', error);
    }

    paddle.Spinner.hide()

  }

  const checkUserPlan = () => {
    domain.status === "deleted" ? setActualPlan("") : setActualPlan(domain.plan);
    setSignatureDate(domain.signature_date);
    setSubscriptionId(domain.subscription_id ? domain.subscription_id : "");
    setStatus(domain.status);
    const nextBillDate = domain.next_bill_date && domain.next_bill_date.toDate();
    const nextBillDateObj = new Date(nextBillDate);
    setNextBirthDate(nextBillDateObj);
    setLoadingPlan(false);
  }

  const handleChoosePlan = (plan, period) => {
    var plancode = "";
    switch (plan) {
      case "Pro-monthly":
        plancode = "pro-monthly";
        break;
      case "Pro-annual":
        plancode = "pro-annual";
        break;
      case "Free":
        plancode = "Free";
        break;
      case "Premium 50":
        if (period === "mensal") {
          plancode = "premium-50-monthly";
        } else {
          plancode = "premium-50-annual";
        }
        break;
      case "Premium 100":
        if (period === "mensal") {
          plancode = "premium-100-monthly";
        } else {
          plancode = "premium-100-annual";
        }
        break;
      case "Premium 200":
        if (period === "mensal") {
          plancode = "premium-200-monthly";
        } else {
          plancode = "premium-200-annual";
        }
        break;
    }


    handleConfirmPlanChange(plan, plancode);
  }


  const handleConfirmPlanChange = (pname, pcode) => {

    setPlanName(pname);

    const planObject = avaliablePlans.find(plan => plan.id === pcode);

    if (planObject) {
      const planid = planObject.plan_paddle_id;
      setPlanId(planid);
    } else {
      return
    }

    setAction("change_plan");

    if (pname === "Free" && actualPlan.includes("trial")) {
      setDataStorage(50);
      setProjectsLimit(3);
      setOpenDialog(true);
      setPlanId("free");
      const textMessage = t(`plans:home.Prosseguir com sua assinatura ao Plano Basic`)
      setMessage(textMessage);
      setDialogOption([t(`plans:home.Sim`), t(`plans:home.Não`)]);
    } else

      if (actualPlan.includes("trial") || actualPlan === "" || actualPlan.includes("free")) {
        setKeepSubscribe(true);
      } else {
        setOpenDialog(true);
        const formattedDate = format(nextBirthDate, 'dd/MM/yyyy');
        const textMessage = `${t(`plans:home.Você tem uma assinatura vigente do plano`)} ${actualPlan} ${t(`plans:home.que vencerá em`)} ${formattedDate}. 
        ${t(`plans:home.Você quer continuar mesmo assim`)}
        ${t(`plans:home.Ao clicar em Sim, você estará realizando uma nova assinatura que substituíra a sua já existente`)}`
        setMessage(textMessage);
        setDialogOption([t(`plans:home.Sim`), t(`plans:home.Não`)]);
      }
  }

  const goRegister = () => {
    window.scrollTo(0, 0);
    navigate("/register")
  }

  const handleClosDialogBox = (option) => {

    setOpenDialog(false);
    if (option === "Sim" || option === "Yes" || option === "Sí") {
      handleUpdateUserPlan();
    }
    if (option === "OK") {
      navigateAccount();
    }
  };

  const handleUpdateUserPlan = async () => {
    setUpdatingPlan(true)

    //await cancelPaddlePlan(subscriptionId);
    if (planName === "Free" && actualPlan.includes("trial")) {
      const newDataFree = {
        coupon: "",
        domain: userId,
        domain_active: true,
        data_storage_limit: dataStorage,
        monthly_project_limit: projectsLimit,
        signature_date: Timestamp.now(),
        ppt_project_limit: 3,
        video_project_limit: 0,
        status: "free",
        plan: "free"
      };
      const resDoc = updateDocument(newDataFree);
      navigateAccount();
    } else {
      try {
        paddle.Spinner.show()
        try {
          const response = await axios.patch(
            `https://us-central1-quizzai-4b3cd.cloudfunctions.net/patchChangeSubscription?subscriptionId=${subscriptionId}&priceId=${planId}`
          );
        } catch (error) {

          console.error('Erro:', error);
        }

        paddle.Spinner.hide()
        setUpdatingPlan(false)
        navigateAccount();
      } catch (error) {
        console.log('Erro:', error);
        setUpdatingPlan(false)
        setErrorPaddle(error);
      }
    }
  }

  const navigateAccount = () => {
    navigate("/seuperfil", { state: { tab: "Detalhes do plano" } })
  }

  const updatePlanValues = (language, isMensal) => {
    const priceValues = {
      'Português': { mensal: 'R$ 69,90', anual: 'R$ 699' },
      'Inglês': { mensal: '$ 24', anual: '$ 240' },
      'Espanhol': { mensal: '$ 24', anual: '$ 240' },
    };

    const premiumValues = {
      "Premium 50": {
        'Português': { mensal: 'R$ 269,00', anual: 'R$ 2.690,00' },
        'Inglês': { mensal: '$ 69', anual: '$ 690' },
        'Espanhol': { mensal: '$ 69', anual: '$ 690' },
      },
      "Premium 100": {
        'Português': { mensal: 'R$ 369,00', anual: 'R$ 3.690,00' },
        'Inglês': { mensal: '$ 94', anual: '$ 940' },
        'Espanhol': { mensal: '$ 94', anual: '$ 940' },
      },
      "Premium 200": {
        'Português': { mensal: 'R$ 469,00', anual: 'R$ 4.690,00' },
        'Inglês': { mensal: '$ 124', anual: '$ 1.240' },
        'Espanhol': { mensal: '$ 124', anual: '$ 1.240' },
      }
    }

    const periodValues = {
      'Português': { mensal: 'por mês', anual: 'por ano' },
      'Inglês': { mensal: 'per month', anual: 'per year' },
      'Espanhol': { mensal: 'por mes', anual: 'por año' },
    };

    const selectedPrice = isMensal ? priceValues[language].mensal : priceValues[language].anual;
    const selectedPremiumPrice = isMensal ? premiumValues[premiumPlan][language].mensal : premiumValues[premiumPlan][language].anual;
    const selectedPeriod = periodValues[language][isMensal ? 'mensal' : 'anual'];

    setPricePro(selectedPrice);
    setPriceBasico(language === 'pt_br' ? 'R$ 0' : '$ 0');
    setPricePremium(selectedPremiumPrice)
    setPeriodPro(selectedPeriod);
    setPeriodBasico(selectedPeriod);
  };

  const togglePlan = event => {
    if (periodo == 'mensal') {
      setMensal(true);
      updatePlanValues(language, true);
    } else {
      setMensal(false);
      updatePlanValues(language, false);
    }
  };

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (preferenceLanguage && preferenceBrowserLanguage) {
      setLanguage(user ? preferenceLanguage : preferenceBrowserLanguage)
    }
  }, [preferenceLanguage, preferenceBrowserLanguage])

  useEffect(() => {
    language && updatePlanValues(language, mensal);

  }, [language, mensal, premiumPlan]);

  useEffect(() => {
    if (premiumPlan) {
      switch (premiumPlan) {
        case "Premium 50":
          setProjectQty(25)
          setPremAdminUsers(2)
          setPremLimitProjects(25)
          setPremRegisterUsers(50)
          setPremStorageLmt("2 Gb")
          setPremTransferLmt("4 Gb")
          setPremTransferPrice(0)
          break
        case "Premium 100":
          setProjectQty(30)
          setPremAdminUsers(2)
          setPremLimitProjects(30)
          setPremRegisterUsers(100)
          setPremStorageLmt("2.5 Gb")
          setPremTransferLmt("6 Gb")
          setPremTransferPrice(0)
          break
        case "Premium 200":
          setProjectQty(35)
          setPremAdminUsers(3)
          setPremLimitProjects(35)
          setPremRegisterUsers(200)
          setPremStorageLmt("3 Gb")
          setPremTransferLmt("8 Gb")
          setPremTransferPrice(0)
          break
      }
    }
  }, [premiumPlan])

  useEffect(() => {
    if (domain) {
      console.log("Domain: ", domain)
      checkUserPlan();
      domain.paddle_alert && setPaddleAlert(domain.paddle_alert)
    }
  }, [domain])

  useEffect(() => {
    if (keepSubscribe && !updatePlan) {

      if (planName === "Free" && !actualPlan.includes("trial")) {
        document.querySelector(".subscribe_basic").click();
      } else {
        console.log("Plan Id: ", planId)
        openCheckout(planId)
      }
/*
      if (planName === "Pro-monthly") {
        document.querySelector(".subscribe_pro").click();
      } else if (planName === "Pro-annual") {
        document.querySelector(".subscribe_annual").click();
      } else if (planName === "Free" && !actualPlan.includes("trial")) {
        document.querySelector(".subscribe_basic").click();
      }
*/
      setKeepSubscribe(false);
    }
  }, [keepSubscribe])

  useEffect(() => {
    if (errorPaddle) {
      setOpenDialog(true);
      const textMessage = textMessage[69];
      setMessage(textMessage);
      setDialogOption(["OK"]);
    }
  }, [errorPaddle])

  /* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
  const fadeInElements = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  /* --- FIM DA ANIMAÇÃO POR SCROLL --- */

  /* --- INICIO SUMMARY IGUALAR ALTURA --- */
  const [maxSummaryHeight, setMaxSummaryHeight] = useState(0);

  useEffect(() => {
    function updateMaxSummaryHeight() {
      const basicSummary = document.getElementById('Basico-summary');
      const proSummary = document.getElementById('Pro-summary');
      const premiumSummary = document.getElementById('Premium-summary');
      const maxHeight = Math.max(basicSummary.scrollHeight, proSummary.scrollHeight, premiumSummary.scrollHeight);
      setMaxSummaryHeight(maxHeight);
    }

    updateMaxSummaryHeight();
    window.addEventListener('resize', updateMaxSummaryHeight);

    return () => {
      window.removeEventListener('resize', updateMaxSummaryHeight);
    };
  }, []);
  /* --- FIM SUMMARY IGUALAR ALTURA --- */

  /* --- INICIO BTNS MENSAL E ANUAL --- */
  const [activeButton, setActiveButton] = useState('mensal');

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    periodo = buttonType;
    setPeriodPlan(periodo);
    togglePlan(buttonType);
  };
  /* --- FIM BTNS MENSAL E ANUAL --- */

  /* --- INICIO --- adicionar para retirar o padding do container parent da página */
  useEffect(() => {
    const capsula = document.querySelector('.container');
    if (capsula) {
      capsula.classList.add('p-0');
    }
  }, []);
  /* --- FIM --- adicionar para retirar o padding do container parent da página */

  const openCheckout = (priceid) => {
    console.log("priceid: ", priceid);
    
    if (!paddle) {
      console.error("Paddle SDK not initialized");
      return;
    }
    
    try {
      paddle.Checkout.open({
        items: [{ 
          priceId: priceid, 
          quantity: 1 
        }],
        customer: {
          email: userEmail || ''
        },
        theme: 'light',
        successUrl: window.location.origin + '/seuperfil?checkout=success',
        closeCallback: () => console.log('Checkout closed')
      });
    } catch (error) {
      console.error("Error opening Paddle checkout:", error);
    }
  };

  useEffect(() => {
    initializePaddle({
      environment: 'production',
      token: 'live_6ce236a27e4afee48087efabc40' 
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
        console.log("Paddle initialized successfully");
      } else {
        console.error("Failed to initialize Paddle");
      }
    });
  }, []);

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  useEffect(() => {
    user && preferenceLanguage && setPreferenceBrowserLanguage(preferenceLanguage)
  }, [preferenceLanguage, user])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <div className={`${styles.div_choosefile}`}>
      <HelmetProvider>
        <Helmet>
          <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
          <script>{`Paddle.Setup({ 
                    seller: 177179, 
                    eventCallback: function(data) {
                      if (data.name === "checkout.completed") {
                        console.log("Data name: " + data);
                        navigateAccount();
                      }}
                    })`}
          </script>
        </Helmet>
        <a href="#" className="paddle_button subscribe_button d-none" data-customer-email={userEmail} data-items={`[{"priceId": ${planId},
      "quantity": 1}]`} >Subscribe now</a>
        <a href="#" className="paddle_button subscribe_basic d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01hcb1k61e29tr22j1a8w5nz0e",
      "quantity": 1}]`} >Subscribe now</a>
        <a href="#" className="paddle_button subscribe_pro d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01haw3tsnx1hsewphcdggtpqbk",
      "quantity": 1}]`} >Compre agora</a>
        <a href="#" className="paddle_button subscribe_annual d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01haw3w97tca8rkr4n8hv8p29t",
      "quantity": 1}]`} >Compre agora</a>

        {updatingPlan
          ? <div className="d-flex justify-content-center mt-5">
            <div className="align-self-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t('plans:home.Comece agora')}</span>
              </div>
            </div>
          </div>
          : <div className={`${styles.bg}`}>
            <div className={`${styles.area}`}>
              <div className={`row ${styles.titulo}`}>{t('plans:home.Planos e Preços')}</div>
              <div className={`row ${styles.subtitulo}`}> {t('plans:home.Escolha o melhor plano para você')} </div>
              <section className={`${styles.secao} ${styles.tabela} row align-items-center justify-content-center`}>
                <div className={`col-12 col-md-11 ${styles.partes} text-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <div className={`${styles.btn_group}`} role="group" aria-label="Basic example">
                    <button id="mensal-btn" className={`${styles.borderLeft} ${activeButton === 'mensal' ? styles.activeButton : styles.inactiveButton}`} onClick={() => handleButtonClick('mensal')}>{t('plans:home.Mensal')}</button>
                    <button id="anual-btn" className={`${styles.borderRight} ${activeButton === 'anual' ? styles.activeButton : styles.inactiveButton}`} onClick={() => handleButtonClick('anual')}>{t('plans:home.Anual')}</button>
                  </div>
                  <table className={`${styles.table_} ${styles.table_bordered}  ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                    <thead>
                      <tr>
                        <th className={`${styles.categoria} ${styles.bordaZ2}`}></th>
                        <th id="Basico" className={`${styles.planos} ${styles.bordaZ2}`}>
                          <h2 className='mt-2'>{t('plans:home.Basic')}</h2>
                          <div className={`row ${styles.rowPremiumBtns}`}></div>
                          <summary id="Basico-summary" className={`mt-1`} style={{ height: maxSummaryHeight + 'px' }}>
                            {t('plans:home.Perfeito para projetos pessoais. Não é necessário cartão de crédito')}
                          </summary>
                          <h1>{priceBasico}</h1>
                          <p className={`${styles.medida}`}>{periodBasico}</p>
                          {actualPlan === "free"
                            ? <div className={`${styles.yourplan}`}>{t('plans:home.Seu plano')}</div>
                            : <a href="#" onClick={user ? () => handleConfirmPlanChange('Free') : () => { goRegister() }} className={`${styles.btn} ${styles.btn_other} mt-3 ${styles.mt_md_0}`}>
                              {user ? t('plans:home.Assinar') : t('plans:home.Comece agora')}
                            </a>}
                        </th>
                        <th id="Pro" className={`${styles.planos} ${styles.bordaZ2}`}>
                          <h2 className='mt-2'>{t('plans:home.Pro')}</h2>
                          <div className={`row ${styles.rowPremiumBtns}`}></div>
                          <summary id="Pro-summary" className={`mt-1`} style={{ height: maxSummaryHeight + 'px' }}>
                            {t('plans:home.Para profissionais, escolas e empresas')}
                          </summary>
                          <h1>{pricePro}</h1>
                          <p className={`medida`}>{periodPro}</p>
                          {(actualPlan === "pro-monthly" && activeButton === "mensal") || (actualPlan === "pro-annual" && activeButton === "anual")
                            ? <div className={`${styles.yourplan}`}>{t('plans:home.Seu plano')}</div>
                            : <a href="#" onClick={user ? () => handleChoosePlan(activeButton === 'mensal' ? 'Pro-monthly' : 'Pro-annual', periodPlan) : () => { goRegister() }} className={`${styles.btn} ${styles.btn_primary} mb-3 ${styles.mt_md_0}`}>
                              {user ? t('plans:home.Assinar') : t('plans:home.Teste grátis')}
                            </a>}
                        </th>
                        <th id="Premium" className={`${styles.planos} ${styles.bordaZ2}`}>
                          <h2 className='mt-3'>{premiumPlan}</h2>
                          <div className={`row ${styles.rowPremiumBtns}`}>
                            <div className="col-3 ms-2"><button type="button" className={`${styles.btn} ${premiumPlan === "Premium 50" ? styles.btn_primary_selected : styles.btn_primary} ${styles.mt_md_0} ${styles.premiumPlans} ${styles.btn_premium}`} onClick={() => setPremiumPlan("Premium 50")}>50</button></div>
                            <div className="col-3"><button className={`${styles.btn} ${premiumPlan === "Premium 100" ? styles.btn_primary_selected : styles.btn_primary}  ${styles.mt_md_0} ${styles.premiumPlans} ${styles.btn_premium}`} onClick={() => setPremiumPlan("Premium 100")}>100</button></div>
                            <div className="col-3 me-2"><button className={`${styles.btn} ${premiumPlan === "Premium 200" ? styles.btn_primary_selected : styles.btn_primary} ${styles.mt_md_0} ${styles.premiumPlans} ${styles.btn_premium}`} onClick={() => setPremiumPlan("Premium 200")}>200</button></div>
                          </div>
                          <summary id="Premium-summary" className={`mt-1`} style={{ height: maxSummaryHeight + 'px' }}>
                            {t('plans:home.Para small business learn hub')}
                          </summary>
                          <h1>{pricePremium}</h1>
                          <p className={`${styles.medida}`}>{periodBasico}</p>
                          {(actualPlan === "pro-monthly" && activeButton === "mensal") || (actualPlan === "pro-annual" && activeButton === "anual")
                            ? <div className={`${styles.yourplan}`}>{t('plans:home.Seu plano')}</div>
                            : <a href="#" onClick={user
                              ? () => handleChoosePlan(premiumPlan, periodPlan)
                              : () => { goRegister() }} className={`${styles.btn} ${styles.btn_primary} mb-3 ${styles.mt_md_0}`}>
                              {user ? t('plans:home.Assinar') : t('plans:home.Teste grátis')}
                            </a>}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn} `} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Funcionalidades gerais')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} `}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3} `}>{t('plans:home.Converter um arquivo PowerPoint em HTML')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3} `}><CheckIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3} `}><CheckIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3} `}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3} `}>{t('plans:home.Converter um arquivo PowerPoint em SCORM')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Criar perguntas com base no conteúdo do PowerPoint usando IA')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Converter um arquivo de vídeo em SCORM')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Adicionar interatividade a um vídeo')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Criar perguntas com base no conteúdo do vídeo usando IA')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Gerar legendas sincronizadas para um vídeo (fala-para-texto)')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}>{t('plans:home.Revisar e editar as legendas geradas para um vídeo')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Traduzir as legendas de um vídeo para 10 idiomas diferentes usando IA')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>

                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Criar perguntas e questionários com base em um assunto usando IA')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Inserir suas próprias perguntas e questionários')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Criar ou traduzir perguntas em 10 idiomas')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Copiar seu texto e gerar automaticamente perguntas sobre ele')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Gerar um texto ou caso sobre qualquer assunto e criar perguntas sobre ele usando IA')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Opções de formato de exportação')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} `}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}>{t('plans:home.SCORM 1.2 ou 2004')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CheckIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.HTML (arquivo ZIP)')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Word (inclui formato Aiken para Moodle e formato GIFT)')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>

                    </tbody>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{"Learn Hub"}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} `}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}>
                          <tr className={`p-0 m-0`}>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellTexts} ${styles.cellSettings} p-0 m-0`}>Premium 50</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellTexts} ${styles.cellSettings} p-0 m-0`}>Premium 100</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.borderRIGHT} ${styles.cellSettings} ${styles.cellTexts} p-0 m-0`}>Premium 200</td>
                          </tr>
                        </td>
                      </tr>

                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Limite de transf de dados')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>4 Gb</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>6 Gb</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>8 Gb</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Preço transf dados')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>0</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>0</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>0</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Autores e adms')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>2</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>2</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>3</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Limite de usuários')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>50</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>100</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>200</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}>{t('plans:home.Publique e hospede')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Customize o hub')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Matricula usuários no curso')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Tracking de usuários')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Notificação email para novos usuários')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div id="tabela_adm" className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Administração e outros')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} text-left`}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}>
                          <tr className={`p-0 m-0`}>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellTexts} ${styles.cellSettings} p-0 m-0`}>Premium 50</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellTexts} ${styles.cellSettings} p-0 m-0`}>Premium 100</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.borderRIGHT} ${styles.cellSettings} ${styles.cellTexts} p-0 m-0`}>Premium 200</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}><span>{t('plans:home.Limite de')} </span>
                          <OverlayTrigger placement="top" overlay={<Tooltip className={`${styles.tooltipCustom}`}>{t('plans:home.Um')} <strong>{t('plans:home.projeto ativo')}</strong> {t('plans:home.é aquele que foi criado, editado, duplicado e/ou exportado no período mensal')}</Tooltip>}>
                            <span className={`${styles.tooltip_term}`}>{t('plans:home.projetos ativos')}</span></OverlayTrigger>
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs} ${styles.bordaZ3}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.3 por mês')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr} ${styles.bordaZ3}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span>
                          {t('plans:home.25 por mês',
                            {
                              projectQty: 25,
                              interpolation: { prefix: '${', suffix: '}' }
                            }
                          )}
                        </td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>
                              {t('plans:home.25 por mês',
                                {
                                  projectQty: 25,
                                  interpolation: { prefix: '${', suffix: '}' }
                                }
                              )}
                            </td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>
                              {t('plans:home.25 por mês',
                                {
                                  projectQty: 30,
                                  interpolation: { prefix: '${', suffix: '}' }
                                }
                              )}
                            </td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>
                              {t('plans:home.25 por mês',
                                {
                                  projectQty: 35,
                                  interpolation: { prefix: '${', suffix: '}' }
                                }
                              )}
                            </td>
                          </tr>
                        </td>
                      </tr>

                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Limite de armazenamento de arquivos')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.50 MB')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.2 GB')}
                        </td>
                        <td className={`${styles.planos}`}>

                          <tr>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderLEFT} ${styles.cellSettings}`}>2 Gb</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.cellSettings}`}>2.5 Gb</td>
                            <td className={`${styles.borderTOP} ${styles.borderBOTTOM} ${styles.borderRIGHT} ${styles.cellSettings} `}>3 Gb</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Idiomas para o administrador')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Português, Inglês e Espanhol')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Português, Inglês e Espanhol')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Português, Inglês e Espanhol')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Suporte técnico')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs} ${styles.bordaZ} ${styles.brZ}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.FAQ e base de conhecimento aberta')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr} ${styles.bordaZ} ${styles.brZ}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Email (tempo de resposta do suporte 24 horas | dias úteis)')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr} ${styles.bordaZ} ${styles.brZ}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Email (tempo de resposta do suporte 24 horas | dias úteis)')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>

            <section className={`${styles.secao} ${styles.botoesChamadas} row align-items-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <div className={`col-12 text-center`}>
                <h3>{t('plans:home.Você está pronto(a) para começar a usar o QuizAI')}</h3>
              </div>
              <div className={`${styles.botoes} col-12 text-center`}>
                <a href="#" onClick={() => { goRegister() }} className={`btn ${styles.btn_primary} ${styles.adjust_primary}  mb-3 ${styles.mb_md_0}`}>{t('plans:home.Comece agora')}</a>
              </div>
            </section>

            {openDialog &&
              <DialogBox
                isopen={true}
                questionDialog={message}
                optionsDialog={dialogOption}
                action={action}
                actionScript={handleClosDialogBox}
              />
            }
          </div >}
      </HelmetProvider >
    </div>
  )
}

export default Plans