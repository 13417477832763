import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import { updateDoc, doc } from "firebase/firestore";

const initialState = {
    loading: null,
    error: null,
};

const updateReducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
            return { loading: true, error: null };
        case "UPDATED_DOC":
            return { loading: false, error: null };
        case "ERROR":
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const useUpdateDocEnrollment = (docCollection) => {
    const [response, dispatch] = useReducer(updateReducer, initialState);

    // deal with memory leak
    const [cancelled, setCancelled] = useState(false);

    const checkCancelBeforeDispatch = (action) => {
        if (!cancelled) {
            dispatch(action);
        }
    };

    const updateDocEnrollment = async (document) => {

        var documentId = document.company_id;
        const id = document.id;
        var collection = document.collection
        var enrollmentId = document.enrollment_id

        console.log("document ", document);

        checkCancelBeforeDispatch({ type: "LOADING" });

        try {

            const docRef = id
                ? await doc(db, docCollection, documentId, collection, id, "Enrollments", enrollmentId)
                : await doc(db, docCollection, documentId, collection, enrollmentId)

            const updatedDocument = await updateDoc(docRef, document);

            checkCancelBeforeDispatch({
                type: "UPDATED_DOC",
                payload: updatedDocument,
            });
        } catch (error) {
            console.log(error);
            checkCancelBeforeDispatch({ type: "ERROR", payload: error.message });
        }
    };

    useEffect(() => {
        return () => setCancelled(true);
    }, []);

    return { updateDocEnrollment, response };
};
