import { useState, useEffect, useReducer } from 'react';
import { db } from '../firebase/config'
import { collection, doc, setDoc, Timestamp, documentId } from 'firebase/firestore';

const initState = {
    loading: null,
    error: null
}

const insertReducer = (state, action) => {
    switch(action.type) {
        case "LOADING":
            return {loading: true, error: null};
        case "INSERTED_DOC":
            return {loading: false, error: null};
        case "ERROR":
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const useInsertDocEnrollment = (docCollection) => {
    const [response, dispatch] = useReducer(insertReducer, initState);

    //deal with memory leak
    const [canceled, setCancelled] = useState(false);
    const checkCancelBeforeDispatch = (action) => {
        if(!canceled){
            dispatch(action);
        }
    }

    const insertDocEnrollment = async(document) => {

        var documentId = document.company_id;
        const id = document.id; 
        var collection = document.collection
        var enrollmentId = document.enrollment_id

        console.log("docCollection: ", docCollection)
        console.log("documentId: ", documentId)
        console.log("id: ", id)
        console.log("collection: ", collection)
        console.log("enrollmentId: ", enrollmentId)

        checkCancelBeforeDispatch({
            type: "LOADING"
        });

        try {
            const newDocument = {
                ...document, 
                creation_date: Timestamp.now()
            };

            
            const insertedDocument = await setDoc(
                collection === "Users" 
                    ? doc(db, docCollection, documentId, collection, id, "Enrollments", enrollmentId)
                    : doc(db, docCollection, documentId, collection, enrollmentId),
                newDocument,
            );

            checkCancelBeforeDispatch({
                type: "INSERTED_DOC",
                payload: insertedDocument
            });

        } catch (error) {
            console.log(error)
            checkCancelBeforeDispatch({
                type: "ERROR",
                payload: error.message,
            });
        }
    };

    useEffect(() => {
        return () => setCancelled(true)
    }, [])

    return { insertDocEnrollment, response }

}