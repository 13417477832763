import styles from './Login.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useState, useEffect } from 'react';
import { useAuthentication } from '../../hooks/useAuthentications';
import { NavLink } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { db } from '../../firebase/config';
import DialogBox from '../../components/DialogBox';
import DialogBoxResend from '../../components/DialogBoxResend';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const LoginStudent = ({ userSetLanguage, changeNavBar, hideFooter }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [openDialogResend, setOpenDialogResend] = useState(false);
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
    const [action, setAction] = useState("");

    const auth = getAuth();

    const { t } = useTranslation(['login']);

    const { login, error: authError, loading, loginWithGoogle } = useAuthentication(userSetLanguage);

    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

    const loginGoogle = async () => {
        const res = await loginWithGoogle();
    }

    const handleResetPassword = () => {
        setMessage(t(`login:message.Digite seu email de login`));
        setOpenDialogResend(true);
    };

    const actionSendEmail = (emailResend) => {
        setOpenDialogResend(false);
        sendPasswordResetEmail(auth, emailResend)
            .then(() => {
                setMessage(t(`login:message.email mudança senha`, {
                    emailResend: emailResend,
                    interpolation: { prefix: '${', suffix: '}' }
                }));
                setOpenDialogBox(true);
            })
            .catch((error) => {
                setOpenDialogBox(true);
                setMessage(t(`login:message.Digite seu email de login`, {
                    error: error.message,
                    interpolation: { prefix: '${', suffix: '}' }
                }));
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError("");

        const user = {
            email,
            password
        };
        const res = await login(user);

    }

    const actionCloseDialogBox = (f, option) => {
        setAction("");
        setOpenDialogBox(false);
        setOpenDialogResend(false);
    }

    useEffect(() => {
        changeNavBar(false);
        hideFooter(true);
    }, [])

    useEffect(() => {
        if (authError) {
            setMessage(authError)
            setOpenDialogBox(true)
        }
    }, [authError])

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);


    useEffect(() => {
        if (preferenceBrowserLanguage) {
            var language = "en";
            switch (preferenceBrowserLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceBrowserLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div className={`${styles.div_choosefile}`}>
            {
                <div className={`row ${styles.row_items} ${styles.login} mt-5`}>
                    <div className="col-3">
                        <i className={`bi bi-person-circle ${styles.icon_user}`}></i>
                    </div>
                    <h3 className={`mt-3 ${styles.texts}`}>{t(`login:home.Acesse a sua conta`)}</h3>

                    <div className="col-12 mt-4"></div>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <input
                                className={`mt-2 ${styles.form_field}`}
                                type="email"
                                name="email"
                                required
                                placeholder={t(`login:home.Email`)}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </label>
                        <p></p>
                        <label>
                            <input
                                className={`mt-2 ${styles.form_field}`}
                                type="password"
                                name="password"
                                required
                                placeholder={t(`login:home.Senha`)}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        <p></p>
                        {!loading && <button className={`mt-3 ${styles.btn_style}`}>{t(`login:home.Entrar`)}</button>}
                        {loading && <button className={`mt-3 ${styles.btn_style}`} disabled>{t(`login:home.Aguarde`)}</button>}
                        {error && <p className="error">{error}</p>}
                    </form>

                    <div className="mt-4 mb-5">
                        <a className={`${styles.textsHyperlink}`} href="#" onClick={() => handleResetPassword()}>{t(`login:home.Esqueci a minha senha`)}</a>
                    </div>
                </div>}

            {openDialogResend && <DialogBoxResend
                isopen={true}
                questionDialog={message}
                handleClosDialogBoxResend={actionCloseDialogBox}
                actionSendEmail={actionSendEmail}
                userSetLanguage={userSetLanguage}
            />}

            {openDialogBox && <DialogBox
                action={action}
                actionScript={actionCloseDialogBox}
                isopen={true}
                questionDialog={message}
                optionsDialog={["Ok"]}
                userSetLanguage={userSetLanguage}
            />}
        </div>
    )
}

export default LoginStudent