//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './UserEnrollments.module.css'
//React Hooks
import { useState, useEffect } from 'react';
// Dev Hooks
import { useFetchProjects } from '../hooks/useFetchProjects';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useLanguageValue } from '../context/LanguageContext';
import { set } from 'date-fns';

const UserEnrollments = ({ user, actualEnrollmentId, handleChangeEnrollment }) => {

    const userId = user.id
    const companyId = user.company_id

    const [enrollmentList, setEnrollmentsList] = useState([])
    const [courseName, setCourseName] = useState("")
    const [courseStatus, setCourseStatus] = useState("")
    const [courseNameFilter, setCourseNameFilter] = useState("")
    const [startDate, setStartDate] = useState("")
    const [validateDate, setValidateDate] = useState("")
    const [statusFilter, setStatusFilter] = useState("")

    const { documents: userEnrollments, loadingUserEnrollments, errorUserEnrollments } = useFetchProjects("Enrollments", "", userId, "", companyId);

    const { documents: enrollments, loadinEnrollments, errorEnrollments } = useFetchProjects("Companys", "", companyId, "Enrollments", "");

    const formatDate = (timestamp) => {
        if(!timestamp) return "N/A";
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString();
    };

    const changeEnrollment = (enrollment) => {
        handleChangeEnrollment(enrollment)
    }

    useEffect(() => {
        if (userEnrollments && userEnrollments.length > 0 && enrollments) {
            setEnrollmentsList(userEnrollments);
            const filteredEnrollments = userEnrollments.filter(userEnrollment => {
                const enrollment = enrollments.find(enrollment => enrollment.id === userEnrollment.enrollment_id);
                return enrollment && !enrollment.deleted && enrollment.active;
            });

            setEnrollmentsList(filteredEnrollments);
        }
    }, [userEnrollments, enrollments]);

    return (
        <div>
            <div className={`mt-1 row d-flex align-items-center `}>
                <div className="col-4 ">
                    <input
                        type="text"
                        className={`form-control fw-bold ${styles.filterName}`}
                        placeholder="Course search..."
                        value={courseNameFilter}
                        onChange={(e) => setCourseNameFilter(e.target.value.toLowerCase())}
                    />
                </div>
                <div className="col-2 fw-bold align-items-center justify-content-center " >
                    <label htmlFor="createdOn" >
                        Enrolled on
                    </label>
                    <div className={` `}>
                        <input
                            id="createdOn"
                            type="date"
                            className={`form-control col-1  ${styles.filterDate}`}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-2 fw-bold align-items-center justify-content-center " >
                    <label htmlFor="createdOn" >
                        Valid until
                    </label>
                    <div className={` `}>
                        <input
                            id="createdOn"
                            type="date"
                            className={`form-control col-1  ${styles.filterDate}`}
                            value={validateDate}
                            onChange={(e) => setValidateDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-2 position-relative">
                    <select
                        id="statusFilter"
                        className={`form-select fw-bold ${styles.filters} pe-5`}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        defaultValue=""
                    >
                        <option value="" disabled>Status</option>
                        <option value="all">All</option>
                        <option value="completed">Completed</option>
                        <option value="passed">Passed</option>
                        <option value="failed">Failed</option>
                        <option value="started">Started</option>
                        <option value="not started">Not Started</option>
                    </select>
                </div>
            </div>

            <div className={`mt-3`}>
                {enrollmentList.length > 0 && enrollmentList
                
                    .filter(enrollment =>
                        (statusFilter === '' || statusFilter === 'all' ||
                            enrollment.status === statusFilter) &&
                        (courseNameFilter === "" || enrollment.course_name.toLowerCase().includes(courseNameFilter)) &&
                        (!startDate || new Date(enrollment.creation_date.seconds * 1000) >= new Date(startDate))
                    )
                    .filter(enrollment => !enrollment.deleted)
                    .map((enrollment) => {
                        return (
                            enrollment.enrollment_id !== actualEnrollmentId && (
                                <div key={enrollment.enrollment_id} className="row">
                                    <div className={`col-4 ${styles.formatTexts}`}>
                                        {enrollment.course_name}
                                    </div>
                                    <div className={`col-2 ${styles.formatTexts}`}>
                                        {formatDate(enrollment.creation_date)}
                                    </div>
                                    <div className={`col-2 ${styles.formatTexts}`}>
                                        {formatDate(enrollment.validate_date)}
                                    </div>
                                    <div className={`col-2 ${styles.formatTexts}`}>
                                        {enrollment.status}
                                    </div>
                                    <div className={`col-1 ${styles.formatTexts}`}>
                                        <i className={`bi bi-info-circle ${styles.iconsEnroll}`} onClick={() => changeEnrollment(enrollment)}></i>
                                    </div>
                                </div>
                            )
                        );
                    })}
            </div>
        </div>
    )
}

export default UserEnrollments