//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './MngConfig.module.css'
//React Hooks
import { useState, useEffect, useRef } from 'react';
//context
import { useAuthValue } from '../context/AuthContext';
//Dev Hooks
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useLanguageValue } from '../context/LanguageContext';
//Dev Components
import DialogBox from '../components/DialogBox';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase/config";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const MngConfig = ({ userInfo }) => {

    const companyId = userInfo.company_id;

    const { t } = useTranslation(['myprojects']);
    const { preferenceLanguage } = useLanguageValue();

    const [bannerColor, setBannerColor] = useState("")
    const [textsColor, setTextsColor] = useState("")
    const [urlCompanyLogo, setUrlCompanyLogo] = useState("");
    const [originalUrlLogo, setOriginalUrlLogo] = useState("");
    const [companyLogoFile, setCompanyLogoFile] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companyHubName, setCompanyHubName] = useState("");

    const [savingSettings, setSavingSettings] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [msgDialog, setMsgDialog] = useState("");
    const [action, setAction] = useState("");


    const { document: companyInfos, loading: loadinguserCmopanyInfos } = useFetchDocument("Companys", companyId)
    const { updateDocument, responseUpdate } = useUpdateDocument("Companys", companyId)

    const actionCloseDialogBox = () => {
        setOpenDialog(false);
        setAction("");
        setMsgDialog("");
        setOptionsDialog([]);
    }

    const handleSave = () => {
        if (companyLogoFile) {
            uploadImage(companyLogoFile)
        } else {
            saveCompanySettings()
        }
    }

    const handleChangeLogo = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];
            if (file) {
                setCompanyLogoFile(file);
            }
        };
        fileInput.click();
    }

    const saveCompanySettings = async () => {
        const companySettings = {
            color: bannerColor,
            text_color: textsColor,
            logo: urlCompanyLogo,
            name: companyName,
            hub_name: companyHubName,
            company_id: companyId
        };

        await updateDocument(companySettings);

        setSavingSettings(false)
        setAction("");
        setMsgDialog("");
        setOptionsDialog([]);
        setOpenDialog(false);

    }

    const uploadImage = async (file) => {
        setSavingSettings(true)
        const forcedFileName = 'logo';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {

                const maxSize = 1000 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 200Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }

                const storageRef = ref(storage, `companys/${companyId}/logo/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    null,
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    },
                    () => {
                        getDownloadURL(storageRef)
                            .then((url) => {
                                setUrlCompanyLogo(url);
                            })
                            .catch((error) => {
                                console.error('Erro ao obter a URL do arquivo:', error);
                            });
                    }
                );
            }

        } catch (error) {
            console.error('Erro geral:', error);
        }
    }

    useEffect(() => {
        if (urlCompanyLogo) {
            saveCompanySettings()
        }
    }, [urlCompanyLogo])

    useEffect(() => {
        if (companyInfos) {
            setBannerColor(companyInfos.color || "#000000");
            setTextsColor(companyInfos.text_color || "#000000");
            setUrlCompanyLogo(companyInfos.logo);
            setOriginalUrlLogo(companyInfos.logo);
            setCompanyName(companyInfos.name);
            setCompanyHubName(companyInfos.hub_name);
        }
    }, [companyInfos])

    useEffect(() => {
        if (urlCompanyLogo != originalUrlLogo) {
            saveCompanySettings()
        }

    }, [urlCompanyLogo, originalUrlLogo])

    useEffect(() => {
        if (savingSettings) {
            setAction("processing");
            setMsgDialog("Saving settings");
            setOptionsDialog([]);
            setOpenDialog(true);
        }
    }, [savingSettings])

    return (
        <div className={``}>
            <div className='p-4'>
                <div className="mb-3">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ backgroundColor: bannerColor, height: '100px', borderRadius: '8px', position: 'relative' }}
                    >
                        {urlCompanyLogo && (
                            <img
                                src={urlCompanyLogo}
                                alt="Company Logo"
                                style={{ height: '70px', position: 'absolute', left: '20px' }}
                            />
                        )}
                        {companyLogoFile && (
                            <img
                                src={URL.createObjectURL(companyLogoFile)}
                                alt="Company Logo"
                                style={{ height: '70px', position: 'absolute', left: '20px' }}
                            />
                        )}
                        <h2 className="ms-5" style={{ textAlign: 'center', width: '100%', color: textsColor }}>{companyHubName}</h2>
                        <NavLink
                            to="#"
                            className={`nav-link dropdown-toggle d-flex align-items-center me-3 ${styles.formatTexts}`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: textsColor }}
                        >
                            <i className={`bi bi-person-circle align-text-bottom me-2 fs-4 ${styles.formatTexts}`}></i>
                            User Name
                        </NavLink>
                        <li className={`nav-item dropdown ${styles.links_list}`}>
                            <ul className="dropdown-menu" id="dropdown-menu">
                                <li className="nav-link d-flex align-items-center ms-2"><div>My Courses</div></li>
                                <li className="nav-link d-flex align-items-center ms-2"><div>My Account</div></li>
                                <li className="nav-link d-flex align-items-center ms-2"><div>Help</div></li>
                                <li className="nav-link d-flex align-items-center ms-2"><div>Exit</div></li>
                            </ul>

                        </li>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="companyName" className={`form-label ${styles.formatTexts}`}>{t('Company Name')}</label>
                    <input
                        type="text"
                        className={`form-control ${styles.input}`}
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="companyHubName" className={`form-label ${styles.formatTexts}`}>{t('Company Hub Name')}</label>
                    <input
                        type="text"
                        className={`form-control ${styles.input}`}
                        id="companyHubName"
                        value={companyHubName}
                        onChange={(e) => setCompanyHubName(e.target.value)}
                    />
                </div>
                <div className={`mb-3`}>
                    <label htmlFor="companyLogo" className={`form-label ${styles.formatTexts}`}>{t('Company Logo')}</label>
                    {urlCompanyLogo ? (
                        <div className={` col-auto `}>
                            <img src={urlCompanyLogo} alt="Company Logo" className={`img-fluid ${styles.companyLogo} ${styles.backImage}`} />
                            <button
                                className={` mt-2 ${styles.buttonX} `}
                                onClick={() => setUrlCompanyLogo("")}
                            >
                                <i className="ms-3 bi bi-x-circle"></i>
                            </button>
                        </div>
                    ) : (companyLogoFile ? (
                        <div className={` col-auto `}>
                            <img
                                src={URL.createObjectURL(companyLogoFile)}
                                alt="Company Logo Preview"
                                className={`img-fluid mt-2 ${styles.companyLogo} ${styles.backImage}`}
                                onClick={() => handleChangeLogo()}
                            />
                            <button
                                className={` mt-2 ${styles.buttonX}`}
                                onClick={() => setCompanyLogoFile("")}
                            >
                                <i className="ms-3 bi bi-x-circle"></i>
                            </button>
                        </div>
                    )
                        : <div>
                            <input
                                type="file"
                                className={`form-control ${styles.input}`}
                                id="companyLogo"
                                onChange={(e) => {
                                    setCompanyLogoFile(e.target.files[0])
                                }}
                            />

                        </div>
                    )}
                </div>
                <div className={`row d-flex align-items-center mt-3 `}>
                    <div className="col-3 d-flex align-items-end">
                        <label htmlFor="color" className={`form-label col-auto ${styles.formatTexts}`}>{t('Company Banner Color:')}</label>
                        <input
                            type="color"
                            className={`form-control ms-2 col-4 ${styles.inputColor}`}
                            id="color"
                            value={bannerColor}
                            onChange={(e) => setBannerColor(e.target.value)}
                        />
                    </div>
                    <div className="col-2 d-flex align-items-end justify-content-end">
                        <label htmlFor="color" className={`form-label col-auto ${styles.formatTexts}`}>{t('Title and Menu Text Color:')}</label>
                        <input
                            type="color"
                            className={`form-control ms-2 col-4 ${styles.inputColor}`}
                            id="color"
                            value={textsColor}
                            onChange={(e) => setTextsColor(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <button
                className={`ms-4 mb-3 btn btn-primary ${styles.buttons}`}
                onClick={() => handleSave()}
            >
                {t('Save')}
            </button>

            {openDialog &&
                <div>
                    <DialogBox
                        action={action}
                        actionScript={action === "error" ? actionCloseDialogBox : ""}
                        questionDialog={msgDialog}
                        optionsDialog={optionsDialog}
                        isopen={true}
                    />
                </div>
            }
        </div>
    )
}

export default MngConfig