import { id } from 'date-fns/locale';
import { db } from '../firebase/config';
import { useInsertDocEnrollment } from './useInsertDocEnrollment';
import { useUpdateDocEnrollment } from './useUpdateDocEnrollment';

import { collection, Timestamp } from 'firebase/firestore';

export const useCreateEnrollment = () => {

    const { insertDocEnrollment, response:  responseInsert } = useInsertDocEnrollment("Companys");
    const { updateDocEnrollment, response: responseUpdate } = useUpdateDocEnrollment("Companys");


    const createEnrollment = async (enrollmentItems) => {

        const actionDoc = enrollmentItems.actionDoc;
        const active = enrollmentItems.active;
        const deleted = enrollmentItems.deleted;
        const bookmark = enrollmentItems.bookmark;
        const concluded = enrollmentItems.concluded;
        const conclusion_date = enrollmentItems.conclusion_date;
        const course_description = enrollmentItems.course_description;
        const course_id = enrollmentItems.course_id;
        const course_image = enrollmentItems.course_image;
        const course_name = enrollmentItems.course_name;
        const company_id = enrollmentItems.domain_id;
        const enrollment_date = enrollmentItems.enrollment_date;
        const enrollment_id = enrollmentItems.enrollment_id;
        const first_access = enrollmentItems.first_access;
        const id = enrollmentItems.id;
        const last_access = enrollmentItems.last_access;
        const passing_score = enrollmentItems.passing_score;
        const score = enrollmentItems.score;
        const status = enrollmentItems.status;
        const total_time = enrollmentItems.total_time;
        const validate_date = enrollmentItems.validate_date;
        const userEnrollmentDomains = enrollmentItems.domain;
        const publisher = enrollmentItems.publisher;
        const enrolledUsers = enrollmentItems.enrolledUsers || [];
        const changeUponCompletion = enrollmentItems.access_upon_completion || false;

        for (const domain of userEnrollmentDomains) {

            const newDocumentEnrollmentUser = {
                id: domain,
                active: active,
                access_upon_completion: changeUponCompletion,
                bookmark: bookmark,
                concluded: concluded,
                conclusion_date: conclusion_date,
                course_description: course_description,
                course_id: course_id,
                course_image: course_image,
                course_name: course_name,
                domain: company_id,
                company_id: company_id,
                enrollment_date: enrollment_date,
                enrollment_id: enrollment_id,
                first_access: first_access,
                enrollment_id: id,
                last_access: last_access,
                passing_score: passing_score,
                score: score,
                status: status,
                total_time: total_time,
                validate_date: validate_date,
                collection: "Users"
            }

            if(actionDoc === "insert" || actionDoc === "insertuser"){
                await insertDocEnrollment(newDocumentEnrollmentUser)
            } else {
                break;
            }
        }

        if (actionDoc === "insert") {
            const newDocumentEnrollment = {
                id: enrollment_id,
                active: true,
                access_upon_completion: changeUponCompletion,
                deleted: false,
                company_id: company_id,
                domain: company_id,
                course_name: course_name,
                course_id: course_id,
                users_enrolled: userEnrollmentDomains,
                publisher: publisher,
                collection: "Enrollments",
                validate_date: validate_date,
                enrollment_id: enrollment_id,
                passing_score: passing_score,
                course_image: course_image,
                course_description: course_description,
                project_type: bookmark.project
            }
            await insertDocEnrollment(newDocumentEnrollment)
        } else if (actionDoc === "updateuser" || actionDoc === "insertuser") {
            const newDocumentEnrollment = {
                company_id: company_id,
                collection: "Enrollments",
                enrollment_id: enrollment_id,
                users_enrolled: [...new Set([...userEnrollmentDomains, ...enrolledUsers])],
                publisher: publisher,
            }
            await updateDocEnrollment(newDocumentEnrollment)
        } else if (actionDoc === "updateenrollment" ) {
            const newDocumentEnrollment = {
                company_id: company_id,
                collection: "Enrollments",
                enrollment_id: enrollment_id,
                active: active,
                access_upon_completion: changeUponCompletion,
                deleted: deleted, 
            }
            await updateDocEnrollment(newDocumentEnrollment)
        } else if (actionDoc === "updateinfosenrollment" ) {
            const newDocumentEnrollment = {
                active: active,
                company_id: company_id,
                collection: "Enrollments",
                enrollment_id: enrollment_id,
                active: active,
                access_upon_completion: changeUponCompletion,
                course_name: course_name,
                course_description: course_description,
                course_image: course_image,
                passing_score: passing_score,
                validate_date: validate_date,
            }
            await updateDocEnrollment(newDocumentEnrollment)
            
            for (const domain of userEnrollmentDomains) {

                const newDocumentEnrollmentUser = {
                    id: domain,
                    enrollment_id: enrollment_id,
                    company_id: company_id,
                    collection: "Users",
                    active: active,
                    access_upon_completion: changeUponCompletion,
                    course_name: course_name,
                    course_description: course_description,
                    validate_date: validate_date,
                    course_image: course_image,
                }
                await updateDocEnrollment(newDocumentEnrollmentUser)
            }
        }

    }

    return { createEnrollment, responseInsert, responseUpdate }

}